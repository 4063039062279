// 商城自定义模块(通用)
import {
  
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtProductShelves,
  WgtImageText,
  WgtMemberInfo,
  WgtSuspension,
  WgtOfficialAccount
  // WgtContentPart
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtProductShelves,
  WgtImageText,
  WgtMemberInfo,
  WgtSuspension,
  WgtOfficialAccount
  // WgtUserContainer
  // WgtContentPart
}


export default {
  widgets,
  initiWidgets
}
