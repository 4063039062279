<template>
  <div class="">
    <el-radio-group v-model="localValue.type" size="mini">
      <el-radio-button label="vip">会员</el-radio-button>
      <el-radio-button label="noVip">非会员</el-radio-button>
    </el-radio-group>
    <CompHotSetting v-model='localValue[localValue.type]' :uuid="`${uuid}_${localValue.type}`" :visible="localValue.type=='noVip'"/>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import CompHotSetting from "../../comps/comp_hotSetting";
export default {
  components: {
    CompHotSetting
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          type: 'vip',
          vip: {
            imgUrl: "", data: []
          },
          noVip: {
            imgUrl: "", data: []
          }
        }
      }
    },
    uuid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      localValue: {
        vip: {
          imgUrl: "", data: [],imgName:''
        },
        noVip: {
          imgUrl: "", data: [],imgName:''
        }
      }
    }
  },
  watch: {
    uuid: {
      handler(val) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true
    },
    localValue: {
      handler(val) {
        this.$emit('input', val)
      },
      deep: true
    },
  },
}
</script>

<style lang="scss" scoped>
.el-radio-group,
.el-input {
  margin-bottom: 10px;
}

</style>