<template>
  <div class="product-shelves-padded-item">
    <div class="padded-item-content">
      <div class="padded-item-content-inner">
        <span>粗细</span>
        <el-input-number v-model="localValue.borderWidth" placeholder="像素" :min="0" controls-position="right"
          size="mini" />
      </div>
      <div class="padded-item-content-inner">
        <span>颜色</span>
        <el-color-picker v-model='localValue.borderColor' size='small' />

      </div>
    </div>
    <div class="padded-item-content">
      <div class="padded-item-content-inner">
        <span>圆角</span> 
        <el-input-number v-model="localValue.borderRadius" placeholder="像素" :min="0"
          controls-position="right" size="mini" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    uuid: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {
        return {
        borderWidth: 0,
        borderColor: '',
        borderRadius: 0,
        }
      }
    }
  },
  data() {
    return {
      localValue: {
        borderWidth: 0,
        borderColor: '',
        borderRadius: 0,
      }
    }
  },
  watch: {
    uuid: {
      handler() {
        this.localValue = this.value;
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.product-shelves-padded-item {
  .padded-item-content {
    display: flex;
    margin-bottom: 10px;

    &-inner {
      display: flex;
      margin-right: 10px;
      align-items: center;

      span {
        color: rgb(156, 156, 156);
        font-size: 12px;
      }

      .el-input-number {
        width: 110px;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
}
</style>