import { pickBy } from '@/utils'
import AttrGoods from './attr-goods'

export default {
  name: 'oldGoods',
  setting: [
    { label: '标题', key: 'title', component: 'input', value: '以旧换新商品' },
    { label: '副标题', key: 'subtitle', component: 'input', value: '以旧换新商品列表' },
    { label: "楼层", key: "floor_var", component: "input", value: "" },
    { label: '组件间距', key: 'padded', component: 'switch', value: true },
    { label: '列表标题', key: 'listTitle', component: 'input', value: '' },
    {
      label: '选择商品',
      key: 'data',
      component: function (h, { key }) {
        return <AttrGoods v-model={this.value[key]} />
      },
      value: [
        {
          imgUrl: '',
          linkPage: '',
          content: '',
          title: '商品名称',
          id: '',
          price: 0,
          market_price: 0
        },
        {
          imgUrl: '',
          linkPage: '',
          content: '',
          title: '商品名称',
          id: '',
          price: 0,
          market_price: 0
        },
        {
          imgUrl: '',
          linkPage: '',
          content: '',
          title: '商品名称',
          id: '',
          price: 0,
          market_price: 0
        },
        {
          imgUrl: '',
          linkPage: '',
          content: '',
          title: '商品名称',
          id: '',
          price: 0,
          market_price: 0
        }
      ]
    },
    {
      label: "折抵金额",
      key: "price_show_type",
      component: "radio",
      options: [
        { name: "机型折抵", label: 1 },
        { name: "最高折抵", label: 2 },
      ],
      value: 1,
    },
  ],
  transformIn: (v) => {
    const { name, base, config, data, list, distributor_id } = v
    return {
      name,
      ...base,
      ...config,
      data,
      list,
      distributor_id
    }
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: 'name',
      base: (v) => {
        return pickBy(v, {
          title: 'title',
          subtitle: 'subtitle',
          padded: 'padded',
          listTitle: 'listTitle',
          floor_var: "floor_var",
          price_show_type: 'price_show_type'
        })
      },
      config: (v) => {
        return pickBy(v, {
        })
      },
      data: 'data'
    })
  }
}
