var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'wgt-film': true,
}},[(_vm.value.title || _vm.value.subtitle)?_c('div',{staticClass:"wgt-hd"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.value.title))]),_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.value.subtitle))])]):_vm._e(),_c('div',{staticClass:"wgt-bd",style:({
    padding: `${_vm.value.padd?.paddedt || 0}px ${_vm.value.padd?.paddedr || 0}px ${_vm.value.padd?.paddedb || 0}px ${_vm.value.padd?.paddedl || 0}px`,
  })},[_c('video-player',{style:({ borderRadius: `${_vm.value.radius}px` }),attrs:{"options":{
      aspectRatio: _vm.aspectRatio,
      sources: [
        {
          type: 'video/mp4',
          src: _vm.sourceUrl
        }
      ],
      notSupportedMessage: '添加视频源',
      suppressNotSupportedError: true,
      controlBar: false
    }}}),(_vm.value?.showVolume == 1)?_c('el-image',{staticClass:"muted-btn",attrs:{"src":_vm.value?.openVolume == 0 ? 'https://cdn-image.tjtjshengtu.com/xcximage/muted.png'
      : 'https://cdn-image.tjtjshengtu.com/xcximage/sound.png'}}):_vm._e(),(_vm.value?.showFullscreen == 1)?_c('el-image',{staticClass:"muted-btn-two",attrs:{"src":'https://cdn-image.tjtjshengtu.com/xcximage/screen.png'}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }