<template>
  <div class="wgt-bd comp_hotImg" v-if="value.data.imgUrl" :class="{ 'spaced': value.spaced }">
    <el-image :src="value.data.imgUrl" />
    <div v-if="visible" class="hotzone-content" v-for="(item, index) in privacy" :key="index"
      :style="{ top: `${item.topPer * 100}%`, left: `${item.leftPer * 100}%`, width: `${item.widthPer * 100}%`, height: `${item.heightPer * 100}%` }">
      <div class='privacy-policy' :style="`bottom:${-item.bottom || 0}px`">
        <div class='privacy-policy__circle'></div>
        <div class='privacy-policy__info'>
          <span class="privacy-policy__text">我已阅读并接受“Apple 授权专营店商城“的</span>
          <span class='privacy-policy__link'>会员隐私条款。</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    visible:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    privacy() {
      return this.value.data.data.filter(item => item.id === 'register' || item.linkPage === 'ordertogift')
    },
  },
}
</script>

<style lang="scss" scoped>
.comp_hotImg {
  position: relative;

  &.spaced {
    padding: 0 10px;
  }

  .hotzone-content {
    position: absolute;
  }

  .el-image {
    display: block;
  }


}
</style>
<style lang="scss">
.privacy-policy {
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  color: #929292;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: max-content;
  max-width: 100%;
  max-height: 30px;
  line-height: 15px;
  font-size: 11px;
  &__circle {
    height: 12px;
    width: 12px;
    min-height: 12px;
    min-width: 12px;
    border: 1px solid #929292;
    box-sizing: border-box;
    border-radius: 100%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    left: 0px;
  }

  &__info {
    flex: 1;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-height: 15px;
    padding-left: 16px;
  }

  &__text {
    height: 15px;
  }

  &__link {
    color: #1D1D1F;
    overflow: hidden;
    height: 15px;
  }
}
</style>