<template>
  <div class='color-cell'>
    <div class="color-cell-item">
      <el-color-picker v-model='localValue.dot_noCover' size='small' />
      <p>未选中</p>
    </div>
    <div class="color-cell-item">
      <el-color-picker v-model='localValue.dot_cover' size='small' />
      <p>选中</p>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          dot_noCover: '',
          dot_cover: ''
        }
      }
    },
    uuid: {
      type: String,
      default: ''
    },
    //
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 默认值
      localValue: {
        dot_noCover: '',
        dot_cover: ''
      }
    }
  },
  watch: {
    localValue: {
      handler(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    uuid: {
      handler() {
        this.localValue = cloneDeep(this.value)
      },
      deep: true,
      immediate: true
    },
    show: {
      handler(val) {
        this.localValue = cloneDeep(this.value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.color-cell {
  display: flex;
  &-item{
    margin-right: 20px;
    p{
      margin: 0;
      font-size: 12px;
      color: #999;
    }
  }
}
</style>