import {
  WgtPage,
  
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtFunctionBlock,
  WgtProductShelves,
  WgtContentPart,
  WgtClassify,
  WgtCustomWgt,
  WgtImageText,
  WgtRecentOrders,
  WgtMemberInfo,
  WgtSuspension,
  WgtUserContainer,
  WgtOfficialAccount
} from "../wgts/index";

export const initiWidgets = [
  // {
  //   name: "functionBlock",
  //   params: {
  //     name: "functionBlock",
  //     base: {
  //       wgtName: "功能区",
  //       wgtDesc: "",
  //       content: "category.png",
  //       type: "category",
  //     },
  //   },
  // },
];

export const widgets = {
  WgtPage,
  
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtFunctionBlock,
  WgtProductShelves,
  WgtContentPart,
  WgtClassify,
  WgtCustomWgt,
  WgtImageText,
  WgtRecentOrders,
  WgtMemberInfo,
  WgtSuspension,
  WgtUserContainer,
  WgtOfficialAccount
};

export default {
  widgets,
  initiWidgets,
};
