import { pickBy } from '@/utils'
import NavItem from "./nav-item.vue";
import { cloneDeep } from 'lodash'
import { transformInTem } from "../../utils"

export default {
  name: 'userContainer',
  setting: [
    {
      label: "用户身份",
      key: "userType",
      component: "radiobutton",
      options: [
        { name: "非会员", label: "notmember" },
        { name: "会员", label: "member" },
      ],
      value: 'notmember',
    }, {
      label: '自定义模块',
      key: 'data',
      component: function (h, { key }) {
        return <NavItem v-model={this.value[key]} type={this.value.userType} uuid={this.value.uuid} size='small' />
      },
      value: {
        member: null,
        notmember: null,
      },
    },
  ],
  transformIn: async (v, widgets) => {
    const { name, base, config, data } = v;
    const _data = {}
    for (const key in data) {
      const value = await transformInTem([{ data: data[key].data }], widgets)
      _data[key] = value[0].cusTom
    }
    return {
      name,
      ...base,
      ...config,
      data: _data,
    }
  },
  transformOut: (v) => {
    const value = cloneDeep(v)
    const _data = {}

    for (const key in value.data) {
      if (Object.prototype.hasOwnProperty.call(value.data, key)) {
        const { pages_template_id, template_title } = value.data[key] || {};
        _data[key] = { data: { pages_template_id, template_title } }
      }
    }
    value.data = _data
    return pickBy(value, {
      name: 'name',
      base: () => {
        return {};
      },
      config: (v) => {
        return pickBy(v, {
          userType: "userType"
        });
      },
      data: "data",
    })
  }
}
