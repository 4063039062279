<style lang="scss" src="./index.scss"></style>
<template>
  <div :class="{
    'wgt-ab-test': true
  }">
    <div class="wgt-bd">
      <div class="placeholder" v-if="showEdit">点击编辑“AB实验”</div>
      <div v-else>
        <component v-for="(wgt, index) in value.experiment.list[value.experiment.groupIndex].children" :is="wgt.name"
          :value="wgt" :key="`ab-component__${index}`" />
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'AbTest',
  wgtName: 'AB实验',
  wgtDesc: '',
  wgtIcon: 'wgt-ab-test',
  wgtType: 2,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {}
  },
  computed: {
    showEdit() {
      const { list = [], groupIndex } = this.value.experiment || {}
      return !this.value.experiment || !list[groupIndex] || list[groupIndex].children.length == 0
    }
  },
  created() { },
  methods: {}
}
</script>
