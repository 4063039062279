import { pickBy } from '@/utils'
import SelectCustom from './select-custom'

export default {
  name: 'customWgt',
  setting: [
    { label: '', component: 'input', key: 'pages_template_id',
    isShow: function () {
      return false
    }},
    {
      label: '自定义模块',
      key: 'data',
      component: function (h, { key }) {
        return (
          <SelectCustom
            pages_template_id={this.value.pages_template_id || ''}
            template_title={this.value.template_title || ''}
            v-model={this.value[key]}
            on-onchange={(e) => {
              const {pages_template_id,template_title}= e
              this.value.pages_template_id = pages_template_id
              this.value.template_title = template_title
            }}
          />
        )
      },
      value: []
    }
  ],
  transformIn: (v,wgtList) => {
    const { name, base, config } = v
    return {
      name,
      ...base,
      ...config
    }
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: 'name',
      base: (v) => {
        return pickBy(v, {
          title: 'title',
          subtitle: 'subtitle',
          padded: 'padded'
        })
      },
      config: ({ pages_template_id,template_title }) => {
        return {
          style: 1,
          pages_template_id,
          template_title
        }
      },
      data: () => {
        return null
      }
    })
  }
}
