var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'wgt-around-store': true,
  'padded': _vm.value.padded
}},[(_vm.value.bgImg)?_c('el-image',{staticClass:"wgt-bg",attrs:{"src":_vm.value.bgImg,"fit":"height-contain"}}):_vm._e(),_c('div',{staticClass:"wgt-bd-row",style:({
    'width': _vm.value.width + '%',
    'bottom': _vm.value.bottom + 'px'
  })},[_c('p',{staticClass:"address",style:(_vm.storeStyle)},[_vm._v("国贸商城店"),_c('i',{staticClass:"el-icon-arrow-right"})]),(_vm.value.deliveryInfo == 1)?_c('p',{staticClass:"distance",style:(_vm.textsettingStyle)},[_c('span',{style:(_vm.distanceStyle)},[_vm._v("2.3km")]),_c('span',[_vm._v("离你最近")])]):_vm._e(),(_vm.value.deliveryInfo == 2)?_c('p',{staticClass:"distance",style:(_vm.textsettingStyle)},[_c('span',{style:(_vm.distanceStyle)},[_vm._v("2.3km")]),_c('span',{staticClass:"time",style:({ borderColor: _vm.value?.textStyle?.color })},[_vm._v("|")]),_c('span',[_vm._v("最快"),_c('span',{style:(_vm.distanceStyle)},[_vm._v("43分钟")]),_vm._v("送达")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }