<style lang="scss" src="./index.scss"></style>
<template>
  <div :class="{
    'wgt-new-slider': true,
    'padded': value.padded,
    'no-cover': !value.dotCover
  }" ref="newSlider">
    <div v-if="refresh" class="wgt-bd" :class="{ 'spaced': value.spaced }" :style="setCarouselHeight">
      <!-- 固定位置的叠层 -->
      <el-carousel class="slider-container" arrow="never" indicator-position="none" :interval="value.interval"
        @change="changeSlider" :autoplay="value.autoplay">
        <el-carousel-item v-for="(item, index) in value.data" :key="`carousel_${index}`">
          <div class="slider-item" :style="`
             border-radius:${value.rounded.upleft || 0}px ${value.rounded.upright || 0}px ${value.rounded.bottomright || 0}px ${value.rounded.bottomleft || 0}px;
             marginLeft:${value.imgpadded.paddedl || 0}px;marginRight:${value.imgpadded.paddedr || 0}px
          `">
            <SpImage :src="item.imgUrl" fit="contain" v-if="!item.videoUrl || item.media_type !== 'video'"
              class="image-item-img" />
            <video :ref="`swiperVideo_${index}`" :poster="item.imgUrl"
              v-if="item.media_type == 'video' && item.videoUrl" class="image-item-video" :loop="item.loop"
              muted="muted" objectFit="cover">
              <source :src="item.videoUrl" />
            </video>
            <!-- :class="{
              'transparent-transition': currentIndex !== index,
              'transparent-transition-active': currentIndex == index
            }"  -->
            <SpImage :src="item.overlay" class="overlay-item" fit="contain" v-if="item.overlay && !value.overlayroll"
              :style='overlayStyle(item, index)' />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-for="(item, index) in value.data" :key="`overlay_${index}`" class='overlay-content'
        v-if="value.overlayroll && item.overlay" :style='overlayStyle(item, index)'>
        <SpImage :class="{
          'transparent-transition': currentIndex !== index,
          'transparent-transition-active': currentIndex == index
        }" :src="item.overlay" class="over-lay" fit="contain" />
      </div>

      <div class="el-carousel_indicator" :class="{
        'left': value.dotLocation == 'left',
        'right': value.dotLocation == 'right',
      }">
        <div class="default-dot dot-circle" :style="setdotPosition()">
          <p v-for="(item, index) in value.data" :key="index" v-if="!value.dot_type" class="circle"
            :style="setcolor(item, index)" />
        </div>
        <p class="dot-text default-dot" v-if="value.dot_type == 'number'"
          :style="setnumbercolor(value.data[currentIndex])">
          <span>{{
            currentIndex + 1 }}</span>/{{
              value.data.length
            }}
        </p>
        <div class="progress default-dot" v-if="value.dot_type == 'line'" :style="setdotPosition()">
          <div class="progress-bar" v-for="(item, index) in value.data" :key="`overlay__${index}`"
            :style="setDefaultColor(item)">
            <p :style="setBgColor(item, index)" :class="{
              'progress-bar-default': true,
              'progress-bar-active': currentIndex >= index
            }"></p>
          </div>
        </div>

      </div>
      <!-- 挂件自定义部分 -->
    </div>
  </div>
</template>

<script>
import config from './config'

export default {
  name: 'NewSlider',
  wgtName: '新轮播',
  wgtDesc: '',
  wgtIcon: 'wgt-slider',
  wgtType: 1,
  wgtTem: 1, //0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      currentIndex: 0,
      h: '0px',
      refresh: true,
      offsetWidth: 375
    }
  },
  created() { },
  mounted() {
    this.$nextTick(() => {
      const ref = this.$refs.newSlider
      this.offsetWidth = ref.offsetWidth
    })
  },
  methods: {
    setLeft() {
      let left = null
      if (this.value.dotleft == 0) {
        return { left: '0px' }
      } else if (this.value.dotleft == 100) {
        return { right: '0px' }
      } else {
        return { left: this.value.dotleft + '%', transform: `translateX(-${this.value.dotleft}%)` }
      }
    },
    setcolor(item, index) {
      if (item.isCustomSlider) {
        return { 'background': this.currentIndex == index ? item.dot_cover : item.dot_noCover };
      } else {
        return { 'background': this.currentIndex == index ? this.value.pilot.dot_cover : this.value.pilot.dot_noCover };
      }
    },
    setnumbercolor(item) {
      if (item.isCustomSlider) {
        return { 'background': item.dot_noCover, color: item.dot_cover, ...this.setdotPosition() };
      } else {
        return { 'background': this.value.pilot.dot_noCover, color: this.value.pilot.dot_cover, ...this.setdotPosition() };
      }
    },

    setBgColor(item, index) {
      if (item.isCustomSlider) {
        return { background: this.currentIndex >= index ? item.dot_cover : item.dot_noCover }
      } else {
        return { background: this.currentIndex >= index ? this.value.pilot.dot_cover : this.value.pilot.dot_noCover }
      }
    },
    setDefaultColor(item) {
      if (item.isCustomSlider) {
        return { background: item.dot_noCover }
      } else {
        return { background: this.value.pilot.dot_noCover }
      }
    },

    overlayStyle(item, index) {
      const { overlaybuttom, overlayLeft, overlayWidth } = item
      return {
        bottom: `${overlaybuttom}%`,
        left: `${overlayLeft}%`,
        width: `${overlayWidth}%`,
        transition: `opacity ${this.value.trans}s ease-in-out`
      }
    },
    changeSlider(e) {
      const preIndex = this.currentIndex
      const ref = `swiperVideo_${e}`
      const preref = `swiperVideo_${preIndex}`
      this.currentIndex = e
      if (this.value.data[preIndex].media_type == 'video' && this.value.data[preIndex].autoplay) {
        this.$refs[preref] && this.$refs[preref][0].pause()
        if (this.value.data[preIndex].interact == 'reset') {
          this.$refs[preref][0].currentTime = 0
        }
      }
      if (this.value.data[e].media_type == 'video') {
        this.value.data[e].autoplay ? this.$refs[ref] && this.$refs[ref][0].play() : this.$refs[ref] && this.$refs[ref][0].pause()
      }
    },
    setdotPosition() {
      return { ...this.setLeft(), bottom: `${this.value.dotbottom || 0}px` }
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.refresh = false
        this.refresh = true
        if (newVal.data.length > 0) {
          if (newVal.data[0].media_type == 'video' && newVal.data[0].videoUrl && newVal.data[0].autoplay) {
            this.$nextTick(() => {
              this.$refs['swiperVideo_0'] && this.$refs['swiperVideo_0'][0].play()
            })
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    setCarouselHeight() {
      const h = this.value.height * this.offsetWidth / 375
      return { height: `${h}px` }
    }
  }
}
</script>
