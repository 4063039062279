<template>
  <div class="wgt_product_shelves" :style="{ ...comStyle, minHeight: height }">
    <el-image v-if="value.combg.bgType == 'pic' && value.combg.imageUrl" :src="value.combg.imageUrl"
      class="wgt_product_shelves_bg" @load="handleLoad" />
    <!-- <div class="wgt_product_shelves_store" :style="storeStyle" v-if="value.showShelves">
      当前门店
    </div> -->
    <div class="" v-if="!loading">
      <div class="category-list"
        v-if="value.shelvesData.shelfType == 'rule' && value.shelvesData.source == 'classify' && value.shelvesData.showClassify">
        <div class="category-item" v-for="(item, index) in categoryList" :key="index"
          :class="{ 'category-item-active': index == activeIndex }" @click="handleClick(index)">
          {{ item.category_name }}
        </div>
        <div class="el-icon-arrow-down" v-if="categoryList.length > 0"></div>
      </div>
      <div class="filter" v-if="value.shelvesData.shelfType == 'rule' && value.shelvesData.source == 'classify' && value.shelvesData.showClassify">
        <div class="filter-left-container">
          <div class="filter-item" v-for="(item, index) in brandList" :key="index">
            {{ item.label }}
            <div v-if="index == 2" class="filter-item-icon">
              <i class="el-icon-arrow-up"></i>
              <i class="el-icon-arrow-down"></i>
            </div>

          </div>
        </div>
        <div className='filter-right-container'
          v-if="value.shelvesData.shelfType == 'rule' && value.shelvesData.source == 'classify' && value.shelvesData.showParam">
          <div class="filter-item">
            <span className='iconfont icon-filter'></span>
            <span className='txt'>筛选</span>
          </div>
        </div>
      </div>
      <!-- 有数据 -->
      <div class="wgt_product_shelves_content" @mousedown="handleStarSlider" @mousemove="handleslide"
        @mouseup="handleendSlide" @mouseenter="handleendSlide" :style="contentStyle"
        v-if="value.shelvesData.data.length > 0 && value.shelvesData.shelfType == 'rule'" ref="nav">
        <div v-for="(item, index) in goodsItem" :class="{
          'product_shelves_item': true,
          'product_shelves_item-transverse': value.show_type,
        }" :style="itemStyle(index)" :key="`config_${index}`">
          <SpImage class="product_shelves_img" :src='item.home_pic'
            :style="`paddingBottom:${value.imgPaddedBottom}px;width:${value.imgWidth}%`" />
          <div class="product_shelves_info">
            <p class="info_item_text" :style="setInfoStyle(index, 'offset', item, !!item.discount)"
              v-if="value.shelvesData.shelfType == 'rule'">
              {{ item.discount ? "最高可折抵xxx元" : "" }}</p>
            <p class="info_item_text" :style="setInfoStyle(index, 'marketing', item, !!item.marketingInfo)"
              v-if="value.shelvesData.shelfType != 'history' && value.shelvesData.shelfType != 'recommend'">{{
                item.selfInfo ? item.selling_text : item.marketingInfo }}</p>
            <p class="info_title" :style="setInfoStyle(index, 'title', item, true)">{{ item.item_name }}</p>
            <p class="info_item_text" :style="setInfoStyle(index, 'salePrice', item)"> <span
                style="padding-right: 2px;">¥</span><span>{{ item.price / 100
                }}</span>&nbsp;<span>起</span><span>*</span>
            </p>
          </div>
        </div>
        <div class="wgtshelvesMore more" v-if="showMore" @click='handleMore'>查看更多<i class="el-icon-arrow-down"></i>
        </div>
        <div class='mac-recommend' v-if="value?.shelvesLink && value.show_type">
          <div class='mac-recommend-suitable' v-if="value.shelvesLink.device_recommend">
            <SpImage :src="value.shelvesLink.device_recommend_img" fit="cover" />
          </div>
          <div class='mac-recommend-compare' v-if="value.shelvesLink.device_compare">
            <SpImage :src="value.shelvesLink.device_compare_img" fit="cover" />
          </div>
        </div>
      </div>
      <!-- 无数据展示 -->
      <div class="wgt_product_shelves_content" :style="contentStyle" @mousedown="handleStarSlider"
        @mousemove="handleslide" @mouseup="handleendSlide" @mouseenter="handleendSlide" ref="nav" v-else>
        <div v-for="(item, index) in value.goodsSetting" :class="{
          'product_shelves_item': true,
          'product_shelves_item-transverse': value.show_type,
        }" :style="itemStyle(index)" :key="`config_${index}`">
          <SpImage class="product_shelves_img" :style="`width:${value.imgWidth}%`" />
          <div class="product_shelves_info" :style="`paddingTop:${value.imgPaddedBottom}px;`">
            <p class="info_item_text" :style="setInfoStyle(index, 'offset', item, true)"
              v-if="value.shelvesData.shelfType == 'rule'">{{ item.discount ? "最高可折抵xxx元" : "" }}
            </p>
            <p class="info_item_text" :style="setInfoStyle(index, 'marketing', item, true)"
              v-if="value.shelvesData.shelfType == 'rule'">商品营销消息</p>
            <p class="info_title" :style="setInfoStyle(index, 'title', item, true)">商品标题</p>
            <p class="info_item_text" :style="setInfoStyle(index, 'salePrice', item)">
              <span style="padding-right: 2px;">¥</span><span>0</span>&nbsp;<span>起</span><span>*</span>
            </p>
            <!-- <p class="info_discount_price" :style="setInfoStyle(index, 'discount')">商品划线价</p>
          <p class="info_discount_btn" :style="setInfoStyle(index, 'btn')">加购</p> -->
          </div>
        </div>
        <div class="wgtshelvesMore more" v-if="showMore" @click='handleMore'>查看更多<i class="el-icon-arrow-down"></i>
        </div>
        <div class='mac-recommend' v-if="value?.shelvesLink && value.show_type">
          <div class='mac-recommend-suitable' v-if="value.shelvesLink.device_recommend">
            <SpImage :src="value.shelvesLink.device_recommend_img" fit="cover" />
          </div>
          <div class='mac-recommend-compare' v-if="value.shelvesLink.device_compare">
            <SpImage :src="value.shelvesLink.device_compare_img" fit="cover" />
          </div>
        </div>
      </div>
    </div>
    <el-progress v-if="value.showProgress && !value.show_type" :percentage="percentage" :stroke-width="2"
      :show-text="false" :define-back-color="value.pilot.dot_noCover" :color="value.pilot.dot_cover"
      :style="{ bottom: `${value.processh || 0}px` }"></el-progress>
  </div>
</template>

<script>
import { cloneDeep, value } from 'lodash-es'
import config from './config'

export default {
  name: 'ProductShelves',
  wgtName: '商品货架',
  wgtDesc: '',
  wgtIcon: 'wgt-productShelves',
  wgtType: 1,
  wgtTem: 1, //0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      localValue: null,
      isMoved: false, // 是否按下鼠标
      startX: 0, // 鼠标起始位置
      scrollLeft: 0,// 滚动条位置
      defaultPercentage: 0,
      percentage: 0,
      height: 'auto',
      titleHeight: 0,
      offsetHeight: 0,
      marketingHeight: 0,
      loading: false,
      showMore: false,
      categoryList: [],
      activeIndex: 0,
      brandList: [
        { label: '全部', value: 'all' },
        { label: '原厂配件', value: 'original' },
        { label: '价格', value: 'price', sort: true }
      ]
    }
  },
  mounted() {
    this.setPercentage()
  },
  watch: {
    value: {
      handler(val) {
        this.localValue = val
        this.titleHeight = 0
        this.offsetHeight = 0
        this.marketingHeight = 0
        this.setPercentage()
        this.changeMore()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {

    goodsItem() {
      if (this.showMore) {
        console.log(this.value.shelvesData.shownum)
        const data = cloneDeep(this.value.shelvesData.data)
        return data.slice(0, this.value.shelvesData.shownum)
      } else {
        return this.value.shelvesData.data
      }

      // value.shelvesData.data


    },
    comStyle() {
      const { combg, compadded } = this.value || {}
      let style = {
        backgroundColor: '',
        'paddingTop': `${compadded.paddedt || 0}px`,
        'paddingBottom': `${compadded.paddedb || 0}px`,
        'paddingLeft': `${compadded.paddedl || 0}px`,
        'paddingRight': `${compadded.paddedr || 0}px`,
        // backgroundImage: '',
        // backgroundSize: '100% auto',
        // backgroundPosition: 'top',
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: '#fff' //todo
      }
      if (combg.bgType == 'color') {
        style.backgroundColor = combg.color
      }
      //  else {
      //   style.backgroundImage = `url(${combg.imageUrl})`
      // }
      return style
    },
    storeStyle() {
      const { storestyle: { bgType, color, imageUrl } } = this.value || {}
      let style = null
      if (bgType == 'color') {
        style = {
          backgroundColor: color
        }
      } else {
        style = {
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: '100% auto',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
        }
      }
      return style

    },
    contentStyle() {
      const { show_type, radius, contentcolor } = this.value || {}
      if (show_type) {
        return {
          'display': 'flex',
          "justify-content": "space-between",
          "flex-wrap": "wrap",
          borderRadius: `${radius?.upleft}px ${radius?.upright}px ${radius?.bottomright}px ${radius?.bottomleft}px`,
          backgroundColor: contentcolor

        }
      } else {
        return {
          'display': 'flex',
          "overflow-x": 'hidden',
          overflowY: 'hidden',
          'scroll-behavior': 'smooth',
          ' -webkit-overflow-scrolling': 'touch',
          ' scroll-snap-type': 'x mandatory',
          borderRadius: `${radius?.upleft}px ${radius?.upright}px ${radius?.bottomright}px ${radius?.bottomleft}px`,
          backgroundColor: contentcolor

        }
      }

    }
  },
  methods: {
    handleLoad(e) {
      const { width, height } = e.target
      this.height = `${height}px`
    },
    setInfoStyle(index, key, item, isTrue) {
      const { setting, goodsSetting } = this.value || {}
      if (index <= 3 && goodsSetting[index][key]?.independent) {
        const size = goodsSetting[index][key].fontSize
        this.setHeight(item, size, key, isTrue)
        return this.getStyle(goodsSetting[index][key], key)
      } else {
        const size = setting[key].fontSize
        this.setHeight(item, size, key, isTrue)
        return this.getStyle(setting[key], key)
      }
    },
    // 每个商品项的内容 高度保持一致 取fonssize最高的一项 行高乘以字体的1.5倍
    async setHeight(item, size, type, isTrue) {
      const defaultSize = size
      switch (type) {
        case 'offset':
          if (!isTrue) return
          this.offsetHeight = defaultSize > this.offsetHeight ? defaultSize : this.offsetHeight
          return
        case 'marketing':
          if (!isTrue) return
          this.marketingHeight = defaultSize > this.marketingHeight ? defaultSize : this.marketingHeight
          return
        case 'title':
          if (!isTrue) return
          this.titleHeight = defaultSize > this.titleHeight ? defaultSize : this.titleHeight
          return
      }
    },
    getStyle(item, type) {
      const { open, fontSize, fontColor, maginbtm, magintop, bgColor, paddedhor, paddedve, borderWidth, borderColor, borderRadius, bold = false } = item
      if (!open) {
        return { display: 'none' }
      } else {
        const defaultStyle = {
          'font-size': `${fontSize}px`,
          'color': fontColor,
          'margin-bottom': `${maginbtm}px`,
          'margin-top': `${magintop}px`,
          'padding': `${paddedhor}px ${paddedve}px`,
          'border': `${borderWidth}px ${borderColor} solid`,
          'border-radius': `${borderRadius}px`,
          'background-color': bgColor,
          'font-weight': bold ? 'bold' : 'normal'
        }
        if (type === 'offset') {
          return {
            ...defaultStyle,
            'text-decoration': 'none',
            height: `${this.offsetHeight}px`,
            lineHeight: `${this.offsetHeight}px`,
          }
        }
        if (type === 'discount') {
          return {
            ...defaultStyle,
            'text-decoration': item.line ? "line-through" : 'none',
          }
        }
        if (type == 'title') {
          return {
            ...defaultStyle,
            height: `${this.titleHeight}px`,
            lineHeight: `${this.titleHeight}px`,
          }
        }
        if (type == 'marketing') {
          return {
            ...defaultStyle,
            height: `${this.marketingHeight}px`,
            lineHeight: `${this.marketingHeight}px`,
          }
        }
        if (type === 'btn') {
          if (item.suspension) {
            return {
              ...defaultStyle,
              position: 'absolute',
              top: '10px',
              right: '10px',
            }
          } else {
            return defaultStyle
          }
        }
        return defaultStyle
      }
    },
    itemStyle(index) {
      const bgSetting = this.value.goodsSetting[index]?.bgSetting
      const { show_type, itembg, itempadded, border, itemmargin, width } = this.value || {}
      let style = {
        backgroundColor: '',
        'paddingTop': `${itempadded.paddedt || 0}px`,
        'paddingBottom': `${itempadded.paddedb || 0}px`,
        'paddingLeft': `${itempadded.paddedl || 0}px`,
        'paddingRight': `${itempadded.paddedr || 0}px`,
        backgroundImage: '',
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        border: `${border.borderWidth || 0}px ${border.borderColor || 'transparent'} solid`,
        borderRadius: `${border.borderRadius || 0}px`,
        marginRight: `${itemmargin.paddedr || 0}px`,
        marginBottom: `${itemmargin.paddedb || 0}px`,
        minWidth: `calc(50% - ${itemmargin.paddedr / 2 || 0}px)`,
        maxWidth: `calc(50% - ${itemmargin.paddedr / 2 || 0}px)`
      }
      if (!show_type) {
        style.minWidth = `${width}px`
      }
      if (bgSetting && bgSetting.independent) {
        if (bgSetting.bgType == 'color') {
          style.backgroundColor = bgSetting.color
          return style
        } else {
          style.backgroundImage = `url(${bgSetting.imageUrl})`
          return style
        }
      }
      if (this.value.setting?.bgSetting?.bgType == 'color') {
        style.backgroundColor = this.value.setting.bgSetting.color
        return style
      } else {
        style.backgroundImage = `url(${this.value.setting?.bgSetting?.imageUrl})`
        return style
      }
    },
    handleStarSlider(e) {
      const currencyItemsRef = this.$refs.nav
      this.isMoved = true
      this.startX = e.pageX - currencyItemsRef.offsetLeft;
      this.scrollLeft = currencyItemsRef.scrollLeft;
    },
    handleslide(e) {
      const currencyItemsRef = this.$refs.nav
      const { scrollWidth, offsetWidth } = currencyItemsRef
      if (!this.isMoved || scrollWidth == offsetWidth) return;
      e.preventDefault();
      const x = e.pageX - currencyItemsRef.offsetLeft;
      const walk = (x - this.startX) * 2; // 滑动距离
      let left
      if (walk > 0) {
        left = this.scrollLeft - offsetWidth;
      } else {
        left = this.scrollLeft + offsetWidth;
      }
      currencyItemsRef.scrollLeft = left
      const percentage = left / (scrollWidth - offsetWidth)
      if (percentage > 1) {
        this.percentage = 100
      } else if (percentage < this.defaultPercentage / 100) {
        this.percentage = this.defaultPercentage
      } else {
        this.percentage = percentage * 100
      }
    },
    handleendSlide(e) {
      this.isMoved = false;
    },
    setPercentage() {
      setTimeout(() => {
        const ref = this.$refs.nav
        if (!ref) return
        const { scrollWidth, offsetWidth } = ref
        const defaultPercentage = 100 / (scrollWidth / offsetWidth)
        this.defaultPercentage = defaultPercentage
        if (scrollWidth == offsetWidth) {
          this.percentage = 100
        } else {
          this.percentage = defaultPercentage
        }
      }, 1000)
    },
    changeMore() {
      this.showMore = this.value.show_type && this.value.shelvesData.data.length > this.value.shelvesData.shownum
    },
    handleMore() {
      this.showMore = !this.showMore
    }
  },
  mounted() {
    this.setPercentage()
    this.changeMore()
  },
  watch: {
    'value.shelvesData': {
      async handler(newVal) {
        const { categoryId = '', source = '', shelfType } = newVal
        if (shelfType == 'rule' && source == 'classify' && categoryId) {
          const res = await this.$api.goods.getCategory()
          this.categoryList = res.find(item => item.category_id == categoryId)?.children
        }
        this.changeMore()
      },
      deep: true,
      immediate: true
    },
    'value.show_type': {
      handler(newVal) {
        this.changeMore()
      }
    }
  }

}
</script>

<style scoped lang="scss">
.wgt_product_shelves {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .info_title {
    max-width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .info_item_text {
    max-width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .wgt_product_shelves_bg {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;

  }

  ::-webkit-scrollbar {
    background-color: transparent;
    /* Chrome Safari */
  }

  &_store {
    height: 110px;
    line-height: 110px;
    font-size: 14px;
    color: #333;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 15px;
  }

  &_content {
    overflow: hidden;

    .product_shelves_item {
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.product_shelves_item-transverse {
        &:nth-child(2n) {
          margin-right: 0px !important;
        }
      }

      .product_shelves_img {
        aspect-ratio: 1 / 1;
        margin: 0 auto;
        max-width: 100%;
      }

      .product_shelves_info {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .more {
      text-align: center;
      width: 100%;
      line-height: 40px;
      cursor: pointer;
    }
  }

  .el-progress {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    margin: 10px auto 10px;
  }

  .el-progress {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    margin: 0 auto;
  }

  .mac-recommend {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #687BF2;
    background-color: #fff;

    &-compare,
    &-suitable {
      flex: 1;
      box-sizing: border-box;
      text-align: center;
      height: 48px;

      .el-image {
        height: 48px;
      }
    }
  }

  .category-list {
    width: 100%;
    white-space: nowrap;
    margin-bottom: 18px;

    .category-item {
      display: inline-block;
      // background: #ececee;
      border-radius: 13px;
      padding: 0px 12px;
      line-height: 27px;
      margin-right: 9px;

      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6E6E73;


      &-active {
        background: #fff;
        // color: #687BF2;
        color: #1D1D1F;
        font-weight: bold;
      }

    }
  }

  .filter {
    border-top: 1px solid #E4E4E6;
    width: 100%;
    height: 36px;
    white-space: nowrap;
    display: flex;

    align-items: center;
    justify-content: space-between;

    &-item {
      display: flex;

      // background: #ececee;
      border-radius: 13px;
      padding: 0px 12px;
      line-height: 27px;
      margin-right: 9px;
      position: relative;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #6E6E73;

      &-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 5px;

        i {
          font-size: 10px;
        }
      }
    }

    &-left-container {
      flex: 1;
      display: flex;
    }

    &-right-container {
      align-items: center;

      .brand-item {
        display: flex;
        margin: 0px;
      }

      .icon-filter {
        font-size: 16px;
      }
    }
  }

}
</style>