<style lang="scss" scoped>
.hot-content {
  margin-top: 10px;
}
.right-container {
  max-height: 370px;
  overflow: auto;
}

.zone-item {
  margin-bottom: 16px;
}
</style>
<template>
  <div>
    <!-- <el-button plain size="small" @click="onAddZoneChange">添加热区</el-button> -->

    <!-- <SpImagePicker v-model="localValue.imgUrl" size="small" /> -->

      <CompTodoList v-model="localValue" v-if="refresh" @onAddItem="handleAddTabs" @onDelete="handleDelTabs" btnText="添加热区" :isMove='false'>
        <template slot="body" slot-scope="scope">
          <CompButton
            placeholder="热区图"
            format="{0}个热区"
            :value="localValue[scope.index].list.length"
            :view-btn="false"
            @click="onSetHotZone(scope.index)"
            @remove="onRemoveHotZone(scope.index)"
          />
          <div
            v-for="(item, index) in localValue[scope.index].list"
            :key="`copy-zone-item__${index}`"
            class="zone-item"
            style="margin-top: 10px;"
          >
            <AttrHotPicker
              :isShowH5Link="false"
              :value="item"
              :dataRes="datas"
              :isShowTabs="false"
              @change="(e) => onChangeRightLink(e, scope.index, index)"
            />
          </div>
        </template>
      </CompTodoList>


    <el-dialog
      :visible="dialog"
      class="sp-tabs-dialog"
      append-to-body
      destroy-on-close
      title="热区设置"
      width="800px"
      @close="onCancel"
    >
      <div v-if="dialog" class="">
        <div>
          <el-button type="primary" size="small" plain @click="onSelectImage"> 选择图片 </el-button>
          <span style="font-size: 12px; color: #888; margin-left: 4px"
            >建议尺寸:（宽度640px，高度自适应）</span>
        </div>

        <div class="hot-content">
          <el-row :gutter="24">
            <el-col :span="12">
              <hotzone
                class="hotzone"
                :image="localValue[dialogIndex].imgUrl"
                :zones-init="localValue[dialogIndex].list"
                @add="handleAddZone"
                @change="handleZoneChange"
                @remove="handleZoneRemove"
              />
            </el-col>
            <el-col :span="12" v-if="ischange" class="right-container">
              <div
                v-for="(item, index) in localValue[dialogIndex].list"
                :key="`zone-item__${index}`"
                class="zone-item"
              >
                <AttrHotPicker
                  :isShowH5Link="false"
                  :value="item"
                  :dataRes="datas"
                  @change="(e) => onChangeLink(e, dialogIndex, index)"
                />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onConfirm"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import hotzone from 'vue-hotzone'
import { cloneDeep } from 'lodash'
import AttrHotPicker from './attr_hotpicker.vue'
import CompButton from '../../comps/comp-button'
import CompTodoList from '../../comps/comp-todoList'
export default {
  name: 'AttrSetting',
  components: {
    AttrHotPicker,
    CompButton,
    hotzone,
    CompTodoList
  },
  props: {
    value: {
      type: Array,
    },
    uuid: {
      type: String
    },
    datas: {
      type: [Object, Array],
      default: () => { }
    }
  },
  data() {
    return {
      localValue: [],
      dialog: false,
      refresh: true,
      ischange: true
    }
  },
  watch: {
    value: {
      deep: true,
      handler: function (nVal, oVal) {
        this.localValue = cloneDeep(nVal)
      }
    }
  },
  created() {
    this.localValue = cloneDeep(this.value)
  },
  methods: {
    onSetHotZone(index) {
      this.dialog = true
      this.dialogIndex = index
    },
    onRemoveHotZone(index) {
      this.localValue.list = []
      this.$emit('change', this.localValue)
      this.refresh = false
      this.$nextTick(() => {
        this.refresh = true
      })
    },
    async onSelectImage() {
      const {
        data: { url }
      } = await this.$picker.image({
        data: { url: this.value.imgUrl }
      })
      this.localValue[this.dialogIndex].imgUrl = url
    },
    handleZoneChange(zone) {
      setTimeout(() => {
        zone.forEach((item, index) => {
          if (item.leftPer) {
            const { heightPer, widthPer, leftPer, topPer } = item
            const obj = {
              heightPer,
              leftPer,
              topPer,
              widthPer,
            }
            Object.assign(this.localValue[this.dialogIndex].list[index], obj)
          }
        })
      }, 500)
    },
    handleAddZone () {
      this.localValue[this.dialogIndex].list.push({
        hotzoneType: 'link',
        hotzoneTypeIndex: 0
      })
    },
    handleZoneRemove(index) {
      this.ischange = false
      this.localValue[this.dialogIndex].list.splice(index, 1)
      this.$nextTick(() => {
        this.ischange = true
      })
    },
    onChangeLink(e, pindex, index) {
      const v = cloneDeep(this.localValue[pindex].list[index])
      const obj = { ...v, ...e }
      Vue.set(this.localValue[pindex].list, index, obj)
    },
   async onChangeRightLink(e, pindex, index) {
      await this.onChangeLink(e, pindex, index)
      this.$emit('change',this.localValue)
    },
    onCancel() {
      this.dialog = false
    },
    onConfirm() {
      this.$emit('change', this.localValue)
      this.dialog = false
      this.ischange = false
      this.refresh = false
      this.$nextTick(() => {
        this.ischange = true
        this.refresh = true
      })
    },
    async handleAddTabs() {
      const item = {
        list: [],
        imgUrl: ''
      }
      this.localValue.push(item)
      this.$emit('change', this.localValue)
    },
    handleDelTabs (index) {
      this.$emit('change', this.localValue)
    }
  }
}
</script>
