<style lang="scss">
.wgt-horizontal {
  .el-image {
    img {
      min-width: 375px;
    }
  }

  &.padded {
    padding: 10px 0;
  }

  .wgt-hd {
    padding: 10px;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }

    .sub-title {
      color: #666;
      margin-left: 4px;
    }
  }

  .wgt-bd {
    position: relative;
    overflow: hidden;

    .el-progress {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      margin: 10px auto 10px;
    }
  }

  &_content {
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }

    .carousel {
      // align-items: center;
      // justify-content: center
    }
  }
}
</style>
<style lang="scss">
.wgt-horizontal {
  .el-image__inner {
    width: fit-content;
  }
}
</style>
<template>
  <div :class="{
    'wgt-horizontal': true,
    'padded': value.padded
  }" 
  :style="styleSetting">
    <div v-if="value.title || value.subtitle" class="wgt-hd">
      <span class="title">{{ value.title }}</span>
      <span class="sub-title">{{ value.subtitle }}</span>
    </div>
    <div class="wgt-bd" v-if="value.data.imgUrl" :class="{
      'spaced': value.spaced
    }">
      <div class="wgt-horizontal_content" ref="nav">
        <div class="carousel">
          <SpImage :src="value.data.imgUrl" @load="handleLoad" style="opacity: 0;position: absolute;" />
          <SpImage :src="value.data.imgUrl" :style='`height: ${value.height ? value.height : height}px;width:${width}px`'
            fit="cover" />
        </div>
      </div>
      <el-progress v-if="value.showProgress" :percentage="percentage"  :stroke-width="2" :show-text="false" define-back-color="#dcdcdd" color="#000"></el-progress>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'ImgHorizontal',
  wgtName: '长热区图',
  wgtDesc: '',
  wgtIcon: 'wgt-hotzone',
  wgtType: 1,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      percentage: 0,
      prohibitDrag: false,
      isDown: false, // 是否按下鼠标
      startX: 0, // 鼠标起始位置
      scrollLeft: 0,// 滚动条位置
      height: 0,
      width: 0,
      dewidth: 0,
      deheight: 0,
      defaultPercentage: 0,
    };
  },
  methods: {
    move(e) {
      const currencyItemsRef = this.$refs.nav
      const { scrollWidth, offsetWidth } = currencyItemsRef
      if (!this.isDown || scrollWidth == offsetWidth) return;
      e.preventDefault();
      const x = e.pageX - currencyItemsRef.offsetLeft;
      const walk = (x - this.startX) * 2; // 滑动距离
      let left
      if (walk > 0) {
        left = this.scrollLeft - offsetWidth;
      } else {
        left = this.scrollLeft + offsetWidth;
      }
      currencyItemsRef.scrollLeft = left
      const percentage = left / (scrollWidth - offsetWidth)
      if (percentage > 1) {
        this.percentage = 100
      } else if (percentage < this.defaultPercentage / 100) {
        this.percentage = this.defaultPercentage
      } else {
        this.percentage = percentage * 100
      }
    },
    setPercentage() {
      setTimeout(() => {
        const { scrollWidth, offsetWidth } = this.$refs.nav
        const defaultPercentage = 100 / (scrollWidth / offsetWidth)
        this.defaultPercentage = defaultPercentage
        if (scrollWidth == offsetWidth) {
          this.percentage = 100
        } else {
          this.percentage = defaultPercentage
          this.setDrag()
        }
      }, 1000)
    },
    setDrag() {
      const currencyItemsRef = this.$refs.nav
      currencyItemsRef.addEventListener('mousedown', (e) => {
        this.isDown = true;
        this.startX = e.pageX - currencyItemsRef.offsetLeft;
        this.scrollLeft = currencyItemsRef.scrollLeft;
      });
      currencyItemsRef.addEventListener('mouseup', (e) => {
        this.isDown = false;
      });
      currencyItemsRef.addEventListener('mouseenter', (e) => { this.isDown = false; });
      currencyItemsRef.addEventListener('mousemove', (e) => this.move(e));
    },
    async handleLoad(e) {
      const { width, height } = e.target
      this.dewidth = width
      this.deheight = height
      if (this.value.height > 0) {
        this.height = this.value.height
        this.width = width * (this.value.height / height)
      } else {
        this.height = width / 2 <= 375 ? 428 : height / 4
        this.width = (width <= 375 || width / 4 <= 375) ? 375 : width / 4
      }
      await this.setPercentage()
    }
  },
  watch: {
    'value.data.imgUrl': {
      handler() {
        this.setPercentage()
      },
      deep: true,
    },

    'value.height': {
      handler(val) {
        if (val > 0) {
          this.width = this.dewidth * (val / this.deheight)
          this.setPercentage()
        }
      },
      deep: true,
    }
  },
  computed: {
    styleSetting() {
      return this.value.style ? {
        backgroundImage: `${this.value.style.bgType === 'color' ? '' : `url(${this.value.style.bgPic})`}`,
        backgroundColor: `${this.value.style.bgType === 'color' ? this.value.style.bgColor : ''}`,
        backgroundSize: '100% auto',
        backgroundPosition: 'top', backgroundRepeat: 'no-repeat',
        padding: `${this.value.style.paddedt || 0}px ${this.value.style.paddedr || 0}px ${this.value.style.paddedb || 0}px ${this.value.style.paddedl || 0}px`,
      } : ''
    }
  }
}
</script>