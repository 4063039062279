<template>
  <div class='color-list'>
    <div class='el-color-picker-wrap' v-for="(item, key) in localValue" :index="key">
      <el-color-picker v-model='localValue[key]' size='small' @change="handleReset($event,key)"/>
      <span class="cell-value-tip">
        {{ key == 'bg_color' ? '底色' : '' }}
        {{ key == 'default_color' ? '默认色' : '' }}
        {{ key == 'default_color_font' ? '默认字体色' : '' }}
        {{ key == 'checked_color' ? '选中色' : '' }}
        {{ key == 'checked_color_font' ? '选中字体色' : '' }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      localValue: null,
      initValue:{
        bg_color: "#1d1d1f",
        default_color: "#1d1d1f",
        default_color_font: "#888888",
        checked_color: "#1d1d1f",
        checked_color_font: "#ffffff",
      }
    }
  },
  props: {
    value: {
      type: Object||Array,
      default: ()=>[]
    },
    uuid: {
      type: String,
      default: ''
    },
  },
  watch: {
    uuid: {
      handler() {
        this.localValue = this.value
      },
      deep: true,
      immediate: true
    },
    localValue: {
      handler(val) {
        this.$emit('input', val)
      },
      deep: true
    }
  },
  methods: {
    handleReset(e,key) {
      if(e) return
      this.$set(this.localValue, key, this.initValue[key])
    }
  }
}
</script>

<style scoped>
.color-list {
  display: flex;
  flex-wrap: wrap;
    margin-top: -10px;
  width: 100%;

  .el-color-picker-wrap {
    width: 33.33%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

}
</style>