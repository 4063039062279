<template>
  <div class="attr-select">
    <el-select v-model="localValue">
      <el-option v-for="item in selectShopOptions" :key="+item.rule_id" :label="item.rule_title" :value="+item.rule_id"/>
    </el-select>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { getShowRuleList } from '@/api/template'
export default {
  name: 'AttrSelect',
  props: {
    value: {
      type: Number,
      default: 0
    },
    uuid:{
      type:String
    }
  },
  data(){
    return {
      localValue: 0,
      selectShopOptions: []
    }
  },
  methods: {
    async getShopList () {
      const res = await getShowRuleList({page: 1, page_size: -1, platform: 'wxapp'})
      const data = [
        { rule_id: 0, rule_title: '全部门店' },
        ...res.data.data.list
      ]
      this.selectShopOptions = data
    }
  },
  watch: {
    localValue (nVal) {
      this.$emit('input', nVal)
    },
    uuid: {
      handler(val) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true,
      deep: true
    }
  },
  created(){
    this.getShopList()
  }
}
</script>