<template>
  <div class="attr-custom">
    <span>滑到</span>
    <el-select size="mini" style="" v-model="localValue">
      <el-option v-for="(item,index) in list" :key="`attr-custom-setting-${index}`" :label="`楼层${index+1}-${item.wgtName}`" :value="item.uuid" />
    </el-select>
    <span>打开头部导航</span>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'AttrVertical',
  props: {
    type: {
      type: String
    },
    info: {
      type: Array,
      default: () => []
    },
    value: {
      type: String
    }
  },
  data(){
    return {
      localValue:undefined
    }
  },
  watch: {
    localValue(val){
      const index = this.list.findIndex((item,index)=>item.uuid==val)
      this.$emit('input', index.toString())
    },
    list:{
      handler(val){
          if(!this.value) return
          const data = val.find((item,index)=>index==this.value)
          this.localValue=data?.uuid || uuidv4()
      },
      deep:true,
      immediate:true
    }
  },
  computed: {
    list(){
      return this.info.map(item=>{
        if(!item.uuid){
          item.uuid = uuidv4()
        }
        return item
      }).slice(1,this.info.length)
    }
  },
  created(){
  }
}
</script>

<style lang="scss" scoped>
.attr-custom{
  .el-select{
    width: 140px;
    padding: 0 5px;
  }
}
</style>
