<template>
  <div class="wgt_product-shelves-bg-item">
    <el-radio-group v-model="localValue.bgType" size="mini">
      <el-radio-button label="color">颜色</el-radio-button>
      <el-radio-button label="pic">图片</el-radio-button>
    </el-radio-group>
    <div class="bg-color" v-if="localValue.bgType=='color'">
      <el-color-picker v-model='localValue.color' size='small' />
      <p>背景颜色</p>
    </div>
    <div class="bg-pic" v-if="localValue.bgType=='pic'">
      <SpImagePicker v-model="localValue.imageUrl" size="small" text="" />
      <p>背景图片</p>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    uuid:{
      type:String,
      default:''
    },
    value:{
      type:Object,
      default:()=>{
        return {
          bgType:'color',
          color:'',
          imageUrl:''
        }
      }
    }
  },
  data() {
    return {
      localValue: {
        color: '#fff',
        imageUrl: ''
      },
    }
  },
  watch:{
    uuid:{
      handler(){
        this.localValue=this.value
      },
      immediate:true
    }
  }
}
</script>

<style lang="scss" scoped>
.wgt_product-shelves-bg-item {
  display: flex;

  .bg-color {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
    margin-left: 15px;

    p {
      padding-top: 5px;
    }
  }

  .bg-pic {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
    margin-left: 15px;
    p {
      padding-top: 5px;
    }
  }
}
</style>
<style lang="scss">
.wgt_product-shelves-bg-item {

  .image-item {
    width: 32px !important;
    height: 32px !important;
    margin: 0px;
  }

  .iconfont {
    font-size: 14px;
  }

  .add-text {
    display: none;
  }
}
</style>