<template>
  <div class="style-setting">
    <el-color-picker v-model="localValue.color" size="small"></el-color-picker>
    <el-input-number v-model="localValue.fontSize" :min="10" controls-position="right" size="mini"></el-input-number>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        color: '#000',
        fontSize: 14
      })
    },
    uuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: {
        color: '#000',
        fontSize: 14
      },
    }
  },
  watch: {
    uuid: {
      handler() {
        this.localValue = this.value
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.style-setting {
  display: flex;
  align-items: center;
  .el-color-picker{
    margin-right: 10px;
  }
}
</style>