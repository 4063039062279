import { pickBy, isObject } from "@/utils";
import Vue from "vue";

export default {
  name: "functionBlock",
  setting: [
    {
      label: "", key: "content", component: function (h, { key }) {
        return <div style="text-align: center; height: 50px; line-height: 50px;">功能区不能编辑</div>;
      }, value: ""
    },
  ],
  transformIn: (v) => {
    const {
      name,
      base
    } = v;
    return {name,base};
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: "base"
    });
  },
};
