<template>
  <el-container class="container">
    <router-view>
    </router-view>
  </el-container>
</template>

<script>
  export default {
    mounted () {
      const wheight = window.innerHeight
      const wwidth = window.innerWidth
      this.$store.dispatch('setHeight', wheight)
      this.$store.dispatch('setWidth', wwidth)
      window.onresize = () => {
        const height = window.innerHeight
        const width = window.innerWidth
        this.$store.dispatch('setHeight', height)
        this.$store.dispatch('setWidth', width)
      }
    }
  }
</script>

<style lang="scss">

</style>
