<template>
  <SpForm class="content_part-nav-setting" ref='form' v-model="localValue" suffix="" :formList='navFormList'
    :submit='false' labelWidth='80px' />
</template>

<script>
import CompsStyle from "../../comps/comps-style.vue";
export default {
  name: "nav-setting",
  props: {
    value: {
      type: Object,
      default: () => { }
    },
    uuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: {},
    }
  },
  methods: {},
  computed: {
    navFormList() {
      const { navbgtype } = this.localValue
      return [
        {
          label: '导航栏背景',
          key: "navbgtype",
          type: 'radio-button',
          options: [
            { name: "背景颜色", label: '0' },
            { name: "背景图片", label: '1' },
          ],
        },
        {
          label: '导航栏底色',
          key: "navbgcolor",
          type: 'color',
          isShow: () => {
            return navbgtype == 0
          }
        },
        {
          label: '导航栏背景图',
          key: "imgUrl",
          component: ({ key }) => {
            return <SpImagePicker v-model={this.localValue[key]} size="small" />;
          },
          isShow: () => {
            return navbgtype == 1
          }
        },
        {
          label: '导航栏内边距',
          key: "navpadded",
          component: ({ key }) => {
            return <CompsStyle
              showBgsetting={false}
              v-model={this.localValue[key]}
              showLabel={false}
              uuid={`${this.uuid}_${key}`}
            />
          },
        },  {
          label: "导航形式",
          key: "navtype",
          type: "radio-button",
          options: [
            { name: "叠加滚动", label: '0' },
            { name: "通栏等分", label: '1' },
          ],
        },{
          label: "导航项底色",
          key: "navitemcolor",
          type: "color",
        },
       // {
        //  label: '导航项内边距',
        //  key: "navitempadded",
        //  component: ({ key }) => {
         //   return <CompsStyle
           //   showBgsetting={false}
         //     v-model={this.localValue[key]}
           //   showLabel={false}
           //   uuid={`${this.uuid}_${key}`}
          //  />
          //},
        //},
        {
          label: "导航项高度",
          key: "navitemHeight",
          type: "number",
          position: "right",
          min: 24,
          size: "mini",
        },
        {
          label: "导航项圆角",
          key: "navitemradius",
          type: "number",
          position: "right",
          min: 0,
          size: "mini",
        }, {
          label: "轮播项内边距",
          key: "slideritempadded",
          component: ({ key }) => {
            return (
              <CompsStyle
                v-model={this.localValue[key]}
                showTop={false}
                showLeft={false}
                showBottom={false}
                showRight={false}
                showBgsetting={false}
                showLabel={false}
                showTB={true}
                showLR={true}
                uuid={`${this.uuid}_${key}`}
              />
            );
          },
        }, {
          label: "导航左滑示意图",
          key: "leftimgUrl",
          component: ({ key }) => {
            return <div class={"sp-image-picker"}><SpImagePicker v-model={this.localValue[key]} size="small" /></div>
          },
        },
        {
          label: "导航右滑示意图",
          key: "rightimgUrl",
          component: ({ key }) => {
            return <div class={"sp-image-picker"}><SpImagePicker v-model={this.localValue[key]} size="small" /></div>
          }
        },
      ]
    }
  },
  watch: {
    uuid: {
      handler() {
        this.localValue = this.value;
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.content_part-nav-setting {
  margin-top: 10px;
  margin-left: -80px;

  .el-form-item {
    margin-bottom: 10px;
  }

  .el-form-item__label {
    text-align: left;
    color: #888;
    padding: 0px;
    line-height: normal;
    margin-top: 5px;
  }

  .sp-image-picker {
    position: relative;

    .el-icon-circle-close {
      position: absolute;
      top: -5px;
      left: 56px;
      cursor: pointer;
    }
  }
}
</style>