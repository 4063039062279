import { render, staticRenderFns } from "./salesman.vue?vue&type=template&id=74cca620&scoped=true"
import script from "./salesman.vue?vue&type=script&lang=js"
export * from "./salesman.vue?vue&type=script&lang=js"
import style0 from "./salesman.vue?vue&type=style&index=0&id=74cca620&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74cca620",
  null
  
)

export default component.exports