<template>
  <el-select v-model="localValue" placeholder="请选择">
    <el-option
      v-for="item in options"
      :key="item.section_id"
      :label="item.cname"
      :value="item.section_id">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "api_select",
  props:{
    value:{
      type:String,
      default:''
    },
    uuid:{
      type:String,
      default:''
    },
    comTypes:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      localValue:'',
      options: []
    }
  },
  methods: {
    async getOptions() {
      const data = await this.$api.template.getwebSections({num:1000,type:'CAROUSEL'})
      this.options=data
    }
  },
  watch:{
    uuid:{
      handler(val){
        this.localValue=this.value
      },
      immediate:true,
    },
    localValue:{
      handler(val){
        this.$emit('input',val)
      }
    },
    comTypes:{
      handler(val){
        if(val==='ai'){
          this.getOptions()
        }
      },
      immediate:true
    }
  }
}
</script>

<style lang="scss" scoped></style>