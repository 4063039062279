<style lang="scss" scoped>
.hori-content {
  margin-top: 10px;
}
.zone-item {
  margin-bottom: 16px;
}
</style>
<style lang="scss">
.hori-content{
  .hz-u-img{
  min-height: 180px;
}
}

</style>
<template>
  <div class="hot-setting">
    <CompButton
      placeholder="设置长热区"
      format="{0}个长热区"
      :value="value.data.length"
      :view-btn="false"
      @click="onSetHotZone"
      @remove="onRemoveHotZone"
    />

    <div v-if="refresh">
      <div
        v-for="(item, index) in value.data"
        :key="`copy-zone-item__${index}`"
        class="zone-item"
        style="margin-top: 10px"
      >
        <CompHotPicker
          :isShowH5Link="false"
          :value="item"
          @change="(e) => onChangeLocalLink(e, index)"
          wgtType="hotzone"
        />
      </div>
    </div>

    <el-dialog
      :visible="dialog"
      class="sp-dialog"
      append-to-body
      destroy-on-close
      title="长热区设置"
      width="1000px"
      @close="onCancel"
    >
      <div v-if="dialog" class="">
        <div>
          <el-button type="primary" size="small" plain @click="onSelectImage"> 选择图片 </el-button>
          <span style="font-size: 12px; color: #888; margin-left: 4px"
            >建议尺寸:（宽度640px，高度自适应）</span
          >
        </div>
        <div class="hori-content">
          <el-row :gutter="20">
            <el-col :span="18">
              <hotzone
                class="hotzone"
                :image="localValue.imgUrl"
                :zones-init="localValue.data"
                @change="handleChange"
                @remove="handleRemove"
              />
            </el-col>
            <el-col :span="6" v-if="ischange">
              <div
                v-for="(item, index) in localValue.data"
                :key="`zone-item__${index}`"
                class="zone-item"
              >
                <CompHotPicker :value="item" @change="(e) => onChangeLink(e, index)"  wgtType="hotzone"/>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onConfirm"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import hotzone from 'vue-hotzone'
import { cloneDeep } from 'lodash'
import CompHotPicker from '../../comps/comp-hotpicker'
import CompButton from '../../comps/comp-button'
export default {
  name: 'AttrHotSetting',
  components: {
    CompHotPicker,
    CompButton,
    hotzone
  },
  props: {
    value: {
      type: Object
    },
    uuid: {
      type: String
    }
  },
  data() {
    return {
      localValue: {
        imgUrl: '',
        data: []
      },
      dialog: false,
      refresh: true,
      ischange:true
    }
  },
  watch: {
    // localValue: {
    //   deep: true,
    //   handler: function (nVal, oVal) {
    //     this.$emit('input', nVal)
    //   }
    // },
    uuid: {
      deep: true,
      handler: function (nVal, oVal) {
        this.refresh = false
        this.$nextTick(() => {
          this.refresh = true
        })
        this.localValue = cloneDeep(this.value)
      }
    }
  },
  created() {
    this.localValue = cloneDeep(this.value)
  },
  methods: {
    onSetHotZone() {
      this.dialog = true
    },
    onRemoveHotZone() {
      this.localValue.data = []
      this.$emit('input', this.localValue)
      this.refresh = false
      this.$nextTick(() => {
        this.refresh = true
      })
    },
    async onSelectImage() {
      const {
        data: { url }
      } = await this.$picker.image({
        data: { url: this.value.imgUrl }
      })
      this.localValue.imgUrl = url
    },
    handleChange(zone) {
      console.log('handle change, ', zone)
      zone.forEach(({ heightPer, widthPer, leftPer, topPer }, index) => {
        const v = cloneDeep(this.localValue.data[index])
        const obj = {
          // linkType: 0,
          // linkUrl: '',
          heightPer,
          leftPer,
          topPer,
          widthPer
        }
        Vue.set(this.localValue.data, index, {
          ...v,
          ...obj
        })
      })
    },
    handleRemove(index) {
      this.ischange = false
      this.localValue.data.splice(index, 1)
        this.$nextTick(() => {
          this.ischange = true
        })
    },
    async onChangeLocalLink(e, index){
      await this.onChangeLink(e, index)
      this.$nextTick(() => {      
        this.$emit('input', this.localValue)
      })
    },
    onChangeLink(e, index) {
      const v = cloneDeep(this.localValue.data[index])
      Vue.set(this.localValue.data, index, {
        ...v,
        ...e
      })
    },
    onCancel() {
      this.dialog = false
    },
    onConfirm() {
      this.$emit('input', this.localValue)
      this.dialog = false

      this.refresh = false
      this.$nextTick(() => {
        this.refresh = true
      })
    }
  }
}
</script>
