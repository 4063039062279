<template>
  <div class="image-text-attr">
    <CompDragList v-model="localValue" @onAddItem="handleAddItem" type="text" btnText="添加图文" :show-title="true"
      title="内容">
      <template slot="body" slot-scope="scope">
        <div class="image-text-attr-item">
          <SpImagePicker v-model="scope.data.url" size="small" class="image-text-video-link"
            v-if="scope.data.type === 'pic'" />
          <div class="picker-video" @click="handleAddVideo($event, scope.index)"
            v-if="scope.data.type === 'video' && !scope.data.url">
            <i class="iconfont icon-camera" />
          </div>
          <div class="picker-video-player" v-if="scope.data.type === 'video' && scope.data.url">
            <SpImage :src="scope.data.data.cover_pic" fit="cover"  class="picker-video"/>
            <!-- <video-player class="picker-video " :options="{
              aspectRatio: '16:9',
              fluid: true,
              sources: [
                {
                  type: 'video/mp4',
                  src: scope.data.url
                }
              ],
              notSupportedMessage: '此视频暂无法播放，请稍后再试',
              controlBar: false
            }" /> -->
            <i class="ecx-icon el-icon-circle-close" @click="handleClickVideoClose(scope.index)"></i>
            <span class="image-meta" @click="handleAddVideo($event, scope.index)">更换视频</span>
          </div>
          <div class="image-text-attr-item__content">
            <el-radio-group v-model="scope.data.type" size="mini" style="margin-bottom:10px;margin-top:0px"
              @change="handleChangeType(scope.index)">
              <el-radio-button label="pic">图片</el-radio-button>
              <el-radio-button label="video">视频</el-radio-button>
            </el-radio-group>
            <el-input v-model="scope.data.title" placeholder="图文标题"
              style="margin-bottom:10px;margin-top:0px"></el-input>
            <CompHotPicker :isShowH5Link="false" :value="scope.data.data" wgtType="hotzone"
              @change="(e) => onChangeLocalData(e, scope.index)" v-if="scope.data.type === 'pic'" />
            <div class="btn-linkpath" v-if="scope.data.type === 'video'" @click="handleAddVideo($event, scope.index)">
              视频：{{ scope.data.data?.original_material?.material_name }}</div>
          </div>
        </div>
      </template>
    </CompDragList>
  </div>
</template>

<script>
import { pickBy } from "@/utils";
import { cloneDeep } from 'lodash'
import CompDragList from '../../comps/comp-dragList'
import CompHotPicker from '../../comps/comp-hotpicker'
export default {
  name: "attr-item",
  components: { CompDragList, CompHotPicker },
  props: {
    uuid: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    uuid: {
      handler(val) {
        this.localValue = cloneDeep(this.value)
      },
      deep: true,
      immediate: true
    },
    localValue: {
      handler(val) {
        this.$emit('input', val)
      },
      immediate: true
    }
  },
  data() {
    return {
      localValue: []
    }
  },
  methods: {
    handleAddItem() {
      this.localValue.push({ type: 'pic', title: '', url: '', data: null })
    },
    async onChangeLocalData(e, index) {
      this.$set(this.localValue[index], 'data', e)
    },
    handleChangeType(index) {
      this.$set(this.localValue[index], 'url', '')
    },
    async handleAddVideo(e, index) {
      const { data } = await this.$picker.afterFilm({ data: this.localValue[index].data, multiple: false })
      this.$set(this.localValue[index], 'data', data[0])
      this.$set(this.localValue[index], 'url', data[0].original_material?.material_url)
      this.$set(this.localValue[index], 'title', data[0].title)
    },
    handleClickVideoClose(index) {
      this.$set(this.localValue[index], 'data', null)
      this.$set(this.localValue[index], 'url', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.image-text-attr {
  .image-text-attr-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    .image-text-attr-item__content {
      flex: 1;
    }

    .btn-linkpath {
      padding: 0 8px;
      color: var(--themeColor);
      border: 1px solid var(--themeColor);
      background-color: #fff;
      font-size: 12px;
      height: 32px;
      line-height: 32px;
      border-radius: 3px;
      max-width: 200px;
      @include text-overflow();
      cursor: pointer;
    }

    .picker-video {
      height: 64px;
      width: 108px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      &-player {
        position: relative;
        width: 108px;
        margin-right: 10px;

        .el-icon-circle-close {
          position: absolute;
          right: -7px;
          z-index: 99;
          top: -7px;
          font-size: 14px;
          color: #666;
        }

        .image-meta {
          height: 20px;
          width: 100%;
          line-height: 20px;
          text-align: center;
          color: white;
          background-color: rgba(0, 0, 0, 0.4);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 10px;
          font-size: 12px;
          cursor: default;
        }
      }
    }

    .icon-camera {
      font-size: 24px;
      color: #d9d9d9;
    }
  }
}
</style>
<style lang="scss">
.image-text-attr {
  .drag-list {
    border: 1px solid #e0e0e0;

    .item-hd {
      padding: 5px 10px;
      border-bottom: 1px solid #e0e0e0;
    }

    .item-bd {
      padding: 10px 10px 0;

    }

    .comp-picker-link {
      margin-bottom: 10px;
    }
  }
}

.image-text-video-link {

  .placeholder,
  .image-item {
    width: 110px !important;

    .add-text {
      display: none;
    }
  }

}
</style>