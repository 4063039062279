import { pickBy } from "@/utils";
import WgtItem from "./wgt-item";
import { v4 as uuidv4 } from 'uuid'

export default {
  name: "abTest",
  setting: [
    // { label: '楼层', key: 'floor', component: 'input', value: '' },
    // { label: '组件间距', key: 'padded', component: 'switch', value: true }
    {
      label: "实验名称",
      key: "experiment",
      component: function (h, { key }) {
        return (
          <WgtItem
            v-model={this.value[key]}
            on-changeChildren={(e) => {
              this.value.children = e;
            }}
            uuid={this.value.uuid}
          />
        );
      },
    },
  ],
  transformIn: async (v, wgtList) => {
    const { name, base, config, data: { list, ...value } } = v
    // 处理每个 list 元素的函数
    const processListElement = async (ele) => {
      const { children, ...l } = ele
      // 并行处理 children 数组中的每个元素
      const processedChildren = await Promise.all(children.map(async (item) => {
        const widget = wgtList.find(wgt => wgt.name.toLowerCase() === item.name.toLowerCase())
        if (!widget) throw new Error(`Widget not found for ${item.name}`)
        const { transformIn } = widget.config
        const transformedItem = await transformIn(item, wgtList)
        return {
          ...transformedItem,
          wgtName: item.wgtName,
          uuid: uuidv4(),
        }
      }))
      return { ...l, children: processedChildren }
    }
    // 处理 list 数组
    const _list = await Promise.all(list.map(processListElement))

    // 返回结果对象
    return {
      name,
      ...base,
      ...config,
      experiment: {
        ...value,
        list: _list,
        groupIndex: 0,
      },
      children: _list[0]?.children ?? [],
    }
  },
  transformOut: (v, wgtList) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          wgtName: "wgtName",
          wgtDesc: "wgtDesc",
          uuid: "uuid",
        });
      },
      config: () => {
        return {
          style: 1,
        };
      },
      data: ({ experiment: { groupIndex, list, ...data } }) => {
        const _list = list.map((ele) => {
          const { children, ...l } = ele;
          const _children = children.map((item) => {
            const { transformOut } = wgtList.find(
              (wgt) => wgt.name.toLowerCase() == item.name.toLowerCase()
            ).config;
            return { ...transformOut(item, wgtList), wgtName: item.wgtName };
          });
          return { children: _children, ...l };
        });
        return { list: _list, ...data };
      },
    });
  },
};
