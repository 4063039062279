<style lang="scss">
.comp-todolist {
  .todo-list {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .comp-button {
      .el-button {
        padding-right: 8px !important;
      }

      .bc {
        i {
          float: right;
        }
      }
    }
  }

  .el-button--text {
    padding: 0px !important;
  }

  .el-divider--horizontal {
    margin: 10px 0;
  }

  .item-bd {
    flex: 1;
  }

  .item-fd {
    margin-left: 10px;
    max-width: 54px;
  }

  .icon-pen {
    font-size: 14px;
    margin-right: 6px;
  }

  .icon-bars {
    margin-right: 6px;
  }

  .icon-trash-alt1 {
    font-size: 15px;
  }
}
</style>
<template>
  <div class="comp-todolist">
    <draggable :list="localValue" :options="dragOptions" style="width: 100%" handle=".mover">
      <div v-for="(item, index) in localValue" :key="`todo-item__${index}`" class="todo-list">
        <div class="item-bd">
          <slot name="body" :data="item" :index="index" />
        </div>
        <div class="item-fd">
          <i v-if="isEdit" class="iconfont icon-pen" @click="onEdit(item, index)" />
          <i v-if="isMove" class="iconfont icon-bars mover" />
          <i v-if="isDelete" class="iconfont icon-trash-alt1" @click="onRemoveItem(index)" />
        </div>
      </div>
      <el-button v-if="isShow && btnType == 'btn'" slot="footer" :type="type" size="small" @click="handleAddItem">{{
        btnText
      }}</el-button>
      <el-dropdown v-if="isShow && btnType == 'drop'" class="comp-button" @command="handleCommand">
        <el-button type="primary" size="small">
          <p class="bc">
            <span class="line">添加</span><i class="el-icon-arrow-down el-icon--right"></i>
          </p>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in dropdownMenu" :key="item.command" :command="item.command">{{item.label}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'CompTodoList',
  components: {
    draggable
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 100
    },
    btnText: {
      type: String,
      default: '添加'
    },
    isShow: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isMove: {
      type: Boolean,
      default: true
    },
    isDelete: {
      type: Boolean,
      default: true
    },
    btnType: {
      type: String,
      default: 'btn'
    },
    type: {
      type: String,
      default: 'primary'
    },
    dropdownMenu:{
      type: Array,
      default: ()=>[]
    }
  },
  data() {
    return {
      localValue: [],
      dragOptions: {
        animation: 300,
        forceFallback: false,
        scroll: true
      }
    }
  },
  watch: {
    value: {
      handler(nVal) {
        this.localValue = this.value
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleAddItem() {
      if (this.localValue.length >= this.max) {
        this.$message.error(`最多添加${this.max}条`)
        return
      }
      this.$emit('onAddItem')
    },
    onEdit(item, index) {
      this.$emit('edit', { item, index })
    },
    onRemoveItem(index) {
      this.localValue.splice(index, 1)
      this.$emit('onDelete', index)
    },
    handleCommand(command) {
      this.$emit('onAddItem', command)
    },
  }
}
</script>
