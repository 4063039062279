// 软文
import {
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
}


export default {
  widgets,
  initiWidgets
}
