import { isArray } from '@/utils'
import { getPagesContentList } from '@/api/template'
import { gWgts } from '../scene'

export const getWgts = (scene) => {
  const { widgets: wgts, initiWidgets = [] } = gWgts[scene]
  let widgets = [...initiWidgets]
  Object.keys(wgts).forEach((index) => {
    widgets.push(wgts[index])
  })
  return widgets
}

export const transformInTem = async (data, widgets) => {
  try {
    const ids = data?.map(item => item?.data?.pages_template_id) || [];
    if (ids.length === 0) return []
    const res = await getPagesContentList({
      pages_template_ids: ids
    })
    const _data = res?.data?.data || []
    const mergedData = () => {
      return data.map(async item => {
        const { data, ...rest } = item
        const _list = _data[data?.pages_template_id].list || []
        const _tem = await transformOutDateasync(_list, widgets)
        return {
          ...rest,
          cusTom: {
            pages_template_id: data?.pages_template_id,
            template_title: data?.template_title,
            template: _tem
          }
        }
      })
    };
    let _result = await Promise.all(mergedData())
    return _result
  } catch (error) {
  }
}

export const transformSelectTem = async (data, widgets) => {
  try {
    if (isArray(data)) {
      const ids = data?.map(item => item?.pages_template_id) || [];
      if (ids.length === 0) return []
      const res = await getPagesContentList({
        pages_template_ids: ids
      })
      const _data = res?.data?.data || []
      const mergedData = () => {
        return data.map(async item => {
          const { pages_template_id, template_title, } = item
          const _list = _data[pages_template_id].list || []
          const _tem = await transformOutDateasync(_list, widgets)
          return {
            pages_template_id: pages_template_id,
            template_title: template_title,
            template: _tem
          }
        })
      };
    let _result = await Promise.all(mergedData())
      return _result
    } else {
      const { pages_template_id, template_title } = data
      const res = await getPagesContentList({
        pages_template_ids: [pages_template_id]
      })
      const _data = res?.data?.data
      const _tem = await transformOutDateasync(_data[pages_template_id].list, widgets)
      return _tem
    }
  } catch (error) {
  }

}

const transformOutDate = (list, widgets) => {
  const transformedList = [];
  if (!list) return transformedList;
  for (const item of list) {
    const widget = widgets.find(wgt => wgt.name.toLowerCase() === item.name.toLowerCase());
    const { transformIn } = widget?.config || {};
    if (transformIn) {
      const resultOrPromise = transformIn(item.params, widgets);
      if (resultOrPromise instanceof Promise) {
        resultOrPromise.then(result => {
          transformedList.push(result);
        });
      } else {
        transformedList.push(resultOrPromise);
      }
    } else {
      transformedList.push(null);
    }
  }
  return transformedList;
};

const transformOutDateasync = async (list, widgets) => {
  const transformedList = [];
  if (!list) return transformedList;
  for (const item of list) {
    const widget = widgets.find(wgt => wgt.name.toLowerCase() === item.name.toLowerCase());
    const { transformIn } = widget?.config || {};
    if (transformIn) {
      const resultOrPromise = await transformIn(item.params, widgets);
      transformedList.push({ id: item.id, ...resultOrPromise });
    } else {
      transformedList.push(null);
    }
  }
  return transformedList;
};