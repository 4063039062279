import { pickBy } from "@/utils";
import AttrItem from "./attr-item.vue";

export default {
  name: "oldEvaluate",
  setting: [
    { label: "楼层", key: "floor_var", component: "input" },
    { label: "组件间距", key: "padded", component: "switch", value: false },
    {
      label: "评估状态",
      key: "radioOldType",
      component: "radio",
      options: [
        { name: "未评估", label: "on" },
        { name: "已评估", label: "yes" },
      ],
      value: "on",
    },
    {
      label: "",
      key: "data",
      component: function (h, { key }) {
        return <AttrItem v-model={this.value[key]} uuid={this.value.uuid} radioOldType={this.value.radioOldType } />;
      },
      value: [
        {
          label: "未评估",
          old_type: "on",
          oldPrice: false,
          fontSize: 14,
          fontColor: '#F8B781',
          textTopPer: 0,
          textLeftPer: 0,
          data: { imgUrl: '', data: [] },
          price_show_type: 0,
          isShowSubsidyNoPrice: true,
          subsidyFontSize: 14,
          subsidyFontColor: '#F8B781',
          subsidyTextTopPer: 0,
          subsidyTextLeftPer: 0,
        },
        {
          label: "已评估",
          old_type: "yes",
          backdrop_img: '',
          backdrop_color: '',
          data:[{ type: 'top', imgUrl: '', data: [] }, { type: 'bottom', imgUrl: '', data: [] }]
        }
      ]
    },
  ],
  transformIn: (v) => {    
    const { name, base, config, data } = v;
    return {
      name,
      ...base,
      ...config,
      data: data.map((item) => {
        const { label, old_type, fontColor, fontSize, oldPrice, textLeftPer, textTopPer, data, price_show_type, isShowSubsidyNoPrice, subsidyFontSize, subsidyFontColor, subsidyTextTopPer, subsidyTextLeftPer, backdrop_img, backdrop_color } = item;
        return {
          data: data,
          label, old_type, fontColor, fontSize, oldPrice, textLeftPer, textTopPer, price_show_type, isShowSubsidyNoPrice, subsidyFontSize, subsidyFontColor, subsidyTextTopPer, subsidyTextLeftPer, backdrop_img, backdrop_color
        };
      }),
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          floor_var: "floor_var",
          padded: "padded",
        });
      },
      data: ({ data }) => {        
        return pickBy(data, {
          label: "label",
          old_type: "old_type",
          backdrop_img: 'backdrop_img',
          backdrop_color: 'backdrop_color',
          oldPrice: 'oldPrice',
          fontSize: 'fontSize',
          fontColor: 'fontColor',
          textTopPer: 'textTopPer',
          textLeftPer: 'textLeftPer',
          data: "data",
          price_show_type: 'price_show_type',
          isShowSubsidyNoPrice: 'isShowSubsidyNoPrice',
          subsidyFontSize: 'subsidyFontSize',
          subsidyFontColor: 'subsidyFontColor',
          subsidyTextTopPer: 'subsidyTextTopPer',
          subsidyTextLeftPer: 'subsidyTextLeftPer'
        });
      },
    });
  },
};
