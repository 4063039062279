<template>
  <div :class="{
    'wgt-around-store': true,
    'padded': value.padded
  }">
    <div class="wgt-bd-row" v-if="value.style=='one'">
      <div class="bd-ad">
        <p class="address">国贸商城店<i class="el-icon-arrow-right"/></p>
        <p class="distance">
          离你<span> 2.4 千米</span>，最快<span> 43 分钟 </span>为你送达。
        </p>
      </div>
    </div>

    <div class="wgt-bd" v-if="value.style=='two'">
      <div class="bd-ad">
        <p class="address">国贸商城店<i class="el-icon-arrow-right"/></p>
        <p class="distance">
          离你<span> 2.4 千米</span>，最快<span> 43 分钟 </span>为你送达。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'AroundStore',
  wgtName: '身边门店',
  wgtDesc: '',
  wgtIcon: 'wgt-around-store',
  wgtType: 3,
  wgtTem: 0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config,
  props: {
    value: [Object, Array]
  },
}
</script>

<style lang="scss" scoped>
.wgt-around-store{

  .bd-ad{
      background-color: #fff;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .address{
        font-size: 12px;
        color: #000;
        i{
          font-weight: 600;
        }
      }
      .distance{
        font-size: 10px;
        color: #000;
        span{
          color: $primary_active;
        }
      }
    }
  .wgt-bd-row{
    .bd-ad{
      padding: 20px 30px;
      .address{
      }
    }

  }
  .wgt-bd{
      padding: 10px 20px;
    .bd-ad{
      padding: 15px 15px;
      border-radius: 8px;
      .address{
        font-size: 13px;
      }
    }

  }
}
</style>
