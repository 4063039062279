<style lang="scss" scoped>
.classity-nav-item {
  margin-left: -80px;

  .scope-content {
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="scss">
.classity-nav-item {
  margin-left: -80px;

  .drag-list {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
}
</style>


<template>
  <div class="classity-nav-item">
    <CompDragList v-model="localValue" @onAddItem="handleAddNavItem" btnText="添加导航项">
      <template slot="body" slot-scope="scope">
        <div class="scope-content">
          <el-input v-model="localValue[scope.index].name" placeholder='导航名称' size="mini" style="width: 120px;"
            clearalbe />
          <el-button v-if="!localValue[scope.index]?.cusTom?.pages_template_id" type="text" size="mini"
            @click="handleSetLink(scope.index)">请选择分区模块</el-button>
          <p v-else>
            <el-button type="text" size="mini" style="display: inline;" @click="handleSetLink(scope.index)">【{{
              localValue[scope.index].cusTom.pages_template_id }}】{{ localValue[scope.index].cusTom.template_title
              }}</el-button>
            <el-button type="text" size="mini" style="display: inline;" class="el-icon-delete"
              @click.stop="handledelLink(scope.index)"></el-button>
          </p>
        </div>
      </template>
    </CompDragList>
  </div>
</template>

<script>
import { cloneDeep, throttle } from 'lodash'
import CompDragList from '../../comps/comp-dragList'
import { gWgts } from '../../scene'
import { transformSelectTem, getWgts } from "../../utils"
export default {
  name: "nav-item",
  components: { CompDragList },
  props: {
    value: Array,
    // 组件唯一标识
    uuid: String
  },
  data() {
    return {
      //
      localValue: []
    }
  },
  methods: {
    handleAddNavItem() {
      this.localValue.push({
        name: '',//导航项名称
        cusTom: null
      })
    },
    async handleSetLink(index) {
      const { scene = '1001' } = this.$route.query
      const { pages_template_id: tid = '' } = this.localValue[[index]].cusTom || {}
      let params = {
        multiple: false,
        data: tid ? [tid] : [],
        isContentpart: true
      }
      const { data } = await this.$picker.customWgt(params)
      const title = data[0].template_title
      const id = data[0].pages_template_id
      const widgets = getWgts(scene)
      const value = await transformSelectTem(data, widgets)
      this.$set(this.localValue[index], 'cusTom', ...value)
    },
    async handledelLink(index) {
      this.$set(this.localValue[index], 'cusTom', null)
    },
  },
  watch: {
    uuid: {
      handler(newVal, oldVal) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true,
      deep: true
    },
    localValue: {
      handler(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  }

}
</script>
