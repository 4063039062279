<template>
  <div>
    <el-dialog
      title="审核记录"
      :visible="examineDialogVisible"
      @open="getExamineLogList()"
      @close="$emit('closeDialog')"
      width="80%">
      <el-table
        v-loading="examineTableLoading"
        :data="logData"
        style="width: 100%">
        <el-table-column
          prop="id"
          label="ID"
          width="100">
        </el-table-column>
<!--        <el-table-column-->
<!--          prop="template_title"-->
<!--          label="模版名称">-->
<!--        </el-table-column>-->
        <el-table-column
          prop="examine_result"
          width="120"
          label="审核状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.examine_result == 2">待审核</el-tag>
            <el-tag type="warning" v-if="scope.row.examine_result == 3">审核未通过</el-tag>
            <el-tag type="success" v-if="scope.row.examine_result == 4">审核已通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="examine_remark"
          label="审核备注">
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="提交时间"
          width="170">
        </el-table-column>
        <el-table-column
          prop="examine_time"
          label="审核时间"
          width="170">
        </el-table-column>
        <el-table-column v-if="login_type == 'admin'" label="操作" width="230">
          <template slot-scope="scope">
            <div v-if="scope.row.examine_result == 2">
              <el-button  size="small" type="primary" @click="handlePreview(scope.row)">预览</el-button>
              <el-button  size="small" type="success" @click="handleExamine(scope.row)">审核</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
          background
          layout="total, prev, pager, next"
          @current-change="currentChange"
          :page-size="pageParams.pageSize"
          :total="log_total_count">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('closeDialog')">关 闭</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="审核模版"
      :visible.sync="examineVisible"
      :before-close="handleCloseExamineDialog"
      width="30%">
      <el-form ref="form" :model="examineForm" label-width="100px">
        <el-form-item label="审核结果">
          <div>
            <el-radio v-model="examineForm.examine_result" label="4" value="4" size="mini">审核通过</el-radio>
            <el-radio v-model="examineForm.examine_result" label="3" value="3" size="mini">审核不通过</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            heigh="200"
            type="textarea"
            placeholder="请输入内容"
            v-model="examineForm.remark"
            maxlength="200"
            show-word-limit
            :autosize="{ minRows: 4, maxRows: 10}">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button @click="handleCloseExamineDialog">取 消</el-button>
      <el-button type="primary" @click="examine" :loading="btnLoading">确 定</el-button>
    </span>
    </el-dialog>

    <el-dialog
      title="预览模版"
      :visible.sync="previewVisible"
      :before-close="handleClosePreviewDialog"
      width="30%">
      <div style="text-align: center" v-loading="imgLoading">
        <div>
          请使用手机扫码预览
        </div>
        <div class="block">
          <el-image
            style="width: 200px; height: 200px"
            :src="previewImgSrc">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="previewVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  submitExamineTemplate,
  getExamineList,
  getDistributorExamineList,
  examineTemplate,
  examineDistributorTemplate,
  getTemplatePreviewQrCode,
  getDistributorTemplatePreviewQrCode,
} from "@/api/template";

export default {
  props: [
    'examineDialogVisible',
    'templateType',
    'templateId',
  ],
  components: {
  },
  data() {
    return {
      login_type: 'admin',
      previewVisible: false,
      previewImgSrc: '',
      imgLoading: false,
      examineVisible: false,
      examineForm: {
        examine_result: 2,
        remark: '',
        log_id: 0,
        template_id: 0,
      },
      btnLoading: false,
      examineTableLoading: false,
      logData: [],
      log_total_count: 0,
      pageParams: {
        page: 1,
        pageSize: 20
      },
    };
  },
  mounted() {
    this.getExamineLogList();
  },
  computed: {
    ...mapGetters(["template_name"])
  },
  methods: {
    handleExamine(row) {
      this.examineVisible = true
      this.examineForm.log_id = row.id
      this.examineForm.template_id = row.template_id
    },

    beforeCloseDialog() {
      this.resetExamineForm();
      this.examineVisible = false
    },

    handleCloseExamineDialog() {
      this.examineVisible = false
      this.resetExamineForm()
    },

    resetExamineForm() {
      this.examineForm = {
        examine_result: 2,
        remark: '',
        log_id: 0,
        template_id: 0,
      }
    },

    examine() {
      this.btnLoading = true
      if (this.examineForm.examine_result == 2) {
        this.$message.error('请选择审核结果');
        this.btnLoading = false
        return false;
      }
      examineDistributorTemplate(this.examineForm).then(response => {
        if (response.data.data.success) {
          this.$message.success('审核成功!');
          this.btnLoading = false
        } else {
          this.$message.error('审核失败!');
          this.btnLoading = false
        }
        this.handleCloseExamineDialog()
        this.getExamineLogList();
      }).catch(error => {
        this.$message.error('审核失败');
        this.handleCloseExamineDialog()
        this.getExamineLogList();
        this.btnLoading = false
      })
    },

    getExamineLogList () {
      // this.examineDialogVisible = true
      this.examineTableLoading = true
      let params = {
        page: this.pageParams.page,
        pageSize: this.pageParams.pageSize,
        examineStatus: this.examineStatus,
        template_id: this.templateId,
        template_type: this.templateType
      }
      getDistributorExamineList(params).then(response => {
        this.logData = response.data.data.list
        this.log_total_count = response.data.data.total_count
        this.examineTableLoading = false;
      }).catch(error => {
        this.logData = [];
        this.log_total_count = 0;
        this.examineTableLoading = false;
      })
    },

    currentChange(page) {
      console.log(page)
      this.pageParams.page = page
      this.getExamineLogList()
    },

    selectChange() {
      this.pageParams.page = 1;
    },

    handleClosePreviewDialog() {
      this.previewVisible = false
      this.previewImgSrc = ''
    },

    handlePreview (row) {
      this.previewImgSrc = ''
      this.previewVisible = true
      this.imgLoading = true
      let template_type = 'index'
      if (row.page_type === 'default') {
        template_type = 'index'
      } else if (row.page_type === 'distributor') {
        template_type = 'distributor'
      }
      let params = {type: template_type, id:row.template_id};
      getDistributorTemplatePreviewQrCode(params).then(response => {
        this.previewImgSrc = 'data:image/png;base64,' + response.data.data.qrcode
        this.imgLoading = false
      }).catch(error => {
        this.imgLoading = false
      })
    },
  }
};
</script>

<style scoped lang="scss">
</style>
