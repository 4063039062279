<style lang="scss">
.wgt-film {
  &.padded {
    padding: 10px 0;
  }

  .wgt-hd {
    padding: 10px;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }

    .sub-title {
      color: #666;
      margin-left: 4px;
    }
  }

  .wgt-bd {
    position: relative;
  }

  .muted-btn {
    position: absolute;
    right: 30px;
    bottom: 19px;
    width: 34px;
    height: 34px;
    padding: 10px;

    &-two {
      position: absolute;
      bottom: 28px;
      width: 17px;
      height: 17px;
      right: 70px;
    }
  }

  .video-player {
    overflow: hidden;

    .vjs-big-play-button {
      display: none;
    }

    .vjs-error-display {
      &::before {
        display: none;
      }
    }

    .vjs-modal-dialog-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
<template>
  <div :class="{
    'wgt-film': true,
  }">
    <div v-if="value.title || value.subtitle" class="wgt-hd">
      <span class="title">{{ value.title }}</span>
      <span class="sub-title">{{ value.subtitle }}</span>
    </div>
    <div class="wgt-bd" :style="{
      padding: `${value.padd?.paddedt || 0}px ${value.padd?.paddedr || 0}px ${value.padd?.paddedb || 0}px ${value.padd?.paddedl || 0}px`,
    }">
      <!-- 挂件自定义部分 -->
      <video-player :style="{ borderRadius: `${value.radius}px` }" :options="{
        aspectRatio: aspectRatio,
        sources: [
          {
            type: 'video/mp4',
            src: sourceUrl
          }
        ],
        notSupportedMessage: '添加视频源',
        suppressNotSupportedError: true,
        controlBar: false
      }" />
      <el-image class="muted-btn" v-if="value?.showVolume == 1" :src="value?.openVolume == 0 ? 'https://cdn-image.tjtjshengtu.com/xcximage/muted.png'
        : 'https://cdn-image.tjtjshengtu.com/xcximage/sound.png'" />
      <el-image class="muted-btn-two" v-if="value?.showFullscreen == 1"
        :src="'https://cdn-image.tjtjshengtu.com/xcximage/screen.png'" />
    </div>
  </div>
</template>

<script>
import config from './config'
import { proportions } from '@/consts'

export default {
  name: 'Film',
  wgtName: '视频',
  wgtDesc: '',
  wgtIcon: 'wgt-film',
  wgtType: 1,
  wgtTem: 0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      currentIndex: 1
    }
  },
  computed: {
    aspectRatio() {
      const { proportion = 0 } = this.value
      return proportions.find((item) => item.label == proportion).name
    },
    sourceUrl() {
      const { data } = this.value
      return data.url || ''
    }
  },
  created() { },
  methods: {}
}
</script>
