import { pickBy } from '@/utils'
import CompBackground from "./comp-background.vue"
import AttrItem from "./attr-item.vue"
import CompsStyle from "../../comps/comps-style.vue";

export default {
  name: "imageText",
  setting: [
    {
      label: "组件背景",
      key: "background",
      component: function (h, { key }) {
        return <CompBackground v-model={this.value[key]} uuid={this.value.uuid} />
      },
      value: {
        color: '',
        imageUrl: ''
      }
    },
    // {
    //   label: "组件高度",
    //   key: "height",
    //   component: "number",
    //   position: "right",
    //   size: "mini",
    //   min: 0,
    //   value: '',
    //   append: "px",
    // },
    {
      label: "内边距",
      key: "padded",
      component: function (h, { key }) {
        return (
          <CompsStyle
            showBgsetting={false}
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showLabel={false}
          />
        );
      },
      value: {
        paddedt: 10,
        paddedb: 10,
        paddedl: 10,
        paddedr: 10,
      },
    },
    {
      label: "添加内容",
      key: "data",
      component: function (h, { key }) {
        return <AttrItem v-model={this.value[key]} uuid={this.value.uuid} />
      },
      value: []
    },

  ],
  transformIn: (v) => {
    const {name,base,config,data} = v
    return {
      name,
      ...base,
      ...config,
      data,
    }

  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {});
      },
      config: (v) => {
        return pickBy(v, {
          background: "background",
          // height: 'height',
          padded: "padded"
        });
      },
      data: "data",
    });
  },

}