import { pickBy, isArray, isObject } from '@/utils'
import AttrHotSetting from './attr-hotsetting'
import CompsStyle from "../../comps/comps-style.vue";

export default {
  name: 'imgHorizontal',
  setting: [
    { label: '标题1', key: 'title', component: 'input', value: '长热区图' },
    { label: '副标题', key: 'subtitle', component: 'input', value: '图上随意画块块' },
    { label: '组件间距', key: 'padded', component: 'switch', value: true },
    { label: "楼层", key: "floor_var", component: "input", value: "" },
    { label: "图片高度", key: "height", component: "number", value: "" ,min:0},
    { label: "展示进度条", key: "showProgress", component: "switch", value: true },
    {
      label: "样式设置",
      key: "style",
      component: function (h, { key }) {
        return <CompsStyle v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: {
        margined: 0,
        paddedt: 0,
        paddedb: 0,
        paddedl: 0,
        paddedr: 0,
        bgColor: "",
        bgPic: "",
        bgType: "color",
      },
    },
    {
      label: '长热区设置',
      key: 'data',
      component: function (h, { key }) {
        return <AttrHotSetting v-model={this.value[key]} uuid={this.value.uuid}/>
      },
      value: { imgUrl: '', data: [] }
    }
  ],
  transformIn: (v) => {
    const {
      name,
      base,
      data
    } = v
    let _longImgUrl = ''
    let _longData = []
    if (isArray(data)) {
      const [{ longImgUrl, longData }] = data
      _longImgUrl = longImgUrl
      _longData = longData
    } else if (isObject(data)) {
      _longImgUrl = data.longImgUrl
      _longData = data.longData
    }
    return {
      name,
      ...base,
      data: {
        imgUrl: _longImgUrl,
        data: _longData
      }
    }
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: 'name',
      base: (v) => {
        return pickBy(v, {
          title: 'title',
          subtitle: 'subtitle',
          padded: 'padded',
          style: "style",
          floor_var:'floor_var',
          height: 'height',
          showProgress:'showProgress'
        })
      },
      data: ({ data }) => {
        const _data= pickBy(data, {
          longImgUrl: 'imgUrl',
          longData: 'data'
        })
        return [_data]
      },
    })
  }
}
