import { pickBy } from "@/utils";
import CompsStyle from "../../comps/comps-style.vue";
export default {
  name: "officialAccount",
  setting: [
    { label: "楼层", key: "floor_var", component: "input", required: true },
    {
      label: "内边距",
      key: "padded",
      component: function (h, { key }) {
        return (
          <CompsStyle
            showBgsetting={false}
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showLabel={false}
          />
        );
      },
      value: {
        paddedt: 15,
        paddedb: 15,
        paddedl: 32,
        paddedr: 32,
      },
    }, {
      label: "背景图",
      key: "bgImg",
      component: function (h, { key }) {
        return <SpImagePicker v-model={this.value[key]} size="small" />;
      },
      value: "",
    },
  ],
  transformIn: (v) => {
    const { name, base, config } = v;
    return {
      name,
      ...base,
      ...config
    };
  },
  transformOut: (v) => {
    if (!v.floor_var) {
      throw '[挂件]关注公众号 请输入楼层';
    }
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          floor_var: "floor_var",
        });
      },
      config: (v) => {
        return pickBy(v, {
          bgImg: "bgImg",
          padded: "padded",
        });
      }
    });
  },
};
