<style lang="scss"></style>
<template>
  <div><img :src="require(`./${value.base.content}`)" /></div>
</template>

<script>
import config from './config'
export default {
  name: 'FunctionBlock',
  wgtName: '功能区',
  wgtDesc: '',
  wgtIcon: 'wgt-functionBlock',
  wgtType: 0,
  wgtCopy: false,
  wgtDelete: false,
  config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {}
  }
}
</script>
