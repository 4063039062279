var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"experiment"},[_c('el-select',{staticClass:"experiment",attrs:{"placeholder":"选择实验"},on:{"change":_vm.changeExperiment},model:{value:(_vm.localValue.value),callback:function ($$v) {_vm.$set(_vm.localValue, "value", $$v)},expression:"localValue.value"}},_vm._l((_vm.abList),function(item,index){return _c('el-option',{key:`experiment-item__${index}`,attrs:{"label":item.title,"value":item.value}})}),1),_c('el-button-group',{staticStyle:{"margin-top":"10px"}},_vm._l((_vm.groupList),function(btn,index){return _c('el-button',{key:`group-item__${index}`,attrs:{"type":_vm.localValue.groupIndex == index ? 'primary' : 'default'},on:{"click":function($event){return _vm.toggleGroupIndex(index)}}},[_vm._v(" "+_vm._s(btn.title)+" ")])}),1),(_vm.localValue.value && _vm.localValue.list.length > 0)?_c('div',{staticClass:"wgt-item"},[_c('CompTodoList',{attrs:{"is-edit":"","btn-text":"添加挂件","max":20},on:{"edit":_vm.onChangeCouponItem,"onAddItem":_vm.handleAddTabs},scopedSlots:_vm._u([{key:"body",fn:function(scope){return [_c('div',{staticClass:"widget-item"},[_c('span',[_vm._v(_vm._s(scope.data.wgtName))]),(scope.data.name == 'customWgt'&&scope.data.pages_template_id)?_c('span',[_vm._v(":【"+_vm._s(scope.data.pages_template_id)+"】"+_vm._s(scope.data.template_title))]):_vm._e()])]}}],null,false,3810309429),model:{value:(_vm.localValue.list[_vm.localValue.groupIndex].children),callback:function ($$v) {_vm.$set(_vm.localValue.list[_vm.localValue.groupIndex], "children", $$v)},expression:"localValue.list[localValue.groupIndex].children"}}),_c('el-dialog',{staticClass:"dialog-wgt-list",attrs:{"title":"选择组件","visible":_vm.dialogVisable,"append-to-body":"","destroy-on-close":""},on:{"close":_vm.cancelAction}},[_c('div',{staticClass:"dialog-bd"},_vm._l((_vm.wgtList),function(wgt,index){return _c('div',{key:`wgt-item__${index}`,staticClass:"item-wrap"},[_c('div',{class:[
            'wgt-icon',
            wgt.wgtIcon,
            {
              'active': _vm.selected.indexOf(wgt.name) > -1
            }
          ],style:(_vm.getWgtStyle(wgt)),on:{"click":function($event){return _vm.onSelectWgt(wgt)}}}),_c('div',{staticClass:"wgt-name"},[_vm._v(" "+_vm._s(wgt.wgtName)+" ")])])}),0),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancelAction}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirmAction}},[_vm._v("确 定")])],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }