<style lang="scss" scoped></style>


<template>
  <div class="use-container-nav-item">
    <el-button v-if="!localValue[type]?.pages_template_id" type="text" size="mini"
      @click="handleSetLink(type)">请选择分区模块</el-button>
    <div v-else>
      <el-button type="text" size="mini" style="display: inline;" @click="handleSetLink(type)">【{{
        localValue[type]?.pages_template_id }}】{{ localValue[type]?.template_title
        }}</el-button>
      <el-button type="text" size="mini" style="display: inline;" class="el-icon-delete"
        @click.stop="handledelLink(type)"></el-button>
    </div>

  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { transformSelectTem, getWgts } from "../../utils"
export default {
  name: "nav-item",
  props: {
    value: Object,
    // 组件唯一标识
    uuid: String,
    type: String
  },
  data() {
    return {
      //
      localValue: null
    }
  },
  methods: {
    handleAddNavItem() {
      this.localValue.push({
        name: '',//导航项名称
        cusTom: null
      })
    },
    async handleSetLink(type) {
      const { scene = '1001' } = this.$route.query
      const { pages_template_id: tid = '' } = this.localValue[type] || {}
      let params = {
        multiple: false,
        data: tid ? [tid] : [],
        isContentpart: true
      }
      const { data } = await this.$picker.customWgt(params)
      const widgets = await getWgts(scene)
      const value = await transformSelectTem(data, widgets)
      this.$set(this.localValue, type, ...value)
    },
    async handledelLink(type) {
      this.$set(this.localValue, type, null)
    },
  },
  watch: {
    uuid: {
      handler(newVal, oldVal) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true,
      deep: true
    },
    localValue: {
      handler(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  }

}
</script>
