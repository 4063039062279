import { pickBy } from "@/utils";
import CompsStyle from "../../comps/comps-style.vue";
import navItem from "./nav-item.vue";
import NavSetting from "./nav-setting.vue";
import { cloneDeep } from 'lodash'
import { transformInTem } from "../../utils"


export default {
  name: "contentPart",
  setting: [
    {
      label: "分区交互",
      key: "animate",
      component: "radiobutton",
      options: [
        { name: "分区纵向", label: "0" },
        { name: "分区横向", label: "1" },
        { name: "分区切屏", label: "2" },
      ],
      value: "0",
      module: "",
    },
    {
      label: "导航展示",
      key: "showNav",
      component: "radiobutton",
      options: [
        { name: "显示", label: "1" },
        { name: "隐藏", label: "0" },
      ],
      value: "1",
      module: "",
    },
    {
      label: "吸顶",
      key: "fixedTop",
      component: "radiobutton",
      options: [
        { name: "是", label: 1 },
        { name: "否", label: 0 },
      ],
      value: 1,
      isShow: function () {
        return this.value.showNav == 1;
      },
      module: "",
    },
    {
      label: "吸顶样式",
      key: "isSameStyle",
      component: "radiobutton",
      options: [
        { name: "一致", label: 1 },
        { name: "不一致", label: 0 },
      ],
      value: 1,
      isShow: function () {
        return this.value.showNav == 1 && this.value.fixedTop == 1;
      },
      onchange: (val, that) => {
        if (val == 1) {
          that.value.navsetting = 0;
        }
      },
      module: "",
    },
    {
      label: "",
      key: "navsetting",
      component: function (h, { key }) {
        return (
          <el-button-group style="margin-left: -80px;margin-top: 10px;display: flex;width: calc(100% + 80px);">
            <el-button
              style="flex:1"
              size="mini"
              type="primary"
              plain={this.value.navsetting == 1}
              onclick={(el) => {
                this.value[key] = this.value[key] == 1 ? 0 : 1;
              }}
            >
              未吸顶样式
            </el-button>
            <el-button
              style="flex:1"
              size="mini"
              type="primary"
              plain={this.value.navsetting == 0}
              onclick={() => {
                this.value[key] = this.value[key] == 1 ? 0 : 1;
              }}
            >
              吸顶样式
            </el-button>
          </el-button-group>
        );
      },
      isShow: function () {
        return this.value.fixedTop == 1 && this.value.isSameStyle == 0;
      },
      value: 0,
      module: "nav",
    },
    {
      label: "",
      key: "fixsetting",
      component: function (h, { key }) {
        return <NavSetting v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      isShow: function () {
        return this.value.fixedTop == 1 && this.value.navsetting == 1;
      },
      value: {
        navbgtype: 0,
        navtype:'0',
        navbgcolor: "",
        imgUrl: "",
        navpadded: {
          paddedt: 0,
          paddedb: 0,
          paddedl: 0,
          paddedr: 0,
        },
        navitemcolor: "",
        // navitempadded: {
        //   paddedt: 20,
        //   paddedb: 20,
        //   paddedl: 20,
        //   paddedr: 20,
        // },
        navitemradius: 10,
        navitemHeight: 45,
        slideritempadded: { paddedtb: 0, paddedlr: 0 },
        leftimgUrl: "",
        rightimgUrl: "",
      },
      module: "nav",
    },
    {
      label: "",
      key: "defaultsetting",
      component: function (h, { key }) {
        return <NavSetting v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      isShow: function () {
        return (
          this.value.fixedTop == 0 ||
          (this.value.fixedTop == 1 && this.value.navsetting == 0)
        );
      },
      value: {
        navbgtype: 0,
        navtype:'0',
        navbgcolor: "",
        imgUrl: "",
        navpadded: {
          paddedt: 0,
          paddedb: 0,
          paddedl: 0,
          paddedr: 0,
        },
        navitemcolor: "",
        navitemradius: 10,
        navitemHeight: 45,
        slideritempadded: { paddedtb: 0, paddedlr: 0 },
        leftimgUrl: "",
        rightimgUrl: "",
      },
      module: "nav",
    },
    {
      label: "",
      key: "data",
      component: function (h, { key }) {
        return <navItem v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: [],
      module: "navcontent",
    },
  ],
  transformIn: async (v, widgets) => {
    const { name, base, config, data, setting: { defaultsetting, fixsetting } } = v;
    const processSettings = (settings) => ({
      navbgtype: settings.navbgtype || '',
      navtype: settings.navtype || '0',
      navbgcolor: settings.navbgcolor || '',
      imgUrl: settings.imgUrl || '',
      navpadded: {
        paddedt: settings.navpadded?.paddedt || 0,
        paddedb: settings.navpadded?.paddedb || 0,
        paddedl: settings.navpadded?.paddedl || 0,
        paddedr: settings.navpadded?.paddedr || 0,
      },
      navitemcolor: settings.navitemcolor || '',
      navitemradius: settings.navitemradius || '',
      navitemHeight: settings.navitemHeight || '',
      slideritempadded: {
        paddedtb: settings.slideritempadded?.paddedtb || 0,
        paddedlr: settings.slideritempadded?.paddedlr || 0,
      },
      leftimgUrl: settings.leftimgUrl || '',
      rightimgUrl: settings.rightimgUrl || '',
    });
    const _data = await transformInTem(data, widgets)
    return {
      name,
      ...base,
      ...config,
      defaultsetting: processSettings(defaultsetting),
      fixsetting: processSettings(fixsetting),
      data: _data,
      navsetting: 0,
    };
  },
  transformOut: (v, widgets) => {
    const value = cloneDeep(v)
    const data = value.data.map(item => {
      const { cusTom, ...rest } = item
      if (cusTom) {
        let _cusTom = null
        const { template = [], pages_template_id, template_title } = cusTom
        _cusTom = {
          pages_template_id,
          template_title,
        }
        return {
          data: _cusTom,
          ...rest
        }
      }
      return item
    })
    value.data = data
    return pickBy(value, {
      name: "name",
      base: (value) => {
        return pickBy(value, {
          animate: "animate", //分区交互
          showNav: "showNav", //导航展示
          fixedTop: "fixedTop", //吸顶
          isSameStyle: 'isSameStyle'
        });
      },
      setting: (value) => {
        return pickBy(value, {
          fixsetting: "fixsetting",
          defaultsetting: "defaultsetting",
        });
      },
      config: {},
      data: "data",
    });
  },
};
