<template>
  <div :class="{
    'wgt-recent-orders': true,
    'padded': value.padded
  }">
    <div class='order-info'>
      <div class="goods-img" :style="`width:${value.imgSize}px;height:auto;`">
        <SpImage />
      </div>
      <div>
        <div class='order-info__warehouse' :style="`font-size:${value.warehouseSize}px;color:${value.warehouseColor}`">
          门店现货</div>
        <div class='order-info__name' :style="`font-size:${value.nameSize}px;color:${value.nameColor}`">iPhone 16</div>
        <div class='order-info__total' :style="`font-size:${value.deliverySize}px;color:${value.deliveryColor}`">共3件商品
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import config from './config'
export default {
  name: 'RecentOrders',
  wgtName: '最近订单',
  wgtDesc: '',
  wgtIcon: 'wgt-around-store',
  wgtType: 1,
  wgtTem: 0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config,
  props: {
    value: [Object, Array]
  },
}
</script>

<style lang="scss" scoped>
.wgt-recent-orders {
  .order-info {
    display: flex;
    padding: 10px 15px;
    background: #fff;
    margin: 10px 20px;
    border-radius: 20px;
  }

  .goods-img {
    margin-right: 10px;
  }

  .order-info__total {
    margin-top: 6px;
  }

}
</style>
