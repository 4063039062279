<style lang="scss" src="./index.scss"></style>
<template>
  <div
    :class="{
      'wgt-slider': true,
      'padded': value.padded
    }"
  >
    <div v-if="value.title || value.subtitle" class="wgt-hd">
      <span class="title">{{ value.title }}</span>
      <span class="sub-title">{{ value.subtitle }}</span>
    </div>
    <div
      class="wgt-bd"
      :class="{
        'spaced': value.spaced,
        'padded': value.itemPadded
      }"
    >
      <!-- 挂件自定义部分 -->
      <SpImage
        class="placeholder-item"
        :src="value.data.length > 0 ? value.data[0].imgUrl : null"
        fit="contain"
      />
      <!-- <img class="placeholder-item" style="display: block;" :src="value.data.length > 0 ? value.data[0].imgUrl : null" width="100%" /> -->
      <el-carousel
        class="slider-container"
        arrow="never"
        indicator-position="none"
        :interval="value.interval"
        :style="'background:' + value.back_color"
        @change="
          (e) => {
            this.currentIndex = e
          }
        "
      >
        <el-carousel-item
          v-for="(item, index) in value.data"
          :key="index"
          class="slider-item"
          :class="{
            'rounded': value.rounded,
            'padded': value.itemPadded
          }"
        >
          <SpImage :src="item.imgUrl" fit="contain" />
        </el-carousel-item>
      </el-carousel>
      <div
        :class="[
          'slider-pagination',
          value.dotLocation,
          value.shape,
          value.dotColor,
          {
            'cover': value.dotCover,
            'cover-padded': value.itemPadded
          }
        ]"
        v-if="value.dot_type != 'line'"
      >
        <template v-if="value.dot">
          <div
            v-for="(item, index) in value.data.length > 0 ? value.data : [1]"
            :key="`dot-item__${index}`"
            :class="['dot-item', { 'active': currentIndex == index }]"
            :style="`background:${setcolor(index)}`"
          />
        </template>
        <template v-else>
          <div class="pagination-count">
            {{ `${currentIndex + 1}/${value.data.length > 0 ? value.data.length : 1}` }}
          </div>
        </template>
      </div>
      <div
        :class="[
          'slider-pagination-line',
          {
            'cover': value.dotCover
          }
        ]"
        v-if="value.dot_type == 'line' && value.data.length > 0"
      >
        <el-progress
          :percentage="(Number(currentIndex + 1) / Number(value.data.length)).toFixed(2) * 100"
          :stroke-width="2"
          :show-text="false"
          :color="value.pages_type == 'custom' ? value.pilot.dot_cover || '#000' : '#000'"
          :define-back-color="
            value.pages_type == 'custom'
              ? value.pilot.dot_noCover || '#ffffffb3'
              : value.dotColor == 'dark'
              ? '#dcdcdd'
              : '#ffffffb3'
          "
        />
      </div>
      <!-- 挂件自定义部分 -->
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'Slider',
  wgtName: '轮播',
  wgtDesc: '',
  wgtIcon: 'wgt-slider',
  wgtType: 1,
  wgtTem: 0, //0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  computed: {},
  created() {},
  methods: {
    setcolor(index) {
      if (this.currentIndex == index) {
        if (this.value.pages_type == 'custom') {
          return this.value.pilot.dot_cover || '#000'
        } else {
          return '#000'
        }
      } else {
        if (this.value.pages_type == 'custom') {
          return this.value.pilot.dot_noCover || 'rgba(255,255,255,0.4)'
        } else {
          return this.value.dotColor == 'dark' ? '#dcdcdd' : '#ffffffb3'
        }
      }
    }
  }
}
</script>
