<template>
  <div :class="{
    'wgt-member-info': true,
  }">
    <el-image :src="value.backgroundImg" mode="aspectFill" class="cover-bg" />
    <div class="wgt-bd" :style="contentStyle">
      <el-image class="avatar"
        src="http://prd-aarminp-image.oss-cn-beijing.aliyuncs.com/xcximage/member/avatar_default.png" />
      <div class="name">HOYY</div>
      <div class="no">NO.88888888</div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'MemberInfo',
  wgtName: '会员信息',
  wgtDesc: '',
  wgtIcon: 'wgt-member-info',
  wgtType: 3,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {}
  },
  created() { },
  methods: {},
  computed: {
    // memberInfoStyle() {
    //   const { backgroundImg } = this.value
    //   return {
    //     backgroundImage: `url(${backgroundImg})`,
    //     backgroundSize: '100% auto',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center',
    //   }
    // },
    contentStyle() {
      const { padded: { paddedt, paddedb, paddedl, paddedr } } = this.value
      return {
        paddingTop: `${paddedt}px`,
        paddingBottom: `${paddedb}px`,
        paddingLeft: `${paddedl}px`,
        paddingRight: `${paddedr}px`,
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wgt-member-info {
  width: 100%;
  position: relative;
  height: fit-content;
  min-height: 62px;

  .cover-bg {
    width: 100%;
    display: block;
  }

  .wgt-bd {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-bottom: 2px;
    }


    .name {
      font-size: 7px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 10px;
      color: rgba(0, 0, 0, 1);
    }

    .no {
      font-size: 7px;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 8px;
      color: rgba(162, 158, 158, 1);
    }
  }
}
</style>
