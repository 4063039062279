<style lang="scss" src="./index.scss">
</style>
<template>
  <div :class="{
    'wgt-around-service': true,
    'padded': value.padded
  }">
    <div v-if="value.title || value.subtitle" class="wgt-hd">
      <span class="title">{{ value.title }}</span>
      <span class="sub-title">{{ value.subtitle }}</span>
    </div>
    <div class="wgt-bd">
      <div v-for="(item,index) in value.data" :key="`wgt-service-item-${index}`" class="wgt-service-item">
        <SpImage :src="item.imgUrl" width="70" height="70" />
        <div class="service-info">
          <p class="title">{{ item.maintitle }}</p>
          <p class="subtitle">{{ item.subtitle }}</p>
          <p class="link">{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'AroundService',
  wgtName: '身边服务',
  wgtDesc: '',
  wgtIcon: 'wgt-around-service',
  wgtType: 1,
  wgtTem:0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {}
  },
  created() { },
  methods: {}
}
</script>
