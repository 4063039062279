<template>
  <div class='color-cell'>
    <el-color-picker v-model='localValue.dot_noCover' size='small' />
    <el-color-picker v-model='localValue.dot_cover' size='small' />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          dot_noCover: '',
          dot_cover: ''
        }
      }
    },
    uuid:{
      type:String,
      default:''
    },
    //
    show:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      // 默认值
      localValue: {
        dot_noCover: '',
        dot_cover: ''
      }
    }
  },
  watch: {
    localValue: {
      handler(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    uuid:{
      handler() {
        this.localValue = cloneDeep(this.value)
      },
      deep: true,
      immediate:true
    },
    show:{
      handler(val) {
        this.localValue = cloneDeep(this.value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.color-cell {
  display: flex;
}
</style>