import { pickBy } from '@/utils'
import CompHotSetting from "../../comps/comp_hotSetting";
import CompsStyle from "../../comps/comps-style.vue";
import AttrItem from './attr-item.vue';

export default {
  name: 'suspension',
  setting: [
    { label: "楼层", key: "floor_var", component: "input",required:true },
    {
      label: "背景色",
      key: "backgroundColor",
      component: "color",
      value: "#fff",
    },
    {
      label: "背景图",
      key: "backgroundImage",
      component: function (h, { key }) {
        return <SpImagePicker v-model={this.value[key]} size="small" />;
      },
      value: "",
    },
    {
      label: "位置",
      key: "position",
      component: "radiobutton",
      options: [
        { name: "页面顶部", label: "top" },
        { name: "页面底部 ", label: "bottom" },
      ],
      value: "top",
    },
    {
      label: "距离屏幕顶部",
      key: "distance",
      component: "number",
      position: "right",
      size: "mini",
      append: "px",
      min: 0,
      value: 0,
      isShow: function () {
        return this.value.position == 'top'
      }
    },
    {
      label: "距离屏幕底部",
      key: "distance",
      component: "number",
      position: "right",
      size: "mini",
      append: "px",
      min: 0,
      value: 0,
      isShow: function () {
        return this.value.position == 'bottom'
      }
    },
    {
      label: "外边距",
      key: "margin",
      component: function (h, { key }) {
        return (
          <CompsStyle
            showTop={false}
            showBottom={false}
            showBgsetting={false}
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showLabel={false}
          />
        );
      },
      value: {
        paddedr: 0,
        paddedl: 0,
      },
    },
    {
      label: "内边距",
      key: "padding",
      component: function (h, { key }) {
        return (
          <CompsStyle
            showBgsetting={false}
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showLabel={false}
          />
        );
      },
      value: {
        paddedt: 0,
        paddedb: 0,
        paddedl: 0,
        paddedr: 0,
      },
    }, {
      label: "内容设置",
      key: "data",
      component: function (h, { key }) {
        return (
          <AttrItem v-model={this.value[key]} uuid={this.value.uuid} />
        );
      },
      value: {
        type: 'vip',
        vip: {
          imgUrl: "", data: [], imgName: ''
        },
        noVip: {
          imgUrl: "", data: [], imgName: ''
        }
      },
    },
  ],
  transformIn: async (v) => {
    const { name, base, config, data: { type, ...data } } = v;
    return {
      name,
      ...base,
      ...config,
      data: {
        type: 'vip',
        ...data
      },
    }
  },
  transformOut: (v) => {
    const {data:{noVip, vip},floor_var} = v
    if (!floor_var) {
      throw '[挂件]悬浮层 请输入楼层';
    }
    if(vip.imgUrl&&!vip.flowName_var){
      throw '[挂件]悬浮层 请输入内容设置-会员-资源位名称';
    }
    if(noVip.imgUrl&&!noVip.flowName_var){
      throw '[挂件]悬浮层 请输入内容设置-非会员-资源位名称';
    }
    for(let i in vip.data){
      if(!vip.data[i].imgName){
        throw '[挂件]悬浮层 请输入内容设置-热区名称';
      }
    }
    for(let i in noVip.data){
      if(!noVip.data[i].imgName){
        throw '[挂件]悬浮层 请输入内容设置-热区名称';
      }
    }
    

    return pickBy(v, {
      name: 'name',
      base: (v) => {
        return pickBy(v, {
          floor_var: "floor_var",
        });
      },
      config: (v) => {
        return pickBy(v, {
          backgroundColor: 'backgroundColor',
          backgroundImage: 'backgroundImage',
          position: 'position',
          distance: 'distance',
          margin: 'margin',
          padding: 'padding',
        });
      },
      data: "data",
    })
  }
}
