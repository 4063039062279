<template>
  <div :class="{
    'wgt-stickyLift': true,
    'padded': value.padded
  }" draggable="false">
    <div v-if="value.title || value.subtitle" class="wgt-hd">
      <span class="title">{{ value.title }}</span>
      <span class="sub-title">{{ value.subtitle }}</span>
    </div>
    <div class="wgt-bd">
      <div class="wgt-bd-item">
        <div class="wgt-bd-title">
          <p class="title" v-for="(item, indx) in value.data" :key="`sticky_lift_title_${indx}`">{{ item.activity_title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: "GoodsLift",
  wgtName: '商品电梯',
  wgtDesc: '',
  wgtIcon: 'wgt-goods-list',
  wgtType: 1,
  wgtTem:1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
.wgt-stickyLift {
  &.padded {
    padding: 10px 0;
  }

  .wgt-hd {
    padding: 10px;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }

    .sub-title {
      color: #666;
      margin-left: 4px;
    }
  }

  .wgt-bd {
    .wgt-bd-title {
      position: sticky;
      top: 64px;
      left: 0;
      right: 0px;
      z-index: 99;
      width: 100%;
      height: 45px;
      max-height: 45px;
      background: #141415;
      overflow-x: auto;
      padding: 0 18px;
      box-sizing: border-box;
      color: #c9c9cb;
      height: 45px;
      line-height: 45px;
      font-size: 13px;
      display: flex;
      overflow: hidden;

      .title {
        &:not(:last-child) {
          padding-right: 10px;
        }
      }
    }
  }
}
</style>