<template>
  <!-- 'suspension-top': suspensionStyle(wgt), 'suspension-bottom': suspensionStyle(wgt) } -->
  <div
    :class="{ 'wgt-suspension': true, 'suspension-top': value.position === 'top', 'suspension-bottom': value.position === 'bottom' }"
    :style="suspensionStyle">
    <div class="wgt-suspension-content">
      <CompHotimg :value="{data:data}" :visible="visible"/>
    </div>
  </div>
</template>

<script>
import config from './config'
import CompHotimg from '../../comps/comp_hotImg';
export default {
  name: 'Suspension',
  wgtName: '悬浮层',
  wgtDesc: '',
  wgtIcon: 'wgt-suspension',
  wgtType: 1,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      imgOffset: {
        width: 0,
        height: 0
      },
      refresh:false
    }
  },
  components: {
    CompHotimg
  },
  methods: {
    handleLode(e) {
      const { naturalWidth, naturalHeight } = e.target
      this.imgOffset = {
        width: naturalWidth,
        height: naturalHeight
      }
    }
  },
  computed: {
    suspensionStyle() {
      const { backgroundColor, backgroundImage, position, distance, margin, padding } = this.value
      let style = {
        backgroundColor,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        margin: `0px ${margin.paddedr}px 0px ${margin.paddedl}px`,
        width: `calc(100% - ${margin.paddedl + margin.paddedr}px)`,
        padding: `${padding.paddedt}px ${padding.paddedr}px ${padding.paddedb}px ${padding.paddedl}px`,
      }
      if (position === 'top') {
        return {
          ...style,
          top: `${distance}px`,
        }
      } else {
        return {
          ...style,
          bottom: `${distance}px`,
        }
      }
    },
    data(){
      return this.value.data[`${this.value.data.type}`]
    },
    visible() {
      return this.value.data.type=='noVip'
    }
  },

}
</script>

<style lang="scss" scoped>
.wgt-suspension {
  box-sizing: border-box;
  min-height: 20px;

  .wgt-suspension-content {
    position: relative;
  }
}
</style>