<template>
  <div :class="{
    'wgt-user-container': true,
  }">
    <component v-for="(wgt, index) in value.data[value.userType]?.template" :is="wgt.name" :value="wgt"
      :key="`content-part-component__${index}`" />
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'UserContainer',
  wgtName: '用户容器',
  wgtDesc: '',
  wgtIcon: 'wgt-user-container',
  wgtType: 2,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {}
  },
  created() { },
  methods: {},
  computed: {
    memberInfoStyle() {
      const { backgroundImg } = this.value
      return {
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
      }
    },
    contentStyle() {
      const { padded: { paddedt, paddedb, paddedl, paddedr } } = this.value
      return {
        paddingTop: `${paddedt}px`,
        paddingBottom: `${paddedb}px`,
        paddingLeft: `${paddedl}px`,
        paddingRight: `${paddedr}px`,
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wgt-user-container {
  min-height: 40px;
}
</style>
