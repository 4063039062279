<template>
  <div class="goods_lift-attr-item">
    <CompButton placeholder="设置企业员工购商品" format="{0}件商品" :value="localValue.length" @click="handleClickAdd"
      @remove="onRemove" :view-btn="false" />
    <div style="width: 100%; margin-top: 10px">
      <CompTodoList v-model="localValue" :is-show="false">
        <template slot="body" slot-scope="scope">
          <div class="block-item">
            <!-- <SpImage :src="scope.data.activity_cover" size="small" width="30" height="30" /> -->
            <div class="block">
              <span>{{ scope.data.id }}</span>
              <span>标题:{{ scope.data.activity_title }}</span>
            </div>
          </div>
        </template>
      </CompTodoList>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import CompTodoList from '../../comps/comp-todoList'
import CompButton from '../../comps/comp-button'
export default {
  components: { CompButton, CompTodoList },
  data() {
    return {
      placeholder: '',
      localValue: []
    }
  },
  props: {
    uuid: {
      type: String
    },

    value: {
      type: Array
    }
  },
  watch: {
    uuid: {
      handler(val, oldVal) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true,
      deep: true
    },
    localValue: {
      handler(val, oldVal) {
        this.$emit('input', val)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async handleClickAdd() {
      const params = {
        data: this.localValue,
        multiple: true,
        params: {
          type: 'ecPurchase'
        }
      }
      const { data } = await this.$picker.welfare(params)
      if (data) {
        this.localValue = cloneDeep(data)
      }
    },
    onRemove() {
      this.localValue = []
    }
  }
}
</script>

<style lang="scss" >
.goods_lift-attr-item {
  .block {
    span {
      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }
}
</style>