import { pickBy } from "@/utils";
export default {
  name: "aroundStore",
  setting: [
    {
      label: "样式选择",
      key: "style",
      component: "radio",
      options: [
        { name: "样式一", label: "one" },
        { name: "样式二", label: "two" },
      ],
      value: "one",
    },
  ],
  transformIn: (v) => {
    const { name, base, config } = v;
    return {
      name,
      ...base,
      ...config
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return {}
      },
      config: (v) => {
        return pickBy(v, {
          style: "style"
        });
      }
    });
  },
};
