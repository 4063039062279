import { pickBy, isObject } from "@/utils";
import AttrItem from "./attr-item.vue";
import Vue from "vue";
import ColorCell from "./color-cell";

export default {
  name: "slider",
  setting: [
    { label: "标题", key: "title", component: "input", value: "分享拼单" },
    {
      label: "副标题",
      key: "subtitle",
      component: "input",
      value: "分享有好礼，拼单更便宜",
    },
    { label: "楼层", key: "floor_var", component: "input" },
    {
      label: "字体颜色",
      key: "WordColor",
      component: "color",
      value: "#333333",
    },
    {
      label: "自动切换",
      key: "autoplay",
      component: "switch",
      value: true,
    },
    {
      label: "轮播时间",
      key: "interval",
      component: "number",
      isShow: function () {
        return this.value.autoplay;
      },
      value: 3000,
      min: 1000,
      max: 50000,
      step: 1000,
    },
    {
      label: "指示点类型",
      key: "dot_type",
      component: "radio",
      options: [
        { name: "点位样式", label: "" },
        { name: "线位样式 ", label: "line" },
      ],
      value: "",
    },
    {
      label: "指示点控制",
      key: "pages_type",
      component: function(h,{key,options}){
       const handleChange=()=>{
        Vue.set(this.value, "pilot", {
          dot_noCover: "",
          dot_cover: "",
        });
          Vue.set(this.value, "back_color", "");
        }
        return (
          <el-radio-group v-model={this.value[key]} on-change={()=>handleChange()}>
            {options.map((op) => (
              <el-radio label={op.label}>{op.name}</el-radio>
            ))}
          </el-radio-group>
        );
      },
      options: [
        { name: "不可控", label: "" },
        { name: "可控", label: "custom" },
      ],
      value: "",
    },
    {
      label: "挂件背景色",
      key: "back_color",
      component: "color",
      value: "",
      isShow: function () {
        return this.value.pages_type == "custom";
      },
    },
    {
      label: "指示点颜色",
      key: "pilot",
      component: function (h, { key }) {
        return <ColorCell v-model={this.value[key]} uuid={this.value.uuid} show={this.value.pages_type == "custom"}/>;
      },
      isShow: function () {
        return this.value.pages_type == "custom";
      },
      value:{
        dot_noCover: "",
        dot_cover: ""
      }
    },
    {
      label: "指示点颜色",
      key: "dotColor",
      component: "radio",
      options: [
        { name: "深色", label: "dark" },
        { name: "亮色", label: "light" },
      ],
      value: "dark",
      isShow: function () {
        return this.value.pages_type == "";
      },
    },
    { label: "组件间距", key: "padded", component: "switch", value: false },
    { label: "圆角图片", key: "rounded", component: "switch", value: true },
    { label: "图片间隔", key: "imgpadded", component: "switch", value: false },
    {
      label: "指示点样式",
      key: "dot",
      component: "radio",
      options: [
        { name: "点", label: true },
        { name: "数字", label: false },
      ],
      value: true,
      isShow: function () {
        return this.value.pages_type == "" && this.value.dot_type == "";
      },
    },
    { label: "指示点覆盖", key: "dotCover", component: "switch", value: true },
    {
      label: "指示点位置",
      key: "dotLocation",
      component: "radio",
      options: [
        { name: "居左", label: "left" },
        { name: "居中", label: "center" },
        { name: "居右", label: "right" },
      ],
      value: "center",
      isShow: function () {
        return this.value.pages_type == "" && this.value.dot_type == "";
      },
    },
    {
      label: "指示点形状",
      key: "shape",
      component: "radio",
      options: [
        { name: "圆形", label: "circle" },
        { name: "长方型", label: "rectangle" },
      ],
      value: "circle",
      isShow: function () {
        return this.value.pages_type == "" && this.value.dot_type == "";
      },
    },
    {
      label: "轮播项间距",
      key: "imgpadded",
      component: "switch",
      value: false,
    },
    { label: "图片描述", key: "content", component: "switch", value: true },
    {
      label: "轮播项",
      key: "data",
      component: function (h, { key }) {
        return <AttrItem v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: [],
    },
  ],
  transformIn: (v) => {
    const {
      name,
      base,
      config: { dot_noCover, padded, dot_cover, ...otherConfig },
      data,
    } = v;
    return {
      name,
      ...base,
      ...otherConfig,
      pilot: {
        dot_noCover,
        dot_cover,
      },
      imgpadded: padded,
      data: data.map((item) => {
        const {
          linkPage,
          pic_type = "",
          title,
          ctoekn,
          id,
          hotData = [],
          videoUrl,
          byte_mini_shop_id,
          ...content
        } = item;
        const url = isObject(videoUrl) ? videoUrl : videoUrl?{url:videoUrl}:{};
        return {
          data: {
            linkPage: linkPage,
            ctoekn,
            id: id,
            title: title,
            byte_mini_shop_id
          },
          pic_type,
          hotData,
          videoUrl:url,
          ...content,
        };
      }),
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          title: "title",
          subtitle: "subtitle",
          floor_var: "floor_var",
          padded: "padded",
          WordColor: "WordColor",
        });
      },
      config: (v) => {
        return pickBy(v, {
          interval: "interval",
          spacing: "spacing",
          pages_type: "pages_type",
          dot_type: "dot_type",
          back_color: "back_color",
          dot_noCover: "pilot.dot_noCover",
          dot_cover: "pilot.dot_cover",
          dotColor: "dotColor",
          rounded: "rounded",
          padded: "imgpadded",
          dot: "dot",
          dotCover: "dotCover",
          dotLocation: "dotLocation",
          shape: "shape",
          content: "content",
          autoplay: "autoplay",
        });
      },
      data: ({ data }) => {
        return pickBy(data, {
          imgUrl: "imgUrl",
          pic_type: "pic_type",
          videoUrl: "videoUrl",
          content: "content",
          mainTitle: "mainTitle",
          subtitle: "subtitle",
          subtitleTow: "subtitleTow",
          button: "button",
          imageName: "imageName",
          template: "template",
          media_type: "media_type",
          linkPage: "data.linkPage",
          ctoken: "data.ctoken",
          id: "data.id",
          title: "data.title",
          hotData: "hotData",
          byte_mini_shop_id:'data.byte_mini_shop_id'
        });
      },
    });
  },
};
