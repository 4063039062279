import { pickBy } from "@/utils";
import AttrSelect from "./attr-select.vue";

export default {
  name: "storeRuleList",
  setting: [
    {
      label: "展示规则",
      key: "rule_id",
      value: 0,
      component: function (h, { key }) {
        return <AttrSelect v-model={this.value[key]} uuid={this.value.uuid} />;
      }
    },
    {
      label: "距离顶部高度",
      key: "num",
      value: 100,
      component: function (h, { key }) {
        return <el-input v-model={this.value[key]} type="number" style="width: 201px;padding:0px" />
      }
    },
  ],
  transformIn: (v) => {
    const { name, base, config, data } = v;
    return {
      name,
      ...base,
      ...config,
      data: [],
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return {
          title: '门店规则列表',
          subtitle: '',
          padded: true,
        }
      },
      config: () => {
        return pickBy(v, {
          rule_id: "rule_id",
          num: "num",
        });
      },
      data: ({ data }) => {
        return []
      },
    });
  },
};
