<template>
  <div class="active-setting">
    <div class="active-setting-content">
      <span v-if="!localValue.activity_title" class="text-blue" @click="addOffline">请选择活动</span>
      <span v-else>{{ localValue.activity_id }}：{{ localValue.activity_title }}</span>
    </div>
    <i class="el-icon-edit" @click="addOffline"></i>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'active-setting',
  props: {
    value: {
      type: Object,
      default: () => { }
    },
    uuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: {
        activity_id: '',
        activity_title: ''
      }
    }
  },
  methods: {
    // 门店活动
    async addOffline() {
      const { data } = await this.$picker.offlineactivity({
        data: [this.localValue.activity_id],
        multiple: false,
      })
      this.localValue = {
        activity_id: data[0].activity_id,
        activity_title: data[0].activity_title
      }
      this.$emit('input', this.localValue)
    },
  },
  watch: {
    uuid: {
      handler(val) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.active-setting {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-content {
    .text-blue {
      color: #409eff;
      cursor: pointer;
    }
  }
}
</style>