import { pickBy } from '@/utils'
import AttrBlock from './attr-block'
import AttrColor from "./attr-color.vue";

export default {
  name: 'blokScroll',
  setting: [
    { label: '标题', key: 'title', component: 'input', value: '当地必买' },
    { label: '副标题', key: 'subtitle', component: 'input', value: '看看大家都在买什么' },
    { label: '描述', key: 'description', component: 'input' },
    { label: '楼层', key: 'floor_var', component: 'input' },
    { label: '组件间距', key: 'padded', component: 'switch', value: true },
    {
      label: '商品类型',
      key: 'type',
      component: 'radio',
      options: [
        { name: '福利', label: 'welfare' },
        { name: '门店活动', label: 'activity' },
        { name: '门店服务', label: 'service' },
        { name: '热卖', label: 'selling' }
      ],
      value: 'welfare'
    },{
      label: "Tab样式",
      key: "style",
      component: function (h, { key }) {
        return <AttrColor v-model={this.value[key]} uuid={this.value.uuid} defaultTxt='默认色' checkTxt='选中色'/>;
      },
      value: {
        default_color: "#666666",
        checked_color: "#687BF2",
      },
      isShow: function () {
        return this.value.type == 'selling'
      }
    },
    {
      label: '显示价格',
      key: 'showPrice',
      component: 'switch',
      value: true,
      isShow: function () {
        return this.value.type == 'selling'
      }
    },{
      label: "价格样式",
      key: "priceStyle",
      component: function (h, { key }) {
        return <AttrColor v-model={this.value[key]} uuid={this.value.uuid}  defaultTxt='背景色' checkTxt='字体色'/>;
      },
      value: {
        default_color: "#f5f5f7",
        checked_color: "#007aff",
      },
      isShow: function () {
        return this.value.type == 'selling'&&this.value.showPrice
      }
    },
    {
      label: '热卖描述',
      key: 'desc_type',
      component: 'radio',
      options: [
        { name: '描述', label: 'describe' },
        { name: '优惠价格', label: 'coupon' }
      ],
      value: 'describe',
      isShow: function () {
        return this.value.type == 'selling'
      }
    },
    {
      key: 'goods',
      component: function (h, { key }) {
        return <AttrBlock type={this.value.type} v-model={this.value[key]} uuid={this.value.uuid}/>
      },
      value: {
        type: 'welfare',
        data: [],
        tabId: '',
        uuid:''
      }
    }
  ],
  transformIn: (v) => {
    const { name, base, config, data } = v
    return {
      name,
      ...base,
      ...config,
      goods: {
        data,
        tabId: data[0]?.id
      }
    }
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: 'name',
      base: (v) => {
        return pickBy(v, {
          title: 'title',
          subtitle: 'subtitle',
          padded: 'padded',
          backgroundImg: 'backgroundImg',
          floor_var:'floor_var',
          description:'description'
        })
      },
      config: (v) => {
        return pickBy(v, {
          seckillId: 'seckillId',
          leaderboard: 'leaderboard',
          showPrice: 'showPrice',
          type: 'type',
          desc_type: 'desc_type',
          moreLink: 'moreLink',
          style:'style',
          priceStyle:'priceStyle'
          // lastSeconds: 'goodsSetting.lastSeconds'
        })
      },
      data: ({ goods: { data } }) => {
        return data
      }
    })
  }
}
