<style lang="scss" src="./index.scss"></style>
<template>
  <div :class="{
    'wgt-custom-wgt': true,
    'padded': value.padded
  }">
    <div v-if="refresh">
      <div class="wgt-custom-wgt-bd" v-if="!value.data || value.data.length == 0">
        <div>点击编辑“自定义页面模块”</div>
      </div>
      <div v-if="value.data">
        <component v-for="(wgt, index) in value.data" :is="wgt.name" :value="wgt" :key="`ab-component__${index}`" />
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
import { gWgts } from '../../scene'
import { transformSelectTem, getWgts } from "../../utils"
export default {
  name: 'CustomWgt',
  wgtName: '自定义模块',
  wgtDesc: '',
  wgtIcon: 'wgt-custom-wgt',
  wgtType: 2,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      refresh: false
    }
  },
  mounted() { console.log('val4441', this.value) },
  methods: {
    async getList() {
      if (!this.value.pages_template_id) {
        this.refresh = true
        return
      }
      this.refresh = false
      const { scene = '1001' } = this.$route.query
      const wgtList = getWgts(scene)
      const value = await transformSelectTem({ pages_template_id: this.value.pages_template_id, template_title: this.value.template_title }, wgtList)
      this.$set(this.value, 'data', value)
      this.$nextTick(() => {
        this.refresh = true
      })
    }
  },
  watch: {
    "value.pages_template_id": {
      handler(val) {
        console.log('val444', val)
        this.getList()
      },
      immediate: true
    }
  },

}
</script>
