<template>
  <div class='comp-background'>
    <div class="comp-background__item">
      <span>背景色</span>
      <el-color-picker v-model="localData.color"></el-color-picker>
    </div>
    <div class="comp-background__item">
      <span>背景图</span>
      <SpImagePicker v-model="localData.imageUrl" text="" />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        color: "",
        imageUrl: ""
      })
    },
    uuid: {
      type: String,
      default: () => ""
    }
  },
  watch: {
    uuid: {
      handler() {
        this.localData = cloneDeep(this.value)
      },
      immediate: true,
      deep: true
    },
    localData: {
      handler(val) {
        this.$emit('input', val)
      },
      immediate: true
    }

  },
  data() {
    return {
      localData: {
        color: "#fff",
        imageUrl: ""
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.comp-background {
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    height: 40px;

    span {
      margin-right: 10px;
    }
  }
}
</style>
<style lang="scss">
.comp-background {
  .sp-image-picker {

    .placeholder,
    .image-item {
      width: 40px;
      height: 40px;
    }

    .icon-camera {
      font-size: 16px;
    }

    .add-text {
      display: none;
    }
  }
}
</style>