<style lang="scss" src="./index.scss" scoped></style>
<style lang="scss">
.wgt-block-scroll {
  .decimal-value {
    font-size: 12px !important;
  }
}
</style>
<template>
  <div :class="{ 'wgt-block-scroll': true, 'padded': value.padded }">
    <!-- moreLink: {{ value }} -->
    <div v-if="value.title || value.subtitle" class="wgt-hd">
      <span class="title">{{ value.title }}</span>
      <span class="sub-title">{{
        value.subtitle
        }}</span>
    </div>
    <div class="wgt-bd" :class="{ 'spaced': value.spaced }">
      <div class="tab-selling" v-if="value.type == 'selling'">
        <div v-for="(item, index) in data" :key="index" class="tab-selling-item">
          <img :src="item.activity_cover" class="tab-selling-img" />
          <div class="item-tab-selling"
            :style="{ color: `${item.id == value.goods.tabId ? value.style.checked_color : value.style.default_color}` }">
            {{
              item.activity_title }}</div>
        </div>
      </div>
      <div v-if="value.type == 'selling' && data.length > 0">
        <video-player v-if="media_type == 1" class="vjs-custom-skin" :options="{
          poster,
          aspectRatio: '16:9',
          sources: [{
            // mp4
            type: 'video/mp4',
            src: video_url
          }],

          notSupportedMessage: '无可播放媒体资源',
          controlBar: false
        }">
        </video-player>
        <SpImage v-if="media_type == 2" :src="poster" />
      </div>
      <div class="scroll-goods" v-if="value.type != 'selling'">
        <div v-for="(item, index) in data" :key="`scroll-item__${index}`" class="scroll-item">
          <div class="goods-item">
            <div class="goods-imgs">
              <SpImage :src="item.activity_cover" :width="130" :height="130" />
            </div>
          </div>
          <div v-if="value.backgroundImg" class="goods-more">
            <SpImage :src="value.backgroundImg" :width="130" :height="200" />
          </div>
          <div v-if="!value.backgroundImg &&
            value.moreLink &&
            (value.moreLink.linkUrl || value.moreLink.linkPage)
          " class="goods-more">
            查看更多
          </div>
        </div>
      </div>


      <div class="scroll-selling-goods" v-if="value.type == 'selling'">
        <div v-for="(item, index) in goodsList" :key="`scroll-item___goods_${index}`" class="selling-scroll-item">
          <div class="selling-goods-item">
            <SpImage :src="item.home_pic" :width="165" :height="138" />
            <div class='goods-selling_text'>{{ value.desc_type == 'describe' ? item.selling_text : '' }}</div>
            <div class="goods-title">{{ item.item_name }}</div>
            <div v-if="value.showPrice" class="goods-caption" :style="`backgroundColor:${value?.priceStyle?.default_color};
                        color:${value?.priceStyle?.checked_color}`">
              <div class="goods-price">{{ item.activity_price }}起*</div>
            </div>
          </div>
        </div>
      </div>
      <div class='mac-recommend'>
          <div class='mac-recommend-suitable' v-if="device_recommend">
            <SpImage :src="device_recommend_img" fit="cover"/>
          </div>
          <div class='mac-recommend-compare' v-if="device_compare">
            <SpImage :src="device_compare_img" fit="cover"/>
          </div>
        </div>
      <div v-if="value.type == 'selling'" class="wgt-selling-desc">{{ value.description }}</div>
    </div>
  </div>
</template>

<script>
import config from './config'
const subscript = require('@/assets/img/subscript.png')

export default {
  name: 'blokScroll',
  wgtName: '区块滚动',
  wgtDesc: '',
  wgtIcon: 'wgt-goods-scroll',
  wgtType: 1,
  wgtTem: 0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      subscriptImg: subscript,
      data: [],
      goodsList: [],
      video_url: null,
      poster: '',
      media_type: 1,
      device_recommend: '',
      device_compare: '',
      device_recommend_img:"",
      device_compare_img:""
    }
  },
  watch: {
    'value': {
      handler(newVal) {
        const { type, goods: { data, tabId } } = newVal
        this.data = data
        if (type == "selling" && data.length > 0) {
          let dataitem = data.find(item => item.id == tabId)
          if (!dataitem) return
          let items = JSON.parse(dataitem.items)
          this.device_recommend = dataitem.device_recommend
          this.device_compare = dataitem.device_compare
          this.device_compare_img = dataitem.device_compare_img
          this.device_recommend_img = dataitem.device_recommend_img
          this.video_url = dataitem.video_url
          this.poster = dataitem.video_cover
          this.media_type = dataitem.media_type
          this.goodsList = items;
        } else {
          this.goodsList = []
          this.video_url = null
        }
      },
      deep: true,
      immediate: true
    }
  },
  // computed: {
  //   data() {
  //     const { type, data, secKillGoods, limitTimeSaleGoods } = this.value
  //     if (type == 'goods') {
  //       return data
  //     } else if (type == 'limitTimeSale') {
  //       return limitTimeSaleGoods
  //     } else {
  //       return secKillGoods
  //     }
  //   }
  // },
  methods: {
    getStatusText(status) {
      if (status == 'in_sale') {
        return '后结束'
      } else if (status == 'in_the_notice') {
        return '后开始'
      } else {
        return '活动已结束'
      }
    }
  }
}
</script>
