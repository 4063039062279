<template>
  <div class="wgt_productShelves_relate-config">
    <div class="wgt_productShelves_relate-config-line"></div>
    <div class="relate-config-header">
      <div class="relate-config-title attr-panel-group-title">商品关联设置</div>
      <div :class="{ 'relate-config-header_link-disabled': true }" @click="handleLink">
        机型比较和推荐
        <el-button icon="el-icon-edit" type="text" />
      </div>
    </div>
    <SpDialog v-model="visible" title="机型比较和推荐" width="800px" :form="goodsForm" :form-list="goodsFormList"
      @onSubmit="onFormSubmit" />
  </div>
</template>

<script>
import { DEVICEOPTION } from "@/consts"
import { cloneDeep } from "lodash-es"
export default {
  props: {
    value: {
      type: Object
    },
    uuid: {
      type: String
    }
  },
  data() {
    return {
      visible: false,
      goodsForm: null,
      localValue: null,
      goodsFormList: [{
        label: '机型对比',
        key: 'device_compare',
        // type: 'select',
        // options: DEVICEOPTION,
        component: ({ key }) => {
          return <div class="relate-config_recommend">
            <el-select v-model={this.goodsForm[key]}  clearable onClear={this.handleChangeCompare}>
              {
                DEVICEOPTION.map(item => {
                  return <el-option label={item.label} value={item.value} />
                })}
            </el-select>
            {this.goodsForm.device_compare && <SpImagePicker v-model={this.goodsForm.device_compare_img} size="small" class="video-link" text="" />}
          </div>
        }
      }, {
        label: '机型推荐',
        key: 'device_recommend',
        // type: 'select',
        // options: DEVICEOPTION,
        component: ({ key }) => {
          return <div class="relate-config_recommend">
            <el-select v-model={this.goodsForm[key]}  clearable onClear={this.handleChangeRecommend}>
              {
                DEVICEOPTION.map(item => {
                  return <el-option label={item.label} value={item.value} />
                })}
            </el-select>
            {this.goodsForm.device_recommend && <SpImagePicker v-model={this.goodsForm.device_recommend_img} size="small" class="video-link" text="" />}
          </div>
        }
      }]
    }
  },
  methods: {
    handleChangeCompare(){
      this.$set(this.goodsForm,'device_compare_img','')

    },
    handleChangeRecommend() {
      this.$set(this.goodsForm,'device_recommend_img','')
    },
    handleCompare() {
      this.visible = true
      this.goodsForm = cloneDeep(this.localValue)
    },
    handleRecommend() {
      this.visible = true
      this.goodsForm = cloneDeep(this.localValue)
    },
    handleLink() {
      this.visible = true
      this.goodsForm = cloneDeep(this.localValue)
    },
    onFormSubmit() {
      if(this.goodsForm.device_compare && !this.goodsForm.device_compare_img) {
        this.$message.error('请上传机型对比图片')
        return
      }
      if(this.goodsForm.device_recommend && !this.goodsForm.device_recommend_img) {
        this.$message.error('请上传机型推荐图片')
        return
      }
      this.localValue = cloneDeep(this.goodsForm)
      this.visible = false
      this.$nextTick(() => {
        this.$emit('input', this.localValue)
      })
    }
  },
  watch: {
    uuid: {
      handler(nVal, oVal) {
        this.localValue = cloneDeep(this.value)
      },
      deep: true,
      immediate: true
    }
  }

}
</script>

<style lang="scss" scoped>
.wgt_productShelves_relate-config {
  margin-left: -80px;

  &-line {
    width: calc(100% + 20px);
    margin-left: -10px;
    height: 10px;
    background-color: #fff;

  }

  .relate-config {
    &-title {
      margin-left: -10px;
      font-size: 14px;
      color: #333;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;

      &_link {
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        color: #999 !important;

        &-disabled {
          color: #409EFF;
          cursor: pointer;
          justify-content: space-between;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.relate-config_recommend {
  display: flex;
  align-items: center;

  .sp-image-picker .placeholder,
  .image-item {
    margin-left: 10px;
    height: 36px !important;
    width: 120px !important;

    .add-text {
      display: none;
    }
  }
}
</style>