<style lang="scss" scoped>
.sd-bd {
  display: flex;

  .picker-link {
    padding-left: 10px;
  }

  .zone-item {
    padding-left: 10px;
  }

  .input-m {
    margin-top: 6px;

    &.img_name {
      font-size: 14px;

      .img-title {
        margin-right: 5px;
        padding-left: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
.attr_slider-dialog {
  .el-form {
    margin-right: 0px !important;

    .btn-linkpath {
      min-width: 120px;
    }
  }

  .video-list {
    display: flex;
  }
}

.zone-item {
  .comp-picker-link {
    .btn-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;


      .input-m {
        margin-top: 6px;
        margin-left: 0px;
      }
    }
  }
}

.slider_imgs {
  .zone-item {
    padding-bottom: 5px;
  }

  .h5-link {
    margin-top: 0px !important;
  }
}

.attr_slider {
  .todo-list {
    align-items: flex-start;
  }

  &_item {
    display: flex;

    .image-item {
      height: 71px !important;
      box-sizing: border-box;

      .el-image {
        height: 69px !important;
      }
    }
  }
}
</style>
<template>
  <div class="attr_slider">
    <CompTodoList v-model="localValue" :max="20" @onAddItem="handleClickAdd">
      <template slot="body" slot-scope="scope">
        <div class="sd-bd">
          <sp-image :src="scope.data.imgUrl" width="70" height="70"
            @click.native="onSetHotZone(scope.data, scope.index)" />
            <CompPickerLink class="picker-link" v-if="!scope.data.pic_type"
              :isShowRadio='false' v-model='scope.data.data'>
              <el-input v-model='scope.data.data.byte_mini_shop_id'
                v-if="scope.data.data && scope.data.data.id == 'douyinStore'" placeholder="请填写抖音小店ID" size="small"
                class="input-m" />
            <div class="input-m img_name">
              <span class="img-title">图片名称:</span>
              <span>{{ scope.data.imageName }}</span>
            </div>
            </CompPickerLink>
          <div class="zone-item" v-if="scope.data.pic_type">
            <CompHotPicker v-for="(item, index) in scope.data.hotData" :key="`copy-zone-item__${index}`"
              :isShowH5Link="false" :value="item" @change="(e) => onChangeLocalLink(e, scope.index, index)"
              wgtType="hotzone" style="margin-bottom: 10px;" />
            <div class="input-m img_name">
              <span class="img-title">图片名称:</span>
              <span>{{ scope.data.imageName }}</span>
            </div>
          </div>
        </div>
      </template>
    </CompTodoList>
    <SpDialog class="attr_slider-dialog" ref="dialogRef" v-model="dialog" title="轮播项" :form="sliderForm"
      :form-list="sliderFormList" @onSubmit="onSliderFormSubmit" />
  </div>
</template>

<script>
import hotzone from 'vue-hotzone'
import { cloneDeep } from 'lodash'
import CompTodoList from '../../comps/comp-todoList'
import CompButton from '../../comps/comp-button'
import CompHotPicker from '../../comps/comp-hotpicker'
import CompPickerLink from '../../comps/comp-pickerLink'
export default {
  name: 'AttrItem',
  components: {
    CompButton,
    CompTodoList,
    CompPickerLink,
    CompHotPicker
  },
  props: {
    value: {
      type: Array
    },
    direction: {
      type: String
    },
    uuid: {
      type: String
    }
  },
  data() {
    return {
      localValue: [],
      dialog: false,
      currentIndex: 0,
      sliderForm: null,
      ischange: true
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler: function (nVal, oVal) {
        this.$emit('input', nVal)
      }
    },
    uuid: {
      handler(nVal, oVal) {
        this.localValue = cloneDeep(this.value)
      },
      deep: true
    }
  },
  computed: {
    sliderFormList() {
      const _that = this
      if (!this.sliderForm) return []
      const { media_type, pic_type, data: { id } } = this.sliderForm
      return [{
        label: '模板类型',
        key: 'media_type',
        type: 'radio',
        options: [{
          label: 'img',
          name: '图片'
        }, {
          label: 'video',
          name: '视频'
        }],
        onChange: function () {
          _that.$set(_that.sliderForm, 'imgUrl', '')
          _that.$set(_that.sliderForm, 'videoUrl', {})
          _that.$set(_that.sliderForm, 'pic_type', '')
          _that.$set(_that.sliderForm, 'hotData', [])
        }
      }, {
        label: '图片类型',
        key: 'pic_type',
        type: 'radio',
        isShow: media_type == "img",
        options: [{
          label: '',
          name: '普通图片'
        }, {
          label: 'hot',
          name: '热区图片'
        }],
        onChange: function () {
          _that.$set(_that.sliderForm, 'imgUrl', '')
          _that.$set(_that.sliderForm, 'hotData', [])
        }
      },
      {
        label: '视频',
        key: 'videoUrl',
        isShow: media_type == "video",
        required:media_type == "video",
        message:'不能为空',
        component: ({ key }) => {
          return (
            <div class="video-list">
              <SpImagePicker v-model={this.sliderForm.imgUrl} size="small" class="video-link" text="封面" />
              <SpVideoPicker v-model={this.sliderForm[key]} size='small' class="video-link" />
            </div>
          )
        },
      },
      {
        label: '轮播图',
        isShow: media_type == "img",
        key: 'imgUrl',
        maxlength: 150,
        placeholder: '请输入分组名称',
        required: media_type == "img",
        message: '不能为空',
        component: ({ key }) => {
          return (pic_type == 'hot' ? <div class='slider_imgs'>
            <el-button type="primary" size="small" plain onClick={() => this.onSelectImage()}> 选择图片 </el-button>
            <el-row gutter={20}>
              <el-col span={14}>
                <hotzone
                  class="hotzone"
                  image={this.sliderForm.imgUrl}
                  zones-init={this.sliderForm.hotData}
                  on-change={(zone) => this.handleChange(zone)}
                  on-remove={(index) => this.handleRemove(index)}
                />
              </el-col>
              {this.ischange && <el-col span={10}>
                {this.sliderForm.hotData && this.sliderForm.hotData?.map((item, index) => {
                  return (
                    <div
                      key={`zone-item__${index}`}
                      class="zone-item"
                    >
                      <CompHotPicker isShowH5Link={false} value={item} on-change={(e) => this.onChangeLink(e, index)} />
                    </div>
                  )
                })}
              </el-col>}
            </el-row>
          </div> : <div class='slider_imgs'>
            <SpImagePicker v-model={this.sliderForm['imgUrl']} size="large" />
            {media_type == 'video' && <SpVideoPicker type="object" v-model={this.sliderForm['videoUrl']} size='large' class="video-link" />}
          </div>)
        },
      }, {
        label: '设置路径',
        key: 'data',
        isShow: pic_type != 'hot',
        component: ({ key }) => {
          return (<CompPickerLink class='slider-pick-link' is-show-radio={false} v-model={this.sliderForm[key]} onchange={(e) => this.onChangeLink(e)} >
            {id == 'douyinStore' && <el-input v-model={this.sliderForm[key].byte_mini_shop_id} placeholder="请填写抖音小店ID" size="small" class="input-m" />}
          </CompPickerLink>)
        }
      },
      {
        label: '标题',
        key: 'mainTitle',
        type: 'input'
      },
      {
        label: '图片名称',
        key: 'imageName',
        type: 'input'
      },]
    }
  },
  created() {
    this.localValue = cloneDeep(this.value)
  },
  methods: {
    async onSetHotZone(item, index) {
      this.currentIndex = index
      this.dialog = true
      this.sliderForm = cloneDeep(item)
    },
    async handleClickAdd() {
      this.localValue = await this.localValue.concat(
        {
          imgUrl: "",
          videoUrl: {},
          content: "",
          mainTitle: "",
          subtitle: "",
          subtitleTow: "",
          button: "",
          imageName: "",
          template: "one",
          media_type: "img",
          pic_type: '',
          hotData: [],
          data: {
            linkPage: '',
            id: '',
            title: ''
          }
        }
      )
    },
    async onSliderFormSubmit(e) {
      this.dialog = false
      await this.$set(this.localValue, this.currentIndex, this.sliderForm)
      this.sliderForm = null
    },
    onChangeLink(e, index) {
      const v = cloneDeep(this.sliderForm.hotData[index])
      this.$set(this.sliderForm.hotData, index, {
        ...v,
        ...e
      })
    },
    onChangeLocalLink(e, pid, index) {
      const v = cloneDeep(this.localValue[pid].hotData[index])
      this.$set(this.localValue[pid].hotData, index, {
        ...v,
        ...e
      })
    },

    async onSelectImage() {
      const {
        data: { url }
      } = await this.$picker.image({
        data: { url: this.value.imgUrl }
      })
      this.$set(this.sliderForm, 'imgUrl', url)
    },

    handleChange(zone) {
      console.log('handle change, ', zone)
      const arr = []
      zone.forEach(({ heightPer, widthPer, leftPer, topPer }, index) => {
        const v = cloneDeep(this.sliderForm.hotData[index])
        const obj = {
          heightPer,
          leftPer,
          topPer,
          widthPer
        }
        arr.push({
          ...v,
          ...obj
        })
      })
      this.$set(this.sliderForm, 'hotData', arr)
    },
    handleRemove(index) {
      this.ischange = false
      this.sliderForm.hotData.splice(index, 1)
      this.$nextTick(() => {
        this.ischange = true
      })
    },
  }
}
</script>
