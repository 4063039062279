import { pickBy } from "@/utils";
export default {
  name: "recentOrders",
  setting: [
    { label: "商品图大小", key: "imgSize", component: "number", value: "60" },
    { label: "订单类型字体", key: "warehouseSize", component: "number", value: "12" },
    { label: '', key: 'warehouseColor', component: 'color', value: '#000' },
    { label: "商品名称字体", key: "nameSize", component: "number", value: "14" },
    { label: '', key: 'nameColor', component: 'color', value: '#000' },
    { label: "配送信息字体", key: "deliverySize", component: "number", value: "12" },
    { label: '', key: 'deliveryColor', component: 'color', value: '#000' },
    { label: "展示订单完成后几天内的订单", key: "days", component: "number", value: "" },
  ],
  transformIn: (v) => {
    const { name, base, config } = v;
    return {
      name,
      ...base,
      ...config
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return {}
      },
      config: (v) => {
        return pickBy(v, {
          imgSize: "imgSize",
          warehouseSize: "warehouseSize",
          warehouseColor: "warehouseColor",
          nameSize: "nameSize",
          nameColor: "nameColor",
          deliverySize: "deliverySize",
          deliveryColor: "deliveryColor",
          days: "days"
        });
      }
    });
  },
};
