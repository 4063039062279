<style lang="scss" scoped>
.input-m {
  margin-bottom: 10px;
}

.hot-content {
  margin-top: 10px;
}

.zone-item {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px dashed #aaaaaa;
}
.hot-content-list{
  border: 1px dashed #aaaaaa;
  padding: 0 10px;
  margin: 10px 0;
}

.hotzone-content {
  position: absolute;
}

.hot-setting {
  ::v-deep .el-button:first-child {
    width: 85px;
    text-align: left;
  }

  .form-item {
    display: flex;
    align-items: center;
    width: fit-content;
    position: relative;
    margin-bottom: 10px;

    .input-m-label {
      padding-right: 10px;
      font-size: 11px;
      width: 100px;
      color: #a6a6a6;
    }

    .el-input {
      margin-bottom: 0px !important;
    }

    .error-tip {
      position: absolute;
      font-size: 8px;
      bottom: -12px;
      left: 60px;
      color: red;
    }
  }
}
</style>
<template>
  <div class="hot-setting">
    <div class="input-m form-item"  v-if="value.imgUrl" >
      <!-- <span class="input-m-label">资源位名称 <span style="color: red;">*</span></span> -->
      <el-input v-model="value.flowName_var" size="mini" placeholder="请输入资源位名称" clearable/>
      <span style="color: red;">*</span>
    </div>
    <CompButton placeholder="设置热区" format="{0}个热区" :value="value.data.length" :view-btn="false" @click="onSetHotZone"
      @remove="onRemoveHotZone" />
    <div v-if="refresh" class="hot-content-list">
      <div v-for="(item, index) in value.data" :key="`copy-zone-item__${index}`" class="zone-item"
        style="margin-top: 10px;">
        <el-input v-model="item.imgName" size="mini" placeholder="请输入热区名称" class="input-m" clearable />
        <CompHotPicker :isShowH5Link="false" :value="item" @change="(e) => onChangeLocalLink(e, index)"
          wgtType="hotzone" />
      </div>
    </div>

    <el-dialog :visible="dialog" class="sp-dialog" append-to-body destroy-on-close title="热区设置" width="800px"
      @close="onCancel">
      <div v-if="dialog" class="">
        <div>
          <el-button type="primary" size="small" plain @click="onSelectImage"> 选择图片 </el-button>
          <span style="font-size: 12px; color: #888; margin-left: 4px">建议尺寸:（宽度640px，高度自适应）</span>
        </div>

        <div class="hot-content">
          <el-row :gutter="20">
            <el-col :span="12" style="position: relative;">
              <div class="hotzone-content" v-for="(item, index) in localValue.data" :key="`zone-item__${index}`" v-if="visible"
                :style="{ position: 'absolute', top: `${item.topPer * 100}%`, left: `${item.leftPer * 100}%`, width: `${item.widthPer * 100}%`, height: `${item.heightPer * 100}%` }">
                <div class='privacy-policy' :style="`bottom:${-item.bottom || 0}px`"
                  v-if="item.id === 'register' || item.linkPage === 'ordertogift'">
                  <div class='privacy-policy__circle'></div>
                  <div class='privacy-policy__info'>
                    <span class="privacy-policy__text">我已阅读并接受“Apple 授权专营店商城”的</span>
                    <span class='privacy-policy__link'>会员隐私条款。</span>
                  </div>
                </div>
              </div>
              <hotzone class="hotzone" :image="localValue.imgUrl" :zones-init="localValue.data" @change="handleChange" @remove="handleRemove"></hotzone>
            </el-col>
            <el-col :span="12" v-if="ischange">
              <el-input v-model="localValue.flowName_var" size="mini" placeholder="请输入资源位名称" class="input-m" clearable v-if="value.imgUrl" />
              <div v-for="(item, index) in localValue.data" :key="`zone-item__${index}`" class="zone-item">
                <el-input v-model="item.imgName" size="mini" placeholder="请输入热区名称" class="input-m" clearable />
                <CompHotPicker :isShowH5Link="false" :value="item" @change="(e) => onChangeLink(e, index)" wgtType="hotzone" />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onConfirm"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import hotzone from 'vue-hotzone'
import { cloneDeep } from 'lodash'
import CompHotPicker from './comp-hotpicker'
import CompButton from './comp-button'
export default {
  name: 'AttrHotSetting',
  components: {
    CompHotPicker,
    CompButton,
    hotzone
  },
  props: {
    value: {
      type: Object
    },
    uuid: {
      type: String
    },
    padded: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: {
        flowName_var: '',
        imgUrl: '',
        data: []
      },
      dialog: false,
      refresh: true,
      ischange: true
    }
  },
  watch: {
    uuid: {
      deep: true,
      handler: function (nVal, oVal) {
        this.refresh = false
        this.$nextTick(() => {
          this.refresh = true
        })
        this.localValue = cloneDeep(this.value)
      }
    }
  },
  created() {
    this.localValue = cloneDeep(this.value)
  },
  updated() { },
  methods: {
    onSetHotZone() {
      this.dialog = true
    },
    onRemoveHotZone() {
      this.localValue.data = []
      this.$emit('input', this.localValue)
      this.refresh = false
      this.$nextTick(() => {
        this.refresh = true
      })
    },
    async onSelectImage() {
      const {
        data: { url }
      } = await this.$picker.image({
        data: { url: this.value.imgUrl }
      })
      this.localValue.imgUrl = url
    },
    handleChange(zone) {
      console.log('handle change, ', zone)
      zone.forEach(({ heightPer, widthPer, leftPer, topPer }, index) => {
        const v = cloneDeep(this.localValue.data[index])
        const obj = {
          // linkType: 0,
          // linkUrl: '',
          heightPer,
          leftPer,
          topPer,
          widthPer
        }
        Vue.set(this.localValue.data, index, {
          ...v,
          ...obj
        })
      })
    },
    handleRemove(index) {
      this.ischange = false
      this.localValue.data.splice(index, 1)
      this.$nextTick(() => {
        this.ischange = true
      })
    },
    async onChangeLocalLink(e, index) {
      await this.onChangeLink(e, index)
      this.$nextTick(() => {
        this.$emit('input', this.localValue)
        this.$emit('change', this.localValue)
      })
    },
    onChangeLink(e, index) {
      const v = cloneDeep(this.localValue.data[index])
      Vue.set(this.localValue.data, index, {
        ...v,
        ...e
      })
    },
    onCancel() {
      this.dialog = false
    },
    onConfirm() {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
      this.dialog = false

      this.refresh = false
      this.$nextTick(() => {
        this.refresh = true
      })
    }
  },
}
</script>
