<template>
  <div class="wgts-setting-style">
    <div class="wgts-setting-style-content" v-for="(item, key) in localValue" v-if="!((shelfType == 'recommend'||shelfType == 'history') && (key == 'marketing'||key=='offset'))">
      <p> {{ item.title }}</p>
      <el-popover placement="top" width="400" trigger="click" :title="`${title}-${item.title}`">
        <el-button icon="el-icon-edit" slot="reference" type="text" @click.stop="handleDialogVisible(item, key)" />
        <MarketingInfo v-model="localValue[key]" :type="key" :uuid="uuid" />
      </el-popover>
    </div>
    <!-- <el-drawer :visible.sync="dialogVisible" direction="rtl" :size="480" :title="title">
      <MarketingInfo :title="curIndex" v-model="localValue[curIndex]" :type="key" />
    </el-drawer> -->

  </div>
</template>


<script>
import { cloneDeep } from "lodash-es";
import MarketingInfo from "./marketing-info.vue";

export default {
  components: { MarketingInfo },
  props: {
    title: "",
    type: {
      type: String,
      default: ""
    },
    uuid: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: () => { }
    },
    shelfType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: null,
      dialogVisible: false,
      curIndex: 0
    }
  },
  methods: {
    handleDialogVisible(item, index) {
      this.dialogVisible = true
      this.curIndex = index
    }
  },
  watch: {
    uuid: {
      handler(val) {
        const _value = this.value
        let localValue = {}
        for (let key in _value) {
          if (_value.hasOwnProperty(key)) { // 确保属性是对象自身的而不是从原型链继承的
            if (!(['btn', 'discount'].includes(key))) {
              localValue[key] = _value[key]
            }
          }
        }
        this.localValue = localValue
      },
      deep: true,
      immediate: true
    },
    localValue: {
      handler(val) {
        this.$emit('input', val)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.wgts-setting-style {
  display: flex;
  flex-wrap: wrap;
  margin-top: -2px;
  flex: 1;

  &-content {
    width: 33%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
}
</style>