import { pickBy } from "@/utils";
import AttrItem from "./attr-item.vue";
export default {
  name: "footer_nav",
  setting: [
    {
      label: "背景色",
      key: "backgroundColor",
      component: "color",
      value: "#fff",
    },
    {
      label: "按钮图片",
      key: "imgUrl",
      component: function (h, { key }) {
        return <SpImagePicker v-model={this.value[key]} size="small" />;
      },
      value: "",
    },
    {
      label: "按钮背景色",
      key: "background",
      component: "color",
      value: "#2D69FB",
    },
    {
      label: "字体颜色",
      key: "color",
      component: "color",
      value: "#fff",
    },
    { label: "按钮文案", key: "text", component: "input", value: "" },
    {
      label: "按钮宽度",
      key: "width",
      component: function (h, { key }) {
        return (
          <el-input
            v-model={this.value[key]}
            placeholder="请输入宽度"
            style="width:200px"
          >
            <template slot="append">px</template>
          </el-input>
        );
      },
      value: " 200",
    },
    {
      label: "跳转",
      key: "btn",
      component: function (h, { key }) {
        return (
          <AttrItem
            v-model={this.value[key]}
            size="small"
            uuid={this.value.uuid}
          />
        );
      },
      value: {
        id:''
      },
    },
  ],
  transformIn: (v) => {
      // 兼容旧版
      if(!v?.config?.imgUrl){
        if(!v?.config){
          v.config={imgUrl: ""}
        }else{
          v.config.imgUrl = ""
        }
    }
    return pickBy(v, {
      name: "name",
      padded: "base.padded",
      imgUrl: "config.imgUrl",
      backgroundColor: "data.wrap.background",
      text: "data.btn.text",
      color: "data.btn.style.color",
      width: "data.btn.style.width",
      background: "data.btn.style.background",
      btn: (v) => {
        return pickBy(v, {
          linkPage: "data.btn.linkPage",
          ctoken: "data.btn.ctoken",
          id: "data.btn.id",
          title: "data.btn.title",
          rule_id:'config.moreLink.rule_id',
        });
      },
    });
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          padded: true,
        });
      },
      config: (v) => {
        return pickBy(v, {
          imgUrl: "imgUrl",
          moreLink: ({ btn }) => {
            return pickBy(btn, {
              rule_id: "rule_id",
            });
          },
        });
      },
      data: (v) => {
        return pickBy(v, {
          wrap: (v) => {
            return pickBy(v, {
              background: "backgroundColor",
            });
          },
          btn: (v) => {
            return pickBy(v, {
              text: "text",
              linkPage:'btn.linkPage',
              id:'btn.id',
              title:'btn.title',
              ctoken:'btn.ctoken',
              style: (v) => {
                return pickBy(v, {
                  color: "color",
                  width: "width",
                  background: "background",
                });
              },
            });
          },
        });
      },
    });
  },
};
