<template>
  <div>
    <el-button type="primary" size="small" plain @click="onSelectImage"> 选择图片 </el-button>
    <CompTodoList v-model="localValue" :is-show="false">
      <template slot="body" slot-scope="scope">
        <SpImage :src="scope.data.url"  style="height: 240px;width: 240px;" fit="cover"/>
        <el-input v-model="scope.data.name" placeholder="请输入壁纸名称"/>
        <el-divider/>
      </template>
    </CompTodoList>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import CompTodoList from '../../comps/comp-todoList'
export default {
  components: { CompTodoList },
  props: {
    value: {
      type: Array,
      default: []
    },
    uuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: []
    }
  },
  watch: {
    uuid: {
      handler(newVal) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true
    },
    localValue:{
      handler(newVal){
        this.$emit('input',newVal)
      },
      deep: true
    }
  },
  methods: {
    async onSelectImage() {
      const {
        data
      } = await this.$picker.image({
        data: this.localValue,
        multiple: true
      })
      this.localValue = data
    },
  }
}
</script>

<style lang="scss" scoped>
.el-button{
  margin-bottom: 15px;
}
.el-input{
  margin-top: 10px;
  width: 240px;
}
</style>