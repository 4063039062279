import { render, staticRenderFns } from "./comp_hotSetting.vue?vue&type=template&id=672b7144&scoped=true"
import script from "./comp_hotSetting.vue?vue&type=script&lang=js"
export * from "./comp_hotSetting.vue?vue&type=script&lang=js"
import style0 from "./comp_hotSetting.vue?vue&type=style&index=0&id=672b7144&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "672b7144",
  null
  
)

export default component.exports