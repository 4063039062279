<template>
  <div :class="{ 'wgt-official-account': true }" :style="settingStyle">
    <div class="official-account-content">
      <div class="official-account-name">Apple 授权专营店商城关联的公众号</div>
      <div class="official-account-info">
        <div class="info-left">
          <el-image class="official-account-logo" :src="VxLogon" fit="height-contain" />
          <div class="official-account-desc">
            <div class="official-account-title">Apple 授权专营店</div>
            <div class="official-account-subtitle">官方授权，3000多家门店...</div>
          </div>
        </div>
        <div class="official-account-btn">关注</div>
      </div>

    </div>

  </div>
</template>

<script>
import config from './config'
import VxLogo from "@/assets/imgs/vx_logo.png"
export default {
  name: 'OfficialAccount',
  wgtName: '关注公众号',
  wgtDesc: '',
  wgtIcon: 'wgt-official-account',
  wgtType: 2,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      VxLogon: VxLogo
    }
  },
  computed: {
    settingStyle() {
      const { bgImg, padded:{ paddedt, paddedr, paddedb, paddedl} } = this.value || {}
      return {
        backgroundImage: bgImg ? `url(${bgImg})` : 'none',
        backgroundSize: '100% auto',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        padding: `${paddedt || 0}px ${paddedr || 0}px ${paddedb || 0}px ${paddedl || 0}px`,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wgt-official-account {
  .official-account {
    &-content {
      box-sizing: border-box;
      padding: 5px 16px;
      background-color: #fff;
      border: 1px solid #979696;
      border-radius: 5px;
    }

    &-name {
      text-align: left;
      font-size: 10px;
      color: #cecece;
    }

    &-info {
      display: flex;
      align-items: center;
      padding: 12px 0;

      .info-left {
        display: flex;
        align-items: center;
      }
    }

    &-logo {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }

    &-desc {
      margin-left: 10px;
    }

    &-btn {
      color: #34b12e;
      border: 1px solid #34b12e;
      padding: 5px 20px;
      font-size: 12px;
      margin-left: auto;
    }

    &-title {
      font-size: 14px;
      font-weight: bold;
    }

    &-subtitle {
      font-size: 10px;
    }
  }

  .wgt-bg {
    width: 100%;
    height: 100%;
  }
}
</style>