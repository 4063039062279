var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'wgt-block-scroll': true, 'padded': _vm.value.padded }},[(_vm.value.title || _vm.value.subtitle)?_c('div',{staticClass:"wgt-hd"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.value.title))]),_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.value.subtitle))])]):_vm._e(),_c('div',{staticClass:"wgt-bd",class:{ 'spaced': _vm.value.spaced }},[(_vm.value.type == 'selling')?_c('div',{staticClass:"tab-selling"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"tab-selling-item"},[_c('img',{staticClass:"tab-selling-img",attrs:{"src":item.activity_cover}}),_c('div',{staticClass:"item-tab-selling",style:({ color: `${item.id == _vm.value.goods.tabId ? _vm.value.style.checked_color : _vm.value.style.default_color}` })},[_vm._v(" "+_vm._s(item.activity_title))])])}),0):_vm._e(),(_vm.value.type == 'selling' && _vm.data.length > 0)?_c('div',[(_vm.media_type == 1)?_c('video-player',{staticClass:"vjs-custom-skin",attrs:{"options":{
        poster: _vm.poster,
        aspectRatio: '16:9',
        sources: [{
          // mp4
          type: 'video/mp4',
          src: _vm.video_url
        }],

        notSupportedMessage: '无可播放媒体资源',
        controlBar: false
      }}}):_vm._e(),(_vm.media_type == 2)?_c('SpImage',{attrs:{"src":_vm.poster}}):_vm._e()],1):_vm._e(),(_vm.value.type != 'selling')?_c('div',{staticClass:"scroll-goods"},_vm._l((_vm.data),function(item,index){return _c('div',{key:`scroll-item__${index}`,staticClass:"scroll-item"},[_c('div',{staticClass:"goods-item"},[_c('div',{staticClass:"goods-imgs"},[_c('SpImage',{attrs:{"src":item.activity_cover,"width":130,"height":130}})],1)]),(_vm.value.backgroundImg)?_c('div',{staticClass:"goods-more"},[_c('SpImage',{attrs:{"src":_vm.value.backgroundImg,"width":130,"height":200}})],1):_vm._e(),(!_vm.value.backgroundImg &&
          _vm.value.moreLink &&
          (_vm.value.moreLink.linkUrl || _vm.value.moreLink.linkPage)
        )?_c('div',{staticClass:"goods-more"},[_vm._v(" 查看更多 ")]):_vm._e()])}),0):_vm._e(),(_vm.value.type == 'selling')?_c('div',{staticClass:"scroll-selling-goods"},_vm._l((_vm.goodsList),function(item,index){return _c('div',{key:`scroll-item___goods_${index}`,staticClass:"selling-scroll-item"},[_c('div',{staticClass:"selling-goods-item"},[_c('SpImage',{attrs:{"src":item.home_pic,"width":165,"height":138}}),_c('div',{staticClass:"goods-selling_text"},[_vm._v(_vm._s(_vm.value.desc_type == 'describe' ? item.selling_text : ''))]),_c('div',{staticClass:"goods-title"},[_vm._v(_vm._s(item.item_name))]),(_vm.value.showPrice)?_c('div',{staticClass:"goods-caption",style:(`backgroundColor:${_vm.value?.priceStyle?.default_color};
                      color:${_vm.value?.priceStyle?.checked_color}`)},[_c('div',{staticClass:"goods-price"},[_vm._v(_vm._s(item.activity_price)+"起*")])]):_vm._e()],1)])}),0):_vm._e(),_c('div',{staticClass:"mac-recommend"},[(_vm.device_recommend)?_c('div',{staticClass:"mac-recommend-suitable"},[_c('SpImage',{attrs:{"src":_vm.device_recommend_img,"fit":"cover"}})],1):_vm._e(),(_vm.device_compare)?_c('div',{staticClass:"mac-recommend-compare"},[_c('SpImage',{attrs:{"src":_vm.device_compare_img,"fit":"cover"}})],1):_vm._e()]),(_vm.value.type == 'selling')?_c('div',{staticClass:"wgt-selling-desc"},[_vm._v(_vm._s(_vm.value.description))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }