<style lang="scss">
.attr-block {
  width: 100%;
  .block-item {
    // display: flex;
    padding: 8px 0 8px 8px;
    // align-items: center;
    // border: 1px solid #dcdfe6;
    // border-radius: 4px;
    .el-radio {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .el-radio__label {
      padding-left: 6px;
    }

    .block {
      padding-left: 10px;
    }
    .activity-info {
      font-size: 13px;
      padding-left: 20px;
      margin-top: 6px;
    }
  }

  .todo-list {
    position: relative;
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: -6px;
        height: 1px;
        background-color: #dcdfe6;
      }
    }
  }
}
</style>
<template>
  <div class="attr-block">
    <CompButton
      :placeholder="placeholder"
      :format="format"
      :value="localValue.data.length"
      @click="handleClickAdd"
      @remove="onRemove"
      :view-btn="false"
    />
    <div style="width: 100%; margin-top: 10px">
      <CompTodoList v-model="localValue.data" :is-show="false">
        <template slot="body" slot-scope="scope">
          <div class="block-item" v-if="type == 'selling'">
            <el-radio v-model="localValue.tabId" :label="scope.data.id">{{
              scope.data.activity_title
            }}</el-radio>
            <!-- <SpImage :src="scope.data.activity_cover" size="small" width="30" height="30" /> -->
            <div class="activity-info">
              <div>{{ `id：${scope.data.id}` }}</div>
              <div>{{ `活动描述：${scope.data.activity_desc || ''}` }}</div>
            </div>
          </div>

          <div class="block-item" v-else>
            <!-- <SpImage :src="scope.data.activity_cover" size="small" width="30" height="30" /> -->
            <div class="block">{{ scope.data.activity_title }}</div>
          </div>
        </template>
      </CompTodoList>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import CompTodoList from '../../comps/comp-todoList'
import CompButton from '../../comps/comp-button'
import { login } from '../../../../api/login'
export default {
  name: 'AttrBlock',
  props: {
    value: Object,
    type: String,
    uuid: String
  },
  data() {
    return {
      localValue: {
        data: []
      },
      placeholder: '',
      format: '',
      tabIndex: 0,
      preuuid: ''
    }
  },
  components: { CompButton, CompTodoList },
  watch: {
    localValue: {
      handler: function (nVal) {
        const { data, tabId } = nVal
        const hasSelect = data.find((item) => item.id == tabId)
        if (!hasSelect && data.length > 0) nVal.tabId = data[0].id
        if (data.length == 0) nVal.tabId = ''
        this.$emit('input', cloneDeep(nVal))
      },
      deep: true
    },
    uuid: {
      deep: true,
      handler: function (nVal, oVal) {
        this.localValue = cloneDeep(this.value)
        this.$nextTick(() => {
          this.preuuid = nVal
        })
      },
      immediate: true
    },
    type: {
      handler: function (val) {
        if (this.preuuid && this.uuid == this.preuuid) {
          this.localValue.data = []
        }
        if (val == 'welfare') {
          this.placeholder = '设置福利'
          this.format = '{0}件福利'
        }
        if (val == 'activity') {
          this.placeholder = '设置活动'
          this.format = '{0}次活动'
        }
        if (val == 'service') {
          this.placeholder = '设置服务'
          this.format = '{0}次服务'
        }
        if (val == 'selling') {
          this.placeholder = '设置热卖商品'
          this.format = '{0}件热卖商品'
        }
      },
      immediate: true
    }
  },
  updated() {
    this.isChanged = false
  },
  methods: {
    async handleClickAdd() {
      const params = {
        data: this.value.data,
        multiple: true,
        params: {
          type: this.type
        }
      }
      const { data } = await this.$picker.welfare(params)
      if (data) {
        this.$set(this.localValue, 'data', cloneDeep(data))
      }
    },
    onRemove() {
      this.$set(this.localValue, 'data', [])
    },
    onChangeBlockItem() {}
  }
}
</script>
