import { pickBy } from "@/utils";
import AttrItem from "./attr-item.vue";

export default {
  name: "goodsLift",
  setting: [
    { label: "标题", key: "title", component: "input", value: "商品电梯" },
    {
      label: "副标题",
      key: "subtitle",
      component: "input",
      value: "商品电梯小标题",
    },
    {
      label: "tab底色",
      key: "bg_color",
      labelWidth: "100px",
      component: function (h, { key }) {
        return <el-color-picker show-alpha v-model={this.value[key]} />;
      },
    },
    {
      label: "tab默认色",
      labelWidth: "100px",
      key: "default_color",
      component: function (h, { key }) {
        return <el-color-picker show-alpha v-model={this.value[key]} />;
      },
    },
    {
      label: "tab默认字体色",
      key: "default_color_font",
      labelWidth: "100px",
      component: function (h, { key }) {
        return <el-color-picker show-alpha v-model={this.value[key]} />;
      },
    },
    {
      label: "tab选中色",
      key: "checked_color",
      labelWidth: "100px",
      component: function (h, { key }) {
        return <el-color-picker show-alpha v-model={this.value[key]} />;
      },
    },
    {
      label: "tab选中字体色",
      key: "checked_color_font",
      labelWidth: "100px",
      component: function (h, { key }) {
        return <el-color-picker show-alpha v-model={this.value[key]} />;
      },
    },
    { label: "楼层", key: "floor_var", component: "input" },
    {
      label: "",
      key: "data",
      component: function (h, { key }) {
        return <AttrItem v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: [],
    },
    //   <el-form-item label="tab底色">
    //   <el-color-picker show-alpha v-model="base.bg_color"></el-color-picker>
    // </el-form-item>
  ],
  transformIn: (v) => {
    const { name, base, config, data } = v;
    return {
      name,
      ...base,
      ...config,
      data: data,
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          title: "title",
          subtitle: "subtitle",
          padded: "padded",
          bg_color: "bg_color",
          default_color: "default_color",
          default_color_font: "default_color_font",
          checked_color: "checked_color",
          checked_color_font: "checked_color_font",
          floor_var: "floor_var",
        });
      },
      config: () => {
        return {};
      },
      data: "data",
    });
  },
};
