// 抖音首页模板
import {
  WgtFilm,
  
  WgtHotZone,
  
  WgtblokScroll,
   
  WgtAroundStoreOne,
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  WgtFilm,
  
  WgtHotZone,
  
  WgtblokScroll,
   
  WgtAroundStoreOne,
}


export default {
  widgets,
  initiWidgets
}
