<style lang="scss" scoped>
.service-wrap {
  width: 100%;
  height: auto;
}

.service-item {
  display: flex;
}

.service {
  flex: 1;
  font-size: 14px;
}

.icon-trash-alt1 {
  color: #888;
  font-size: 13px;
}
</style>
<style lang="scss">
.service-item {
  .el-input {
    margin-bottom: 5px;
  }
  .input-m{
    margin-top: 5px;
  }
}
</style>
<template>
  <div class="service-wrap">
    <CompTodoList v-model="localValue" @onAddItem="handleAddTabs">
      <template slot="body" slot-scope="scope">
        <div class="service-item">
          <SpImagePicker v-model="localValue[scope.index].imgUrl" size="small" />
          <div>
            <el-input v-model="localValue[scope.index].maintitle" placeholder="请输入标题" />
            <el-input v-model="localValue[scope.index].subtitle" placeholder="请输入副标题" />
            <el-input v-model="localValue[scope.index].name" placeholder="请输入按钮名称" />
            <CompPickerLink v-model='scope.data' :isShowRadio='false'
              @change='(e) => onChangeLink(e, scope.index)' >
             <el-input v-model="localValue[scope.index].byte_mini_shop_id" placeholder="请填写抖音小店ID" v-if="localValue[scope.index] && localValue[scope.index].id=='douyinStore'" size="small" class="input-m" />
            </CompPickerLink>
          </div>
        </div>
      </template>
    </CompTodoList>
  </div>
</template>

<script>
import Vue from 'vue'
import { cloneDeep } from 'lodash'
import CompPickerLink from '../../comps/comp-pickerLink'
import CompTodoList from '../../comps/comp-todoList'
export default {
  name: 'serviceItem',
  components: { CompTodoList, CompPickerLink },
  props: {
    value: {
      type: Array
    },
    uuid:{
      type:String
    }
  },
  data() {
    return {
      localValue: [],
    }
  },
  computed: {
  },
  watch: {
    localValue: function (nVal, oVal) {
      this.$emit('input', nVal)
    },
    uuid: {
      handler(val) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true,
      deep: true
    }
  },
  created() {
  },
  methods: {
    async handleAddTabs() {
      this.localValue = this.localValue.concat([{ imgUrl: "", maintitle: "", subtitle: "", name: "", id:'',linkPage:'',title:'' }]
      )
    },
    onChangeLink(e, index) {
      const oldVal = this.localValue[index]
      this.$set(this.localValue, index,Object.assign(oldVal, e))
    }
  }
}
</script>
