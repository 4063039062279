import { pickBy } from "@/utils";
import ServiceItem from "./service-item.vue";

export default {
  name: "aroundService",
  setting: [
    { label: "标题", key: "title", component: "input", value: "标题" },
    { label: "副标题", key: "subtitle", component: "input", value: "副标题" },
    { label: "楼层", key: "floor_var", component: "input", value: "" },
    { label: "组件间距", key: "padded", component: "switch", value: true },
    {
      label: "服务",
      key: "data",
      component: function (h, { key }) {
        return <ServiceItem v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: [{imgUrl: "", maintitle: "", subtitle: "", name: "", id:'',linkPage:'',title:''}],
    },
  ],
  transformIn: (v) => {
    const { name, base, config, data=[] } = v;
    return {
      name,
      ...base,
      ...config,
      data:data.map(item=>{
        const {moreLink,title,...other} = item
        return {...moreLink,...other,maintitle:title}
      })
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          title: "title",
          subtitle: "subtitle",
          floor_var: "floor_var",
          padded: "padded",
        });
      },
      config: () => {
        return {
          style: 1,
        };
      },
      data: ({ data }) => {
        return data.map(item=>{
          const {imgUrl,maintitle,subtitle,...moreLink} = item
          return {imgUrl,title:maintitle,subtitle,moreLink}
        })
      },
    });
  },
};
