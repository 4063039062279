import SCENE_1001 from './scene_1001'
import SCENE_1002 from './scene_1002'
import SCENE_1003 from './scene_1003'
import SCENE_1005 from './scene_1005'
import SCENE_1006 from './scene_1006'
import SCENE_1007 from './scene_1007'
import SCENE_1008 from './scene_1008'
import SCENE_1009 from './scene_1009'
import SCENE_1010 from './scene_1010'
import SCENE_1011 from './scene_1011'
import SCENE_1012 from './scene_1012'


export const gWgts = {
  1001: SCENE_1001,
  1002: SCENE_1002,
  1003: SCENE_1003,
  1005: SCENE_1005,
  1006: SCENE_1006,
  1007: SCENE_1007,
  1008: SCENE_1008,
  1009: SCENE_1009,
  1010: SCENE_1010,
  1011: SCENE_1011,
  1012: SCENE_1012,
}

export default {}

