<template>
  <div :class="{
    'wgt-tabsCut': true,
    'padded': value.padded
  }" draggable="false">
    <div v-if="value.title || value.subtitle" class="wgt-hd">
      <span class="title">{{ value.title }}</span>
      <span class="sub-title">{{ value.subtitle }}</span>
    </div>
    <div class="wgt-bd">
      <div v-for="(item, index) in value.data" :key="index" class="tab-item">
        <div class="near-store" v-if="item.isShowNearStore == 'show'" :style="nearStoreStyle(item)">
          <div class="near-store-item">前往&nbsp;<span class="store-name">国贸三期店</span>&nbsp;获取帮助 </div>
          <div class="near-store-item">离你&nbsp;<span class="store-name">1.3&nbsp;km</span></div>
        </div>

        <div class="img-hotzone" v-for="(em, ex) in item.hotzoneList" :key="'index' + ex">
          <img :src="em.imgUrl" alt="" />
        </div>
        <div class="img-hotzone">
          <img :src="item.fixedBtnImg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: "TabsCut",
  wgtName: 'Tabs切换',
  wgtIcon: 'wgt-tabsCut',
  wgtType: 1,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {}
  },
  methods: {
    nearStoreStyle(style) {
      return {
        top: style.nearStroeTop + 'px',
        width: style.nearStoreWidth + 'px',
      }
    }
  }
}
</script>

<style lang="scss">
.wgt-tabsCut {
  &.padded {
    padding: 10px 0;
  }

  .wgt-hd {
    padding: 10px;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }
  }

  .wgt-bd {
    .img-hotzone {
      position: relative;

      img {
        width: 100%;
        user-select: none;
        -webkit-user-drag: none;
        -webkit-touch-callout: none;
        display: block
      }

      .zone {
        position: absolute;
        cursor: pointer;
      }
    }
  }

  .tab-item {
    position: relative;

    .near-store {
      position: absolute;
      border-radius: 5px;
      background: #F7F9FF;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 5px 10px;
      left: 50%;
      transform: translateX(-50%);
      

      &-item {
        font-size: 10.5px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        color: rgba(29, 29, 31, 1);
      }

      .store-name {
        color: #687BF2;
      }
    }
  }
}
</style>