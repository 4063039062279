// 抖音自定义页面装修
import {
  
  WgtNewSlider,
  WgtHotZone,
  WgtFilm,
  
  WgtWallPaper,
  WgtFooterNav,
  WgtAroundStoreOne
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  
  WgtNewSlider,
  WgtHotZone,
  WgtFilm,
  
  WgtWallPaper,
  WgtFooterNav,
  WgtAroundStoreOne
}


export default {
  widgets,
  initiWidgets
}
