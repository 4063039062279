import AttrItem from "./attr-item.vue";
import { pickBy } from '@/utils'
export default {
  name: "wallPaper",
  setting: [
    { label: "标题", key: "title", component: "input", value: "壁纸标题" },
    { label: "分类标签", key: "subtitle", component: "input", value: "" },
    { label: "标签颜色", key: "color", component: "color", value: "#000" },
    {
      label: "壁纸设置",
      key: "data",
      component: function(h, { key }){
        return <AttrItem v-model={this.value[key]} uuid={this.value.uuid}/>;
      },
      value:[]
    },
  ],
  transformIn: (v) => {
    const { name, base, data=[] } = v;
    return {
      name,
      ...base,
      data,
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v,{
          title: 'title',
          subtitle: 'subtitle',
          padded: true,
          color:'color'
        })
      },
      data: 'data'
    });
  },
};
