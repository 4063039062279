var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"newSlider",class:{
  'wgt-new-slider': true,
  'padded': _vm.value.padded,
  'no-cover': !_vm.value.dotCover
}},[(_vm.refresh)?_c('div',{staticClass:"wgt-bd",class:{ 'spaced': _vm.value.spaced },style:(_vm.setCarouselHeight)},[_c('el-carousel',{staticClass:"slider-container",attrs:{"arrow":"never","indicator-position":"none","interval":_vm.value.interval,"autoplay":_vm.value.autoplay},on:{"change":_vm.changeSlider}},_vm._l((_vm.value.data),function(item,index){return _c('el-carousel-item',{key:`carousel_${index}`},[_c('div',{staticClass:"slider-item",style:(`
           border-radius:${_vm.value.rounded.upleft || 0}px ${_vm.value.rounded.upright || 0}px ${_vm.value.rounded.bottomright || 0}px ${_vm.value.rounded.bottomleft || 0}px;
           marginLeft:${_vm.value.imgpadded.paddedl || 0}px;marginRight:${_vm.value.imgpadded.paddedr || 0}px
        `)},[(!item.videoUrl || item.media_type !== 'video')?_c('SpImage',{staticClass:"image-item-img",attrs:{"src":item.imgUrl,"fit":"contain"}}):_vm._e(),(item.media_type == 'video' && item.videoUrl)?_c('video',{ref:`swiperVideo_${index}`,refInFor:true,staticClass:"image-item-video",attrs:{"poster":item.imgUrl,"loop":item.loop,"muted":"muted","objectFit":"cover"},domProps:{"muted":true}},[_c('source',{attrs:{"src":item.videoUrl}})]):_vm._e(),(item.overlay && !_vm.value.overlayroll)?_c('SpImage',{staticClass:"overlay-item",style:(_vm.overlayStyle(item, index)),attrs:{"src":item.overlay,"fit":"contain"}}):_vm._e()],1)])}),1),_vm._l((_vm.value.data),function(item,index){return (_vm.value.overlayroll && item.overlay)?_c('div',{key:`overlay_${index}`,staticClass:"overlay-content",style:(_vm.overlayStyle(item, index))},[_c('SpImage',{staticClass:"over-lay",class:{
        'transparent-transition': _vm.currentIndex !== index,
        'transparent-transition-active': _vm.currentIndex == index
      },attrs:{"src":item.overlay,"fit":"contain"}})],1):_vm._e()}),_c('div',{staticClass:"el-carousel_indicator",class:{
      'left': _vm.value.dotLocation == 'left',
      'right': _vm.value.dotLocation == 'right',
    }},[_c('div',{staticClass:"default-dot dot-circle",style:(_vm.setdotPosition())},_vm._l((_vm.value.data),function(item,index){return (!_vm.value.dot_type)?_c('p',{key:index,staticClass:"circle",style:(_vm.setcolor(item, index))}):_vm._e()}),0),(_vm.value.dot_type == 'number')?_c('p',{staticClass:"dot-text default-dot",style:(_vm.setnumbercolor(_vm.value.data[_vm.currentIndex]))},[_c('span',[_vm._v(_vm._s(_vm.currentIndex + 1))]),_vm._v("/"+_vm._s(_vm.value.data.length)+" ")]):_vm._e(),(_vm.value.dot_type == 'line')?_c('div',{staticClass:"progress default-dot",style:(_vm.setdotPosition())},_vm._l((_vm.value.data),function(item,index){return _c('div',{key:`overlay__${index}`,staticClass:"progress-bar",style:(_vm.setDefaultColor(item))},[_c('p',{class:{
            'progress-bar-default': true,
            'progress-bar-active': _vm.currentIndex >= index
          },style:(_vm.setBgColor(item, index))})])}),0):_vm._e()])],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }