import { pickBy } from "@/utils";
import CompHotSetting from "../../comps/comp_hotSetting";
import { isEmpty } from "lodash";
import AttrChannel from "./attr-channel.vue";

export default {
  name: "imgHotzone",
  setting: [
    // { label: "标题", key: "title", component: "input", value: "热区图" },
    // {
    //   label: "副标题",
    //   key: "subtitle",
    //   component: "input",
    //   value: "图上随意画块块",
    // },
    { label: "楼层", key: "floor_var", component: "input" },
    { label: "组件间距", key: "padded", component: "switch", value: true },
    {
      label: "挂件可见用户",
      key: "visible",
      component: "checkbox",
      options: [
        { name: "非会员", label: "one" },
        { name: "会员", label: "two" },
      ],
      value: ["one", "two"],
      onchange: (val, that) => {
        if (!val.includes('two')) {
          that.$set(that.value, 'channelType', 'all');
        }
      },
      isShow: function (v) {
        const hasRegister = this.value.data.data.some((v) => v.id === "register");
        return !hasRegister;
      },
      min: 1
    }, {
      label: "勾选会员展示",
      key: "channelType",
      component: 'radio',
      options: [
        { name: "全部渠道", label: "all" },
        { name: "指定渠道", label: "appoint" },
      ],
      value: 'all',
      isShow: function (v) {
        const hasRegister = this.value.data.data.some((v) => v.id === "register");
        return this.value.visible?.some((v) => v === "two") && !hasRegister;
      },
      onchange: (val, that) => {
        that.$set(that.value, 'channel', []);
      },
    }, {
      label: "选择指定渠道展示",
      key: "channel",
      component: function (h, { key }) {
        return (
          <AttrChannel v-model={this.value[key]} uuid={this.value.uuid} channel-type={this.value.channelType} />
        );
      },
      value: [],
      isShow: function () {
        return this.value.visible?.some((v) => v === "two") && this.value.channelType == 'appoint';
      },
    },
    {
      label: "热区设置",
      key: "data",
      component: function (h, { key }) {
        const visible = this.value.visible?.some((v) => v === "one")
        return (
          <CompHotSetting visible={visible} v-model={this.value[key]} uuid={this.value.uuid} padded={this.value.padded} on-change={(e) => {
            const hasRegister = e.data?.some((v) => v.id === "register");
            if (hasRegister) {
              this.$set(this.value, 'visible', ['one', 'two']);
              this.$set(this.value, 'channelType', 'all');
              that.$set(that.value, 'channel', []);
            }
          }} />
        );
      },
      value: { imgUrl: "", data: [] },
    },
  ],
  transformIn: (v) => {
    const {
      name,
      base:{flowName_var,...base},
      config: { imgUrl, imgName,visible=['one','two'],channelType='all',channel=[], ...config },
      data,
    } = v;
    return {
      name,
      ...base,
      ...config,
      visible,
      channelType,
      channel,
      data: {
        imgUrl,
        flowName_var,
        data,
      },
    };
  },
  transformOut: (v) => {
    const { data: { flowName_var, imgUrl, data }, floor_var } = v
    if (!floor_var) {
      throw '[挂件]热区图 请输入楼层';
    }
    if (imgUrl && !flowName_var) {
      throw '[挂件]热区图 请输入热区设置-资源位名称';
    }
    for (let i in data) {
      if (!data[i].imgName) {
        throw '[挂件]热区图 请输入热区设置-热区名称';
      }
    }

    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          padded: "padded",
          floor_var: "floor_var",
          flowName_var: "data.flowName_var",
        });
      },
      config: (v) => {
        return pickBy(v, {
          imgUrl: "data.imgUrl",
          visible: "visible",
          channelType: "channelType",
          channel: "channel",
        });
      },
      data: "data.data",
    });
  },
};
