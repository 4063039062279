import { pickBy, isBoolean } from "@/utils";
import AttrItem from "./attr-item.vue";
import AttrColor from "./attr-color.vue"

export default {
  name: "stickyLift",
  setting: [
    { label: "标题", key: "title", component: "input", value: "电梯" },
    {
      label: "副标题",
      key: "subtitle",
      component: "input",
      value: "电梯小标题",
    },
    {
      label: "Tab样式",
      key: "style",
      labelWidth: "100px",
      component: function (h, { key }) {
        return <AttrColor v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: {
        bg_color: "#1d1d1f",
        default_color: "#1d1d1f",
        default_color_font: "#888888",
        checked_color: "#1d1d1f",
        checked_color_font: "#ffffff",
      },
    },
    // { label: "组件间距", key: "padded", component: "switch", value: false },
    {
      label: "楼层",
      key: "floor_var",
      component: "input",
      value: "",
    },
    {
      label: "",
      labelWidth: "100px",
      key: "data",
      component: function (h, { key }) {
        return <AttrItem v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: [],
    },
    //   <el-form-item label="tab底色">
    //   <el-color-picker show-alpha v-model="base.bg_color"></el-color-picker>
    // </el-form-item>
  ],
  transformIn: (v) => {
    const {
      name,
      base: {
        bg_color,
        default_color,
        default_color_font,
        checked_color,
        checked_color_font,
        ...base
      },
      config,
      data,
    } = v;
    return {
      name,
      style: {
        bg_color,
        default_color,
        default_color_font,
        checked_color,
        checked_color_font,
      },
      ...base,
      ...config,
      data: data.map(
        ({
          title,
          longImgUrl,
          longData,
          shortBotData,
          shortBotImgUrl,
          data,
          imgUrl,
          horizontal = 0,
          defaultzone = 1,
          hotzone = 2,
          children = [],
        }) => {
          const datas = [];
          datas[horizontal] = longImgUrl
            ? {
                type: "horizontal",
                data: longData,
                imgUrl: longImgUrl,
              }
            : null;

            datas[hotzone] = shortBotImgUrl?{
              type: "hotzone",
              data: shortBotData,
              imgUrl: shortBotImgUrl,
            }:null;
            datas[defaultzone] = imgUrl?{
              type: "defaultzone",
              data,
              imgUrl,
            }:null;
           let arr = datas.filter(item=> !!item)
           children.map(item=>{
            if(!isBoolean(item.showProgress)){
              item.showProgress = true;
            }
            return item
           })
          return {
            title,
            children: [...arr, ...children],
          };
        }
      ),
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          title: "title",
          subtitle: "subtitle",
          padded: false,
          bg_color: "style.bg_color",
          default_color: "style.default_color",
          default_color_font: "style.default_color_font",
          checked_color: "style.checked_color",
          checked_color_font: "style.checked_color_font",
          floor_var: "floor_var",
        });
      },
      config: () => {
        return {};
      },
      data: ({ data }) => {
        return data;
      },
    });
  },
};
