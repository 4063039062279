<style lang="scss" scoped>
.wgt-footer-nav {
  &.padded {
    padding: 10px 0;
  }

  .wgt-bd {
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  button {
    background-repeat: no-repeat;
    background-size: cover;
    height: 40px;
  }
}
</style>
<template>
  <div :class="{
    'wgt-footer-nav': true,
    'padded': value.padded
  }" :style="{ backgroundColor: value.backgroundColor }">
    <div class="wgt-bd">
      <!-- 挂件自定义部分 -->
      <el-button
        :style="`backgroundColor: ${value.background};color:${value.color};width:${value.width}px;backgroundImage:url(${value.imgUrl})`"
        round>{{ value.text }}</el-button>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'Footer_nav',
  wgtName: '底部导航',
  wgtDesc: '',
  wgtIcon: 'wgt-footer_nav',
  wgtType: 1,
  wgtTem: 0,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
    }
  },
  created() { },
  methods: {}
}
</script>
