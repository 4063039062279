<template>
  <div class="wgt_productShelves_config-attr_content">
    <el-divider @click="handleClick">个性化设置<i :class="{
      'el-icon-d-arrow-right':true,
      'arrow-up':showMore,
      'arrow-down':!showMore
      }"></i></el-divider>
    <div v-for="(item, index) in localValue" :key="`goods_config${index}`" class="attr-item_content-item"
      v-if="showMore">
      <p class="item-title">商品{{ index + 1 }}样式</p>
      <SettingStyle :value="localValue[index]" :uuid="`${uuid}_${index}`" :shelfType="shelfType"
        :title="`商品${index + 1}样式`" @change="handlechangeSetting(index, $event)" :independent="true" />
    </div>
  </div>
</template>

<script>
import SettingStyle from "./setting-style.vue";
export default {
  components: { SettingStyle },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    uuid: {
      type: String,
      default: ''
    },
    shelfType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: [],
      showMore: true
    }
  },
  methods: {
    handlechangeSetting(index, e) {
      this.$set(this.localValue, index, e)
      this.$emit('input', this.localValue)
    },
    handleClick() {
      this.showMore = !this.showMore
    }
  },
  watch: {
    uuid: {
      handler(val) {
        this.localValue = this.value
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.wgt_productShelves_config-attr_content {
  margin-left: -80px;

  .arrow-up {
    transform: rotate(-90deg);
  }
  .arrow-down{
    transform: rotate(90deg);
  }

  .attr-item_content-item {
    display: flex;

    .item-title {
      width: 80px;
      color: #888;
      margin-top: 6px;
    }
  }
}
</style>
<style lang="scss">
.wgt_productShelves_config-attr_content {
  .el-divider__text {
    background-color: #f4f7ff;
  }
}
</style>