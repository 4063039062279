<template>
  <div class="attr-item">
    <el-select v-model="localValue" placeholder="请选择">
      <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

export default {
  components: {},
  props: {
    uuid: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  watch: {
    uuid: {
      immediate: true,
      handler(newVal) {
      }
    },
    localValue: {
      deep: true,
      handler(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  data() {
    return {
      localValue: null,
      list: [{
          value: 'cart',
          label: '购物车数据'
        }]
    }
  },
  created() {
    this.localValue = cloneDeep(this.value)
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped></style>