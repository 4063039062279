import { render, staticRenderFns } from "./nav-setting.vue?vue&type=template&id=5df759df&scoped=true"
import script from "./nav-setting.vue?vue&type=script&lang=js"
export * from "./nav-setting.vue?vue&type=script&lang=js"
import style1 from "./nav-setting.vue?vue&type=style&index=1&id=5df759df&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5df759df",
  null
  
)

export default component.exports