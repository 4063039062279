<template>
  <div class="comps-radius">
    <div class="comps-radius-item" v-if="showUpleft">
      <span>上左</span>
      <el-input-number size="mini" :min="0" controls-position="right" v-model="localValue.upleft" />
    </div>
    <div class="comps-radius-item" v-if="showUpright">
      <span>上右</span>
      <el-input-number size="mini" :min="0" controls-position="right" v-model="localValue.upright" />
    </div>
    <div class="comps-radius-item" v-if="showBottomleft">
      <span>下左</span>
      <el-input-number size="mini" :min="0" controls-position="right" v-model="localValue.bottomleft" />
    </div>
    <div class="comps-radius-item" v-if="showBottomright">
      <span>下右</span>
      <el-input-number size="mini" :min="0" controls-position="right" v-model="localValue.bottomright" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showUpleft: {
      type: Boolean,
      default: false
    },
    showUpright: {
      type: Boolean,
      default: false
    },
    showBottomleft: {
      type: Boolean,
      default: false
    },
    showBottomright: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      localValue: {
        upleft: 0,
        upright: 0,
        bottomleft: 0,
        bottomright: 0
      },
    }
  },
  watch: {
    uuid: {
      handler(val) {
        this.localValue = this.value;
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.comps-radius {
  display: flex;
  flex-wrap: wrap;

  &-item {
    width: 50%;
    display: flex;
    align-items: center;
    padding-right: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;

    span {
      width: 30px;
      margin-right: 10px;
      font-size: 12px;
    }
  }
}
</style>