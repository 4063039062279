<template>
  <div class="wgt-image-text-content" :style="setstyle">
    <div v-for="(item, index) in value?.data" :key="index" class="image-text-content">
      <!-- <span class="image-text-type" v-if="item.type == 'pic'">文章</span> -->
      <div class="image-content">
        <el-image class="image-content-item" :src="item.url" fit="cover" v-if="item.type == 'pic' && item.url" />
        <el-image class="image-content-item" v-if="item.type == 'video' && item.data.cover_pic"
          :src="item.data.cover_pic" />
        <div class='image-text-type-video' v-if="item.type == 'video'">
          <el-image class='image-text-type-video-bg' src='https://cdn-image.tjtjshengtu.com/xcximage/play.png' />
        </div>
      </div>
      <p class="image-text-title">
        <span>
          {{ item.title }}
        </span>
      </p>
    </div>
  </div>
</template>


<script>
import config from './config'
export default {
  name: 'ImageText',
  wgtName: '图文',
  wgtDesc: '',
  wgtIcon: 'wgt-image-text',
  wgtType: 1,
  config,
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getOptions(src) {
      return {
        aspectRatio: '16:9',
        fluid: true,
        sources: [
          {
            type: 'video/mp4',
            src
          }
        ],
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: false
      }
    },
  },
  computed: {
    setstyle() {
      return {
        height: this.value?.height + 'px',
        padding: this.value?.padded.paddedt + 'px ' + this.value?.padded.paddedr + 'px ' + this.value?.padded.paddedb + 'px ' + this.value?.padded.paddedl + 'px',
        backgroundColor: this.value?.background.color || 'transparent',
        backgroundImage: `url(${this.value?.background.imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }
    }
  }
}

</script>


<style lang="scss" scoped>
.wgt-image-text-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .image-text-content {
    height: 274px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 15px;
    background-color: #fff;
    margin-bottom: 10px;
    position: relative;



    .image-content {
      position: relative;
      float: left;
      flex: 1;
      overflow: hidden;
      background-color: #f5f7fa;

      .image-content-item {
        background-color: #f5f7fa;
        height: 100%;
        width: 100%;
      }

      .image-text-type-video {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 9;
        background-color: #000;
        border-radius: 100%;
        width: 26px !important;
        height: 26px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        &-bg {
          width: 12px !important;
          height: 12px !important;
        }
      }
    }

    .image-text-title {
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 20px;
      color: rgba(29, 29, 31, 1);
      box-sizing: border-box;
      padding: 22px 16px;
      font-size: 15px;
      height: 84px;
      max-height: 84px;
      font-size: 13px;
      box-sizing: border-box;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
