<style lang="scss" scoped>
.select-custom {
  .custom-info {
    font-size: 14px;
    margin-top: 6px;
    display: flex;

    &-item {
      padding-right: 12px;
    }
  }
}
</style>
<template>
  <div class="select-custom">
    <CompButton placeholder="添加模块" format="{0}个模块" :value="pages_template_id ? 1 : 0" :view-btn="false"
      @click="handleAddCustom" @remove="handleDelCustom" />
    <div class="custom-info">
      <div class="custom-info-item">
        <span class="title">id:</span>
        {{ pages_template_id }}
      </div>
      <div>
        <span class="title">模块名称:</span>
        {{ template_title }}
      </div>
    </div>
  </div>
</template>

<script>
import { getPagesTemplateDetail } from '@/api/template'
import CompButton from '../../comps/comp-button'
import { gWgts } from '../../scene'
import { cloneDeep } from 'lodash'
import { transformSelectTem, getWgts } from "../../utils"
export default {
  name: 'SelectCustom',
  components: { CompButton },
  props: {
    value: {
      type: Array
    },
    pages_template_id: {
      type: String
    },
    template_title: {
      type: String
    }
  },
  data() {
    return {
      localValue: null
    }
  },
  created() {
    this.localValue = cloneDeep(this.value)
  },
  methods: {
    async handleDelCustom() {
      this.localValue = null
      this.$emit('input', this.localValue)
    },
    async handleAddCustom() {
      let params = {
        multiple: false,
        data: this.pages_template_id ? [this.pages_template_id] : []
      }
      const { data } = await this.$picker.customWgt(params)
      this.$emit('onchange', { pages_template_id: data[0].pages_template_id, template_title: data[0].template_title })
    }
  }
}
</script>
