import Vue from "vue";
import { pickBy, isObject } from "@/utils";
import { sliderproportion } from "@/consts";
import { getImageAttribute, getVideoAttribute } from "./utils";
import AttrItem from "./attr-item.vue";
import CompColor from "../../comps/comp-color";
import CompsRadius from "../../comps/comps-radius";
import CompsStyle from "../../comps/comps-style.vue";
import ApiSelect from "./api_select.vue";

export default {
  name: "newSlider",
  setting: [
    { label: "楼层", key: "floor_var", component: "input" },
    {
      label: "组件间距",
      key: "padded",
      component: "switch",
      value: false,
    },
    {
      label: "比例",
      key: "proportion",
      component: function (h, { key }) {
        return (
          <el-radio-group
            v-model={this.value[key]}
            on-change={(e) => {
              if (e == 1) {
                this.value.height = 667;
                return;
              } else {
                if (this.value.data.length == 0) {
                  this.value.height = 375;
                } else {
                  if (
                    this.value.data[0].media_type == "video" &&
                    this.value.data[0].videoUrl
                  ) {
                    getVideoAttribute(this.value.data[0].videoUrl, (h) => {
                      this.value.height = h;
                    });
                  } else {
                    getImageAttribute(this.value.data[0].imgUrl, (h) => {
                      this.value.height = h;
                    });
                  }
                }
              }
            }}
          >
            {sliderproportion.map((op) => (
              <el-radio label={op.label}>{op.name}</el-radio>
            ))}
          </el-radio-group>
        );
      },
      value: 0,
    },
    // {
    //   label: "挂件背景色",
    //   key: "back_color",
    //   component: "color",
    //   value: "#000",
    // },
    {
      label: "覆盖",
      key: "dotCover",
      component: "switch",
      value: true,
      module: "dot",
    },
    {
      label: "类型",
      key: "dot_type",
      component: "radiobutton",
      options: [
        { name: "点位", label: "" },
        { name: "线位 ", label: "line" },
        { name: "数字 ", label: "number" },
      ],
      value: "",
      module: "dot",
    },
    {
      label: "颜色",
      key: "pilot",
      component: function (h, { key }) {
        return <CompColor v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: {
        dot_noCover: "#000",
        dot_cover: "#fff",
      },
      module: "dot",
    },
    {
      label: "左边间距",
      key: "dotleft",
      component: "slider",
      showInput:true,
      value: 50,
      tip: "单位为 %",
      module: "dot",
    },
    {
      label: "下边间距",
      key: "dotbottom",
      component: "slider",
      showInput:true,
      value: 10,
      tip: "单位为 px",
      module: "dot",
    },

    {
      label: "自动切换",
      key: "autoplay",
      component: "switch",
      value: true,
      module: "slidersetting",
    },
    {
      label: "轮播时间",
      key: "interval",
      component: "number",
      isShow: function () {
        return this.value.autoplay;
      },
      value: 3000,
      min: 1000,
      max: 50000,
      step: 1000,
      module: "slidersetting",
    },
    {
      label: "圆角图片",
      key: "rounded",
      component: "switch",
      value: {
        upleft: 0,
        upright: 0,
        bottomleft: 0,
        bottomright: 0,
      },
      module: "slidersetting",
      component: function (h, { key }) {
        return (
          <CompsRadius
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showUpleft
            showUpright
            showBottomleft
            showBottomright
          />
        );
      },
    },
    {
      label: "轮播项间距",
      key: "imgpadded",
      value: {
        left: 0,
        right: 0,
      },
      module: "slidersetting",
      component: function (h, { key }) {
        return (
          <CompsStyle
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showTop={false}
            showBottom={false}
            showLabel={false}
            showBgsetting={false}
          />
        );
      },
    },
    {
      label: "叠层",
      key: "overlayroll",
      component: "radiobutton",
      options: [
        { name: "跟随图片", label: "" },
        { name: "固定 ", label: "fixed" },
      ],
      value: "",
      module: "slidersetting",
    },
    {
      label: "渐变时间",
      key: "trans",
      component: "number",
      isShow: function () {
        return this.value.overlayroll;
      },
      value: 0.6,
      min: 0,
      step: 0.1,
      module: "slidersetting",
    },
    {
      label: "",
      key: "height",
      component: "input",
      value: 375,
      isShow: function () {
        return false;
      },
    },
    {
      label: "组件类型",
      key: "comTypes",
      component: "radiobutton",
      options: [
        { name: "常规组件", label: "" },
        { name: "智能组件 ", label: "ai" },
      ],
      value: "",
      module: "slideritem",
    },
    {
      label: "资源位",
      key: "resourceBit",
      component: function (h, { key }) {
        return (<ApiSelect v-model={this.value[key]} uuid={this.value.uuid} comTypes={this.value.comTypes}/>)
      },
      isShow: function () {
        return this.value.comTypes == "ai";
      },
      value: "",
      module: "slideritem",
    },
    {
      label: "",
      key: "data",
      component: function (h, { key }) {
        return (
          <AttrItem
            v-model={this.value[key]}
            uuid={this.value.uuid}
            proportion={this.value.proportion}
            // backColor={this.value.back_color}
            comTypes={this.value.comTypes}
            on-changeheight={(e) => {
              this.value.height = e;
            }}
            height={this.value.height}
          />
        );
      },
      value: [],
      module: "slideritem",
    },
  ],
  transformIn: (v) => {
    const {
      name,
      base,
      config: { dot_noCover, dot_cover,imgpadded, ...otherConfig },
      data,
    } = v;
    return {
      name,
      ...base,
      ...otherConfig,
      pilot: {
        dot_noCover,
        dot_cover,
      },
      imgpadded:{
        paddedl:imgpadded&&imgpadded.left,
        paddedr:imgpadded&&imgpadded.right
      },
      data: data.map((item) => {
        const {
          linkPage,
          pic_type = "",
          title,
          ctoekn,
          id,
          hotData = [],
          videoUrl,
          overlayHotData = [],
          autoplay,
          interact,
          overlay,
          overlayWidth,
          overlaybuttom,
          overlayLeft,
          dot_noCover,
          dot_cover,
          isCustomSlider,
          loop,
          imageName,
          ...content
        } = item;
        const url = isObject(videoUrl) ? videoUrl?.url : videoUrl;
        return {
          data: {
            linkPage: linkPage,
            ctoekn,
            id: id,
            title: title,
          },
          pic_type,
          hotData,
          videoUrl: url,
          autoplay,
          interact,
          overlay,
          overlayWidth,
          overlaybuttom,
          overlayLeft,
          dot_noCover,
          dot_cover,
          overlayHotData,
          isCustomSlider,
          loop,
          imageName,
          ...content,
        };
      }),
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          floor_var: "floor_var",
          padded: "padded",
          proportion: "proportion",
          // back_color: "back_color",
        });
      },
      config: (v) => {
        return pickBy(v, {
          interval: "interval",
          dot_type: "dot_type",
          dot_noCover: "pilot.dot_noCover",
          dot_cover: "pilot.dot_cover",
          rounded: "rounded",
          imgpadded: (v)=>{
            return pickBy(v, {
              left: "imgpadded.paddedl",
              right: "imgpadded.paddedr",
            })
          },
          overlayroll: "overlayroll",
          dot: "dot",
          dotCover: "dotCover",
          dotbottom: "dotbottom",
          dotleft: "dotleft",
          shape: "shape",
          content: "content",
          autoplay: "autoplay",
          height: "height",
          trans:'trans',
          comTypes:'comTypes',
          resourceBit:'resourceBit'
        });
      },
      data: ({ data }) => {
        return pickBy(data, {
          imgUrl: "imgUrl",
          pic_type: "pic_type",
          videoUrl: "videoUrl",
          subtitle: "subtitle",
          aiId:'aiId',
          subtitleTow: "subtitleTow",
          button: "button",
          template: "template",
          media_type: "media_type",
          linkPage: "data.linkPage",
          ctoken: "data.ctoken",
          id: "data.id",
          title: "data.title",
          hotData: "hotData",
          autoplay: "autoplay",
          interact: "interact",
          overlay: "overlay",
          overlayWidth: "overlayWidth",
          overlaybuttom: "overlaybuttom",
          overlayLeft: "overlayLeft",
          dot_noCover: "dot_noCover",
          dot_cover: "dot_cover",
          overlayHotData: "overlayHotData",
          isCustomSlider: "isCustomSlider",
          loop: "loop",
          imageName:'imageName'
        });
      },
    });
  },
};
