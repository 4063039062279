<template>
  <div :class="{
    'wgt-storeRuleList': true,
    'padded': value.padded
  }" draggable="false">
    <div class="wgt-hd">
      <span class="title">门店规则列表</span>
    </div>
    <div class="wgt-bd">
      <div class="rule-list">
        <div class="rule-item" v-for="(item, index) of [{},{}]" :key="index">
          <div class="rule-item-left">
            <div class="bg"></div>
          </div>
          <div class="rule-item-right">
            <p class="store-name">店铺名称</p>
            <p class="address">店铺位置</p>
            <p class="distance_text">店铺距离</p>
            <p class="rule-item-label">
              <span>活动标签</span>
              <span>活动标签</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: "StoreRuleList",
  wgtName: '门店规则列表',
  wgtIcon: 'wgt-storeRuleList',
  wgtType: 1,
  wgtTem:1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>

<style lang="scss">
.wgt-storeRuleList {
  &.padded {
    padding: 10px 0;
  }

  .wgt-hd {
    padding: 10px;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }
  }

  .wgt-bd {
    .rule-list {
      .rule-item {
        display: flex;
        margin-bottom: 10px;
        &-left{
          width: 80px;
          .bg{
            width: 60px;
            height: 60px;
            margin: 10px;
            background: #929292;
          }
        }
        &-right{
          flex: 1;
          p{

          }
          .store-name{
            color: #000;
            font-size: 18px;
          }
          .address{
            color: #929292;
            line-height: 14px;
          }
          .distance_text{
            color: #000;
            font-size: 12px;
          }
          .rule-item-label{
            span{
              display: inline-block;
              height: 20px;
              line-height: 20px;
              border: 1px solid #1d1d1f;
              color: #1d1d1f;
              font-size: 10px;
              margin-right: 5px;
              padding: 0px 5px;
            }
          }
        }
      }
    }
  }
}
</style>