<template>
  <div class="product-shelves-marketing-info">
    <el-row class="marketing-info-content" :gutter="20">
      <el-col :span="12" class="marketing-info-item" v-if="localValue.independent !== undefined">
        <span class="label">独立样式</span>
        <el-switch v-model="localValue.independent" />
      </el-col>
      <el-col v-if="type != 'bgSetting'" :span="localValue.independent !== undefined ? 12 : 24" class="marketing-info-item">
        <span class="label">是否展示</span>
        <el-switch v-model="localValue.open" :disabled="isDisbaled" />
      </el-col>
    </el-row>
    <!-- v-if="!(isDisbaled&&localValue.independent !== undefined)" -->
    <el-row class="marketing-info-content" :gutter="10" v-if="type != 'bgSetting'">
      <el-col :span="12" class="marketing-info-item">
        <span class="label">字体大小</span>
        <el-input-number v-model="localValue.fontSize" placeholder="像素" :min="10" controls-position="right" size="mini"
          :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item">
        <span class="label">字体颜色</span>
        <el-color-picker v-model='localValue.fontColor' size='small' :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item">
        <span class="label">下间距</span>
        <el-input-number v-model="localValue.maginbtm" placeholder="像素" :min="0" controls-position="right" size="mini"
          :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item">
        <span class="label">背景色</span>
        <el-color-picker v-model='localValue.bgColor' size='small' :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item">
        <span class="label">上间距</span>
        <el-input-number v-model="localValue.magintop" placeholder="像素" :min="0" controls-position="right" size="mini"
          :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item">
        <span class="label">上下内边距</span>
        <el-input-number v-model="localValue.paddedhor" placeholder="像素" :min="0" controls-position="right" size="mini"
          :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item">
        <span class="label">左右内边距</span>
        <el-input-number v-model="localValue.paddedve" placeholder="像素" :min="0" controls-position="right" size="mini"
          :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item">
        <span class="label">边框粗细</span>
        <el-input-number v-model="localValue.borderWidth" placeholder="像素" :min="0" controls-position="right"
          size="mini" :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item">
        <span class="label">边框圆角</span>
        <el-input-number v-model="localValue.borderRadius" placeholder="像素" :min="0" controls-position="right"
          size="mini" :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item">
        <span class="label">边框颜色</span>
        <el-color-picker v-model='localValue.borderColor' size='small' :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item" v-if="type === 'discount'">
        <span class="label">文字划线</span>
        <el-switch v-model="localValue.line" :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item" v-if="type === 'btn'">
        <span class="label">右上悬浮</span>
        <el-switch v-model="localValue.suspension" :disabled="isDisbaled" />
      </el-col>
      <el-col :span="12" class="marketing-info-item" v-if="type === 'title' || type === 'salePrice'">
        <span class="label">字体加粗</span>
        <el-switch size="mini" v-model="localValue.bold" :disabled="isDisbaled" />
      </el-col>
    </el-row>
    <el-row class="marketing-info-content"  :gutter="10"  v-else>
      <el-col :span="12" class="marketing-info-item">
        <el-radio-group v-model="localValue.bgType" size="mini">
          <el-radio-button label="color">颜色</el-radio-button>
          <el-radio-button label="pic">图片</el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="12" class="marketing-info-item">
        <div class="bg-color" v-if="localValue.bgType == 'color'">
          <el-color-picker v-model='localValue.color' size='small' />
          <p>背景颜色</p>
        </div>
        <div class="bg-pic" v-if="localValue.bgType == 'pic'">
          <SpImagePicker v-model="localValue.imageUrl" size="small" text="" />
          <p>背景图片</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { isBoolean } from 'lodash-es';
export default {
  props: {
    title: "",
    type: {
      type: String,
      default: ""
    },
    uuid: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: () => { }
    },
    combg: {
      type: Object,
      default: () => ({
        bgType: "color",
        color: "#f5f5f7",
        imageUrl: "",
      })
    }
  },
  data() {
    return {
      localValue: {
        open: false,
        fontSize: 14,
        fontColor: "#000",
        maginbtm: 0,
        bgColor: "",
        paddedhor: 0,
        paddedve: 0,
        borderWidth: 0,
        borderColor: "#000",
        borderRadius: 0,
        show: true
      }
    }
  },
  computed: {
    isDisbaled() {
      return isBoolean(this.localValue.independent) && !this.localValue.independent;
    }
  },
  watch: {
    uuid: {
      handler(val) {
        this.localValue = this.value;
      },
      deep: true,
      immediate: true
    },
    localValue: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.product-shelves-marketing-info {
  .markting-info-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;

    .title-text {
      font-size: 14px;
      font-weight: bold;
      color: #595961;
    }
  }

  .marketing-info-content {
    .marketing-info-item {
      display: flex;
      align-items: center;
      margin-top: 12px;

      .label {
        width: 65px;
        font-size: 12px;
        color: #888;
      }
    }
  }

}
</style>
<style lang="scss">
.product-shelves-marketing-info {
  .el-row {
    margin-bottom: 0px;
  }
}
</style>