export const MODULE = {
  nav:'导航设置',
  navitem:'导航项设置',
  navcontent:'导航内容设置',
  slidersetting:'轮播交互设置',
  dot:'指示点设置',
  slideritem:'轮播项设置',
  goodsItem:'商品项设置',
  marketingInfo:'',//营销信息设置
  shelvesData:'',//商品数据设置
  shelvesLink:'',//商品关联设置
  store:'门店',
  classifynav:"分类导航", 
}

export const shelvesConfig =  {
  bgSetting:{
    title: "背景设置",
    bgType:"color",
    color:"#fff",
    imageUrl:""
  },
  offset: {
    title: "以旧换新",
    open: true,
    fontSize: 11,
    fontColor: "#f99339",
    maginbtm: 5,
    magintop: 0,
    bgColor: "",
    paddedhor: 0,
    paddedve: 5,
    borderWidth: 0,
    borderColor: "#000",
    borderRadius: 0,
  },
  marketing: {
    title: "营销消息",
    open: true,
    fontSize: 11,
    fontColor: "#f99339",
    maginbtm: 5,
    magintop: 0,
    bgColor: "",
    paddedhor: 0,
    paddedve: 5,
    borderWidth: 0,
    borderColor: "#000",
    borderRadius: 0,
  },
  title: {
    title: "商品标题",
    open: true,
    fontSize: 13,
    fontColor: "#000",
    maginbtm: 2,
    magintop: 0,
    bgColor: "",
    paddedhor: 0,
    paddedve: 5,
    borderWidth: 0,
    borderColor: "#000",
    borderRadius: 0,
    bold: false,
  },
  salePrice: {
    title: "商品销售价",
    open: true,
    fontSize: 12,
    fontColor: "#000",
    maginbtm: 0,
    magintop: 0,
    bgColor: "",
    paddedhor: 0,
    paddedve: 5,
    borderWidth: 0,
    borderColor: "#000",
    borderRadius: 0,
    bold: false,
  },
  discount: {
    title: "商品划线价",
    open: true,
    fontSize: 12,
    fontColor: "#000",
    maginbtm: 0,
    magintop: 0,
    bgColor: "",
    paddedhor: 0,
    paddedve: 5,
    borderWidth: 0,
    borderColor: "#000",
    borderRadius: 0,
    line:true
  },
  btn: {
    title: "加购按钮",
    open: true,
    fontSize: 14,
    fontColor: "#007aff",
    maginbtm: 15,
    magintop: 0,
    bgColor: "#f5f5f7",
    paddedhor: 0,
    paddedve: 24,
    borderWidth: 1,
    borderColor: "#007aff",
    borderRadius: 50,
    suspension: false,
  },
}