var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'wgt-storeRuleList': true,
  'padded': _vm.value.padded
},attrs:{"draggable":"false"}},[_vm._m(0),_c('div',{staticClass:"wgt-bd"},[_c('div',{staticClass:"rule-list"},_vm._l(([{},{}]),function(item,index){return _c('div',{key:index,staticClass:"rule-item"},[_vm._m(1,true),_vm._m(2,true)])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wgt-hd"},[_c('span',{staticClass:"title"},[_vm._v("门店规则列表")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rule-item-left"},[_c('div',{staticClass:"bg"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rule-item-right"},[_c('p',{staticClass:"store-name"},[_vm._v("店铺名称")]),_c('p',{staticClass:"address"},[_vm._v("店铺位置")]),_c('p',{staticClass:"distance_text"},[_vm._v("店铺距离")]),_c('p',{staticClass:"rule-item-label"},[_c('span',[_vm._v("活动标签")]),_c('span',[_vm._v("活动标签")])])])
}]

export { render, staticRenderFns }