<style lang="scss" src="./index.scss"></style>
<template>
  <div
    :class="{
      'wgt-goods-grid': true,
      'padded': value.padded
    }"
  >
    <div v-if="value.title || value.subtitle" class="wgt-hd">
      <div>
        <span class="title">{{ value.title }}</span>
        <span class="sub-title">{{ value.subtitle }}</span>
      </div>
      <div class="wgt-more" />
    </div>
    <div class="wgt-bd">
      <div class="list-title">{{ value.listTitle }}</div>
      <!-- 挂件自定义部分 -->
      <div v-if="value.data.length > 0" class="grid-goods out-padding two-inrow">
        <div v-for="(item, index) in value.data" :key="index" class="grid-item">
          <div class="goods-wrap">
            <div class="thumbnail">
              <img class="goods-img" :src="wximageurl + item.imgUrl" />
            </div>
            <div class="caption">
              <!-- && config.style !== 'grids' -->
              <!-- <img class="goods-brand" v-if="config.brand " :src="item.brand ? wximageurl + item.brand : 'https://fakeimg.pl/60x60/EFEFEF/CCC/?text=brand&font=lobster'" /> -->
              <!-- <div class="activity-label">
                <p v-for="(s, i) in item.promotionActivity" :key="i">
                  {{ s.tag_type == 'single_group' ? '团购' : '' }}
                  {{ s.tag_type == 'full_minus' ? '满减' : '' }}
                  {{ s.tag_type == 'full_discount' ? '满折' : '' }}
                  {{ s.tag_type == 'full_gift' ? '满赠' : '' }}
                  {{ s.tag_type == 'normal' ? '秒杀' : '' }}
                  {{ s.tag_type == 'limited_time_sale' ? '限时特惠' : '' }}
                </p>
              </div> -->
              <div class="goods-title">{{ item.title }}</div>
              <div class="goods-title">{{ item.itemEnName }}</div>
              <!-- v-if="config.showPrice"  -->
              <div class="price">
                <span class="item-price"
                  ><span class="cur">¥</span>{{ item.price ? item.price / 100 : 0 }} 起*</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'oldGoods',
  wgtName: '以旧换新',
  wgtDesc: '',
  wgtIcon: 'wgt-goodsgrid',
  wgtType: 1,
  wgtTem: 1, //0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
