import { pickBy } from "@/utils";
import { cloneDeep } from 'lodash'
import CompsStyle from "../../comps/comps-style.vue";
import CompBg from "../../comps/comp_bg.vue";
import NavItem from "./nav-item.vue";
import CompColor from "../../comps/comp-color";
import CompsRadius from "../../comps/comps-radius";
import { transformInTem } from "../../utils"

export default {
  name: "classify",
  setting: [{
    label: '导航栏背景',
    key: "combg",
    component: function (h, { key }) {
      return <CompBg v-model={this.value[key]} uuid={this.value.uuid} />;
    },
    value: {
      bgType: "color",
      color: "#f5f5f7",
      imageUrl: "",
    },
    module: "classifynav",
  }, {
    label: "导航栏宽度",
    key: "navwidth",
    component: "number",
    position: "right",
    min: 0,
    size: "mini",
    value: 120,
    placeholder: '像素',
    module: "classifynav",
  },
  {
    label: '导航栏内边距',
    key: "navpadded",
    component: function (h, { key }) {
      return <CompsStyle
        showBgsetting={false}
        v-model={this.value[key]}
        showLabel={false}
        showLeft={false}
        showRight={false}
        uuid={`${this.value.uuid}_${key}`}
      />;
    },
    value: {
      paddedt: 0,
      paddedb: 0,
    },
    module: "classifynav",
  }, {
    label: "圆角图片",
    key: "rounded",
    component: "switch",
    value: {
      upleft: 0,
      upright: 0,
      bottomleft: 0,
      bottomright: 0,
    },
    module: "classifynav",
    component: function (h, { key }) {
      return (
        <CompsRadius
          v-model={this.value[key]}
          uuid={this.value.uuid}
          showUpleft
          showUpright
          showBottomleft
          showBottomright
        />
      );
    },
  }, {
    label: '内容内边距',
    key: "contentpadded",
    component: function (h, { key }) {
      return <CompsStyle
        showBgsetting={false}
        v-model={this.value[key]}
        showLabel={false}
        uuid={`${this.value.uuid}_${key}`}
      />;
    },
    value: {
      paddedt: 0,
      paddedb: 0,
      paddedl: 0,
      paddedr: 0,
    },
    module: "classifynav",
  }, {
    label: "默认字体",
    key: "navsize",
    component: "number",
    position: "right",
    min: 10,
    size: "mini",
    value: 13,
    placeholder: '像素',
    module: "navitem",
  }, {
    label: "选中字体",
    key: "selectnav",
    component: function (h, { key }) {
      return <div style={'display: flex; align-items: center;'}>
        <el-input-number placeholder='像素' v-model={this.value[key].size} size='mini' min={10} step={1} controls-position='right' />
        <el-radio-group v-model={this.value[key].weight} size="mini" style={'margin: 0 15px'}>
          <el-radio-button label='bold'>加粗</el-radio-button>
          <el-radio-button label='normal'>常规</el-radio-button>
        </el-radio-group>
      </div>
    },
    value: {
      size: 13,
      weight: 'bold'
    },
    module: "navitem",
  },
  {
    label: "内边距",
    key: "navitempadded",
    component: function (h, { key }) {
      return <CompsStyle
        v-model={this.value[key]}
        showTop={false}
        showLeft={false}
        showBottom={false}
        showRight={false}
        showBgsetting={false}
        showLabel={false}
        showTB={true}
        showLR={true}
        uuid={`${this.value.uuid}_${key}`}
      />;
    },
    value: { paddedtb: 0, paddedlr: 0 },
    module: "navitem",
  }, {
    label: "字体颜色",
    key: "fontcolor",
    component: function (h, { key }) {
      return <CompColor v-model={this.value[key]} uuid={this.value.uuid} />;
    },
    value: {
      dot_noCover: "#fff",
      dot_cover: "#fff",
    },
    module: "navitem",
  }, {
    label: '文字位置',
    key: 'textAlign',
    component: "radiobutton",
    options: [
      { name: "靠左", label: "left" },
      { name: "居中", label: "center" },
      { name: "靠右", label: "right" },
    ],
    value: "left",
    module: "navitem",
  },
  {
    label: "底色",
    key: "bgcolor",
    component: function (h, { key }) {
      return <CompColor v-model={this.value[key]} uuid={this.value.uuid} />;
    },
    value: {
      dot_noCover: "#000",
      dot_cover: "#409EFF",
    },
    module: "navitem",
  },
  {
    label: "",
    key: "data",
    component: function (h, { key }) {
      return <NavItem v-model={this.value[key]} uuid={this.value.uuid} />;
    },
    value: [],
    module: "navcontent",
  },
  ],
  transformIn: async (v, widgets) => {
    const { name, base, config, data, setting } = v;
    const _data = await transformInTem(data, widgets)
    return {
      name,
      ...base,
      ...config,
      ...setting,
      data: _data,
      navsetting: 0,
    };
  },
  transformOut: (v, widgets) => {
    const value = cloneDeep(v)
    const data = value.data.map(item => {
      const { cusTom, ...rest } = item
      if (cusTom) {
        let _cusTom = null
        const { pages_template_id, template_title } = cusTom
        _cusTom = {
          pages_template_id,
          template_title,
        }
        return {
          data: _cusTom,
          ...rest
        }
      }
      return item
    })
    value.data = data
    return pickBy(value, {
      name: "name",
      base: (v) => {
        return {}
      },
      config: (v) => {
        return {}
      },
      setting: (value) => {
        return pickBy(value, {
          combg: "combg",
          navwidth: "navwidth",
          navpadded: 'navpadded',
          contentpadded: "contentpadded",
          navsize: "navsize",
          selectnav: "selectnav",
          navitempadded: "navitempadded",
          fontcolor: "fontcolor",
          bgcolor: "bgcolor",
          rounded: "rounded",
          textAlign: 'textAlign'
        });
      },
      data: "data",
    });
  },
};
